import { SET_DEPENDENTS } from "../actions/action-types";

const initialState = {
  dependents: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DEPENDENTS:
      return {
        ...state,
        dependents: action.payload,
      };
    default:
      return state;
  }
};
