import React, { useEffect, useState } from "react";
import useYup from "@usereact/use-yup";
import creditCardType from "credit-card-type";
import { useSelector, useDispatch } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { setLoading } from "../../../redux/actions/utils.actions";
import api from "../../../services/api";

import { creditCardSchema } from "../../../utils/creditCard";
import { esitefStore } from '../../../utils/esitefStore';
import { isCardNumberValid } from "../../../utils/validateCreditCard";
import paymentIcons from '../../../utils/icons-payment';

import Input from "../../../components/input-custom";
import Loading from "../../../components/loading"

import {
  Container,
  Title,
  SubTitle,
  InputContainer,
  CardNumberContainer,
  Image,
  ExpirtationDateContainer,
  Slash,
  Button,
  ButtonBackHome,
} from "./styles";
import { show } from "../../../services/alert";

export default function App({ open, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.utilsReducer.loading);
  
  const currentPrivatePlan = useSelector(
    (state) => state.userReducer.currentPrivatePlan
  );

  const [authorizerId, setAuthorizerId] = useState('');
  const [cardType, setCardType] = useState('');
  const [creditCardData, setCreditCardData] = useState({
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
  });

  const [touched, setTouched] = useState(false);

  let { errors } = useYup(creditCardData, creditCardSchema, {
    validateOnChange: true,
  });

  useEffect(() => {
    switch (cardType) {
      case 'visa':
        setAuthorizerId('1');
        break;
      case 'mastercard':
        setAuthorizerId('2');
        break;
      case 'american express':
        setAuthorizerId('3');
        break;
      case 'diners':
        setAuthorizerId('33');
        break;
      case 'elo':
        setAuthorizerId('41');
        break;
      default:
        setAuthorizerId('');
    }
  }, [cardType])

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });

    setCreditCardData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'cardNumber') {
      if (isCardNumberValid(value)) {
        var cardType = creditCardType(value);
        if (cardType.length !== 0) {
          cardType[0].type === 'american-express'
            ? setCardType('american express')
            : cardType[0].type === 'diners-club'
              ? setCardType('diners')
              : setCardType(cardType[0].type);
        }
      }

      if (!value) {
        setCardType('');
      }
    }
  }

  async function requestChangeCardToken() {
    dispatch(setLoading(true));
    try {
      const response = await api.post("/payment/changeCreditCard",
        {
          purchaseId: currentPrivatePlan.PurchaseId,
          userId: currentPrivatePlan.idConecta,
        }
      );

      myStore(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  function myStore(token) {
    var request = {
      onSuccess: function (response) {
        dispatch(setLoading(false));
        show('Troca efetuada com sucesso!');
        handleClose('ok');
        console.log(response);
      },
      onFailure: function (response) {
        dispatch(setLoading(false));
        show('Erro na troca do cartão de crédito, tente novamente!');
        console.log(response);
      },
      onInvalid: function (errors) {
        dispatch(setLoading(false));
        for (var i = 0; i < errors.length; i++) {
          show(`${errors[i].cause}`);
          console.log(errors[i].field);
          console.log(errors[i].cause);
        }
      },
      nita: token.nita,
      storeToken: token.storeToken,
      merchantId: token.merchantId,
      locale: 'pt',
      authorizerId,
    };

    esitefStore(request);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
      >
        {loading && <Loading />}
        <Container>
          <div>
            <Title>Novo cartão de crédito</Title>
            <SubTitle>Informe os dados do seu novo cartão</SubTitle>
          </div>

          <InputContainer>
            <CardNumberContainer>
              <Input
                className="esitef-cardnumber"
                width="160px"
                name="cardNumber"
                mask="9999999999999999"
                maskChar=""
                value={creditCardData.cardNumber}
                onChange={handleChange}
                placeholder="Número do cartão"
                errorMessage={touched["cardNumber"] && errors && errors.cardNumber}
                isCreditCardNumber={true}
              />

              {paymentIcons.map(icon => {
                if (cardType === icon.label)
                  return <Image key={icon.id} src={icon.image} width={45} />;
              })}
            </CardNumberContainer>

            <ExpirtationDateContainer>
              <Input
                className="esitef-cardexpirymonth"
                width="55px"
                name="expirationMonth"
                mask="99"
                maskChar=""
                value={creditCardData.expirationMonth}
                onChange={handleChange}
                placeholder="Mês"
                errorMessage={touched["expirationMonth"] && errors && errors.expirationMonth}
                isCreditCardDate={true}
              />

              <Slash>/</Slash>

              <Input
                className="esitef-cardexpiryyear"
                width="55px"
                name="expirationYear"
                mask="99"
                maskChar=""
                value={creditCardData.expirationYear}
                onChange={handleChange}
                placeholder="Ano"
                errorMessage={touched["expirationYear"] && errors && errors.expirationYear}
                isCreditCardDate={true}
              />
            </ExpirtationDateContainer>
          </InputContainer>
          {Object.keys(errors).length !== 0 || !!cardType !== true ? (
            <Button disabled>
              Confirmar
            </Button>
          ) : (
            <Button onClick={() => {
              requestChangeCardToken();
            }}>
              Confirmar
            </Button>
          )}
          <ButtonBackHome onClick={() => {
            handleClose()
          }}>
            Cancelar
          </ButtonBackHome>
        </Container>
      </Dialog>
    </>
  );
}
