import React from "react";
import {
  FiVideo,
  FiMail,
  FiUsers,
  FiCalendar,
  FiCheckSquare,
  FiClock,
} from "react-icons/fi";

export default [
  {
    icon: <FiVideo />,
    label: "Atendimento",
    route: "/home",
  },
  {
    icon: <FiCalendar />,
    label: "Agendar",
    route: "/agendamento",
    needEgilibility: true,
    needScheduling: true,
  },
  {
    icon: <FiCheckSquare />,
    label: "Consultas agendadas",
    route: "/consultas-agendadas",
    needEgilibility: true,
    needScheduled: true
  },
  {
    icon: <FiClock />,
    label: "Histórico",
    route: "/historico",
    needEgilibility: false,
  },
  {
    icon: <FiUsers />,
    label: "Dependentes",
    route: "/dependentes",
  },
  {
    icon: <FiMail />,
    label: "Contato",
    route: "/fale-conosco",
  },
];
