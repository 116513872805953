import styled, { css } from "styled-components"

import { motion } from "framer-motion"
import animations from "../../utils/animation"

export const Container = styled(motion.div).attrs(animations)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  overflow: auto;
`

export const DivLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const Logo = styled.img`
  width: 200px;
`

export const Main = styled.div`
  min-width: 700px;
  width: 45%;
  height: auto 85%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
  }
`

export const Titles = styled.div`
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1024px) {
    align-items: center;
  }
`

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 18px;
`

export const SubTitle = styled.p`
  color: #707070;
`

export const Infos = styled.div`
  box-shadow: 0px 0px 9px 0px rgba(150, 150, 150, 0.4);
  width: 628px;
  min-height: 60%;
  height: auto;
  padding: 30px 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0 0;
  line-height: 1.5;

  @media (max-width: 1024px) {
    width: 90%;
  }
`
export const ImageAlert = styled.img`
  margin: 0 0 30px;
`

export const Texts = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 18px;
  text-align: left;
  padding: 30px 0;

  p {
    color: #909090;
    width: 500px;
    text-align: center;
    line-height: 1.5;
  }

  ul {
    width: 450px;
    margin: 30px 0;
  }

  li {
    color: #707070;
  }
  
  img {
    margin: 0 10px;
    width: 70px;
  }

  @media (max-width: 1024px) {
    width: 100%;

    p {
      width: 80%;
    }

    ul {
      width: 80%;
    }
  }
`

export const Buttons = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 25px 0 0;
`

export const Button = styled.button`
  width: ${(props) => (props.width ? props.width : "160px")};
  height: 40px;
  border-radius: 30px;
  display: flex;
  margin: 0 10px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    margin: 0 20px;
    min-width: 100px;
  }

  ${(props) => props.contained && contained}
  ${(props) => props.outline && outline}
`

const contained = css`
  background: #2196f3;
  color: #fff;
`

const outline = css`
  border: 1px solid #2196f3;
  color: #2196f3;
`

export const AnotherDiv = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  h4 {
  }

  input {
    margin: 30px 0 0;
    width: 60%;
    padding: 10px 0px;
    border: none;
    border-bottom: 2px solid #909090;
  }

  p {
    margin: 30px 0 0;
    line-height: 1.5;
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: #707070;
  }
`

export const UploadMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`

export const DivImageUpload = styled.div`
  img {
    width: 50px;
    height: 50px;
    margin: 20px 0;
  }
`

export const UploadArea = styled.label`
  width: 70%;
  height: 100%;
  background: #F8F8F8;
  display: flex;
  flex-direction: row;
  border: 1px dashed #d9d9d9;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input#inputFile {
    display: none;
  }
  @media (max-width: 1024px) {
    width: 90%;
  }
`

export const Uploaded = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 20px 0 0;
  }
`

export const Files = styled.div`
  width: 100%;
  max-height: 160px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
`

export const ButtonUpload = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 30px;
  color: #D1D1D1;
  border-radius: 25px;
  cursor: pointer;
  margin: 10px 0 0;

  @media (max-width: 1024px) {
    width: 92%;
  }
`

export const ButtonSaveUpload = styled.button`
  width: 150px;
  height: 35px;
  background-color: #2196f3;
  color: white;
  border-radius: 25px;
  font-weight: bolder;
  cursor: pointer;
  margin: 30px 0 0;

  @media (max-width: 1024px) {
    margin: 40px 0 180px;
    width: 92%;
  }
`

export const ItemFile = styled.div`
  width: 100%;
  border-bottom: 1px solid #c9c9c9;
  height: 40px;
  padding: 10px;
  margin: 5px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  img {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }

  @media (max-width: 1024px) {
    margin: 20px 0 0;
  }
`

export const FirstFile = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;

  label {
    color: #707070;
    font-size: 13px;
    white-space: nowrap; 
    width: 60%; 
    overflow: hidden;
    text-overflow: ellipsis; 
    margin-left: 10px;

  }

  img {
    margin: 0 10px;
    width: 20px;
    color: blue;
  }
`

export const Form = styled.form`
  position: relative;
  margin: 10px auto 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
`

export const Caption = styled.span`
  font-size: 0.7em;
  color: #cacaca;
`

export const UploadTextContainer = styled.div`
  padding-left: 8px;
`