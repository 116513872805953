import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckIcon from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import { parseISO, format } from "date-fns";

import {
  Container,
  DivLogo,
  DivLogoWhite,
  Logo,
  Steps,
  DivMain,
  DivButtonBack,
  ButtonBack,
  IconBack,
  LabelBack,
  AlertDiv,
  TitleAlert,
  SubTitleAlert,
  IconAlert,
  GridContainer,
  DivLabels,
  LabelSchedule,
  SubLabelSchedule,
  Icon,
  Buttons,
  Button,
  ContainerBuy,
} from "./styles";

// pages
import UserData from "./user-data";
import Plans from "./plans";
import Informations from "./informations";
import Payment from "./payment";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveStep,
  setVisibleOptions,
} from "../../redux/actions/utils.actions";

import {
  setDataUser as setDataUserProps,
  setCurrentPlan,
} from "../../redux/actions/buy.actions";

import plans from "./plans/plans";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      background: "#2196F3",
    },
  },
  completed: {
    "& $line": {
      background: "#2196F3",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    background: "#2196F3",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    background: "#2196F3",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {(completed || active) && <CheckIcon />}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: 5,
    color: "#707070",
  },
}));

function getSteps() {
  return [
    "Complete seus dados",
    "Selecione seu contrato",
    "Confirme as informações",
    "Finalizar",
  ];
}

export default function CustomizedSteppers() {
  const classes = useStyles();
  const steps = getSteps();
  const dispatch = useDispatch();

  const status = window.location.href.split("=")[1];

  const plansAPI = useSelector((state) => state.buyReducer.plans);
  const activeStep = useSelector((state) => state.utilsReducer.activeStep);
  const user = useSelector((state) => state.userReducer.user);

  const plan = JSON.parse(localStorage.getItem("@current_plan"));
  const changePlan = JSON.parse(localStorage.getItem("@change_plan"));
  const discount = localStorage.getItem("discount");

  const [statusBuy, setStatusBuy] = useState(false);

  useEffect(() => {
    if (discount && plan && !plan.Amount) {
      dispatch(setActiveStep(2));
    }

    if (plan && plan.Amount) {
      dispatch(setActiveStep(3));
    }

    dispatch(setVisibleOptions(false));

    if (changePlan && changePlan.TypeProduct.toLowerCase().includes('avulso')) {
      return dispatch(setActiveStep(2));
    }else if(changePlan){
      return dispatch(setActiveStep(3));
    }

    if (plan && plan.Amount) {
      return dispatch(setActiveStep(3));
    }

    if (user && user.cep) {
      return dispatch(setActiveStep(2));
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("cb5e100e5a9a3e7f6d1fd97512215282")) {
      setStatusBuy(1);
    }

    if (window.location.href.includes("10aec35353f9c4096a71c38654c3d402")) {
      setStatusBuy(2);
    }

    if (window.location.href.includes("fb5f2f27be2de104ac2b192f3e874dda")) {
      setStatusBuy(3);
    }
  }, [status]);

  useEffect(() => {
    if (changePlan) {
      let plan = plans[changePlan.category];
      let currentPlan = { ...plan, ...changePlan };
      dispatch(setCurrentPlan(currentPlan));
    }
  }, [plansAPI]);

  useEffect(() => {
    if (plan) {
      dispatch(setActiveStep(3));
      dispatch(setCurrentPlan(plan));
    }

    if (!plan && discount) {
      dispatch(setActiveStep(2));
    }
  }, []);

  useEffect(() => {
    if (user && user.adress) {
      let obj = {
        cep: user.adress.Address,
        address: user.adress.Street,
        cityAddress: user.adress.City,
        stateAddress: user.adress.State,
        numberAddress: user.adress.Number,
        complementAddress: user.adress.Complement,
      }
      if(user.financeResponsible){
        obj = { ...obj, hasFinanceOfficer: user.financeResponsible.hasFinanceResponsible}
      }
      dispatch(
        setDataUserProps(obj)
      );
    }else{
      dispatch(setActiveStep(1));
    }
  }, [user]);

  function handleNext() {
    if (activeStep !== 5) dispatch(setActiveStep(activeStep + 1));
  }

  function handleBack() {
    if (activeStep !== 1) dispatch(setActiveStep(activeStep - 1));
  }

  function reBuy() {
    setStatusBuy(false);
    dispatch(setActiveStep(3));
  }

  function goToHome() {
    window.location.href = "/home";
  }

  function switchPage(step) {
    switch (step) {
      case 1:
        return <UserData handleNext={handleNext} />;
        break;
      case 2:
        return <Plans handleNext={handleNext} />;
        break;
      case 3:
        return <Informations />;
        break;
      case 4:
        return <Payment />;
        break;
    }
  }

  return (
    <Container>
      <DivLogo onClick={() => (window.location.href = "/home")}>
        <Logo src="/img/logo-sidebar.png" />
      </DivLogo>

      <DivLogoWhite onClick={() => (window.location.href = "/home")}>
        <Logo src="/img/logo-white.svg" />
      </DivLogoWhite>

      {statusBuy && statusBuy === 1 && (
        <AlertDiv>
          <TitleAlert>Infezlimente tivemos erros hora de sua compra</TitleAlert>
          <IconAlert src="/img/error.jpg" />
          <SubTitleAlert>
            Você pode tentar realizar a compra novamente e se o error persistir,
            entre em contato através de:
          </SubTitleAlert>
          <Buttons>
            <Button outline onClick={goToHome}>
              Cancelar
            </Button>
            <Button contained onClick={reBuy}>
              Tentar novamente
            </Button>
          </Buttons>
          <DivLabels>
            <GridContainer>
              <Icon src="/img/icon@.png" />
              <DivLabels>
                <LabelSchedule>Por e-mail</LabelSchedule>
                <SubLabelSchedule>conecta@einstein.br</SubLabelSchedule>
              </DivLabels>
              <Icon src="/img/phone.png" />
              <DivLabels>
                <LabelSchedule>Por telefone</LabelSchedule>
                <SubLabelSchedule>(11) 2151-9659</SubLabelSchedule>
              </DivLabels>
              <Icon src="/img/icon-chat.png" />
              <DivLabels>
                <LabelSchedule>Chat on-line</LabelSchedule>
                <SubLabelSchedule>Atendimento 24hs por dia</SubLabelSchedule>
              </DivLabels>
            </GridContainer>
          </DivLabels>
        </AlertDiv>
      )}

      {statusBuy && statusBuy === 2 && (
        <AlertDiv>
          <TitleAlert>Compra cancelada</TitleAlert>
          <IconAlert src="/img/alert.png" />
          <SubTitleAlert>
            Se possuir alguma dúvida, entre em contato através de:
          </SubTitleAlert>
          <DivLabels>
            <GridContainer>
              <Icon src="/img/icon@.png" />
              <DivLabels>
                <LabelSchedule>Por e-mail</LabelSchedule>
                <SubLabelSchedule>conecta@einstein.br</SubLabelSchedule>
              </DivLabels>
              <Icon src="/img/phone.png" />
              <DivLabels>
                <LabelSchedule>Por telefone</LabelSchedule>
                <SubLabelSchedule>(11) 2151-9659</SubLabelSchedule>
              </DivLabels>
              <Icon src="/img/icon-chat.png" />
              <DivLabels>
                <LabelSchedule>Chat on-line</LabelSchedule>
                <SubLabelSchedule>Atendimento 24hs por dia</SubLabelSchedule>
              </DivLabels>
            </GridContainer>
          </DivLabels>
        </AlertDiv>
      )}

      {statusBuy && statusBuy === 3 && (
        <AlertDiv>
          <TitleAlert>Compra efetivada com sucesso</TitleAlert>
          <IconAlert src="/img/verification.png" />
          <SubTitleAlert>
            Agora você ja pode realizar consultas através do Einstein Conecta.
            Se possuir alguma dúvida, entre em contato através de:
          </SubTitleAlert>
          <DivLabels>
            <GridContainer>
              <Icon src="/img/icon@.png" />
              <DivLabels>
                <LabelSchedule>Por e-mail</LabelSchedule>
                <SubLabelSchedule>conecta@einstein.br</SubLabelSchedule>
              </DivLabels>
              <Icon src="/img/phone.png" />
              <DivLabels>
                <LabelSchedule>Por telefone</LabelSchedule>
                <SubLabelSchedule>(11) 2151-9659</SubLabelSchedule>
              </DivLabels>
              <Icon src="/img/icon-chat.png" />
              <DivLabels>
                <LabelSchedule>Chat on-line</LabelSchedule>
                <SubLabelSchedule>Atendimento 24hs por dia</SubLabelSchedule>
              </DivLabels>
            </GridContainer>
          </DivLabels>
        </AlertDiv>
      )}

      {!statusBuy && (
        <ContainerBuy>
          <Steps>
            <Stepper
              style={{ background: "transparent" }}
              alternativeLabel
              activeStep={activeStep - 1}
              connector={<ColorlibConnector />}
            >
              {steps.map((label, key) => (
                <Step onClick={() => setActiveStep(1)} key={label}>
                  <StepLabel
                    StepIconProps={{
                      classes: { text: classes.label },
                    }}
                    StepIconComponent={ColorlibStepIcon}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Steps>

          {(!changePlan || changePlan.TypeProduct.toLowerCase().includes('avulso')) && activeStep !== 1 && activeStep !== 4 &&(
            <DivButtonBack>
              <ButtonBack type="button" onClick={handleBack}>
                <IconBack src="/img/icons/back-icon.png"></IconBack>
                <LabelBack>Voltar</LabelBack>
              </ButtonBack>
            </DivButtonBack>
          )}

          <DivMain>{switchPage(activeStep)}</DivMain>
        </ContainerBuy>
      )}
    </Container>
  );
}
