export function convertDate(data) {
  if (data) {
    let arrayData = data.split("-");
    data = `${arrayData[2]}/${arrayData[1]}/${arrayData[0]}`;
  }

  return data;
}

export function generateDate(data) {
  if (data) {
    let arrayData = data.split("/");
    data = `${arrayData[2]}-${arrayData[1]}-${arrayData[0]}`;
  }

  return data;
}
