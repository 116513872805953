import {
  FIND_SLOTS,
  FIND_SPECIALTIES,
  FIND_DOCTORS_BY_SPECIALTY_ID,
  CONFIRM_SCHEDULING,
  FIND_PATIENT,
  FIND_SLOT_BY_PATIENT,
  CANCEL_SCHEDULING,
  SCHEDULE_CLEAR,
  CLEAR_SLOT_BY_PATIENT,
  SCHEDULED_SUCCESS,
  FIND_PRODUCTS,
  SUCCESS_SCHEDULED_CLEAR,
  APPOINTMENT_OLD,
  GET_FLAG_SCHEDULING
} from "./action-types"

export const slotByMonth = (payload) => ({
  type: FIND_SLOTS,
  payload,
})

export const specialtiesByMonth = (payload) => ({
  type: FIND_SPECIALTIES,
  payload,
})

export const doctorsBySpecialtyId = (payload) => ({
  type: FIND_DOCTORS_BY_SPECIALTY_ID,
  payload,
})

export const confirmSchedulingSlot = (payload) => ({
  type: CONFIRM_SCHEDULING,
  payload,
})
export const checkPatient = (payload) => ({
  type: FIND_PATIENT,
  payload,
})
export const slotByPatient = (payload) => ({
  type: FIND_SLOT_BY_PATIENT,
  payload,
})
export const cancelScheduling = (payload) => ({
  type: CANCEL_SCHEDULING,
  payload,
})
export const clearSchedule = () => ({
  type: SCHEDULE_CLEAR,
})
export const clearSlotByPatient = () => ({
  type: CLEAR_SLOT_BY_PATIENT,
})
export const successScheduled = (payload) => ({
  type: SCHEDULED_SUCCESS,
  payload,
})
export const findProducts = () => ({
  type: FIND_PRODUCTS,
})
export const clearSuccessScheduled = () => ({
  type: SUCCESS_SCHEDULED_CLEAR,
})
export const appointmentOld = (payload) => ({
  type: APPOINTMENT_OLD,
  payload,
})
export const getScheduling = () => ({
  type: GET_FLAG_SCHEDULING
})