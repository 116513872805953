import React, { useState, useEffect } from "react";

import {
  Container,
  Form,
  DivLogo,
  Logo,
  Inputs,
  Icon,
  LabelPassword,
  Button,
  Register,
  ImageForm,
  BackIos,
  BackIosButton
} from "./styles";

import { useDispatch } from "react-redux";
import { push } from "../../services/navigate";
import { login } from "../../redux/actions/user.actions";

import Input from "../../components/input-custom";

import useYup from "@usereact/use-yup";
import validationSchema from "./validate";

export default function App() {
  const dispatch = useDispatch();

  const [touched, setTouched] = useState(false);
  const [iosApp, setIosApp] = useState(localStorage.getItem("@ios_app"));
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  let { errors } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  function _openApp() {
    setIosApp(false)
    localStorage.removeItem("@ios_app");
    window.location.href = "conectaapp://payment=true"
  }

  function _goToLogin() {
    setIosApp(false)
    localStorage.removeItem("@ios_app");
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!errors.email && !errors.password) {
      const data = {
        username: values.email,
        password: values.password,
      };

      dispatch(login(data));
    }
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function goToRegister() {
    push("/cadastro");
  }

  return (
    <Container>
      {!iosApp || iosApp == null ? <Form onSubmit={handleSubmit}>
        <DivLogo onClick={() => push("/")}>
          <Logo src="/img/logo-sidebar.png" />
        </DivLogo>

        <Inputs>
          <Input
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="E-mail"
            type="text"
            errorMessage={touched["email"] && errors && errors.email}
          />

          <Input
            name="password"
            passwordShow={true}
            value={values.password}
            onChange={handleChange}
            placeholder="Senha"
            type="password"
            errorMessage={touched["password"] && errors && errors.password}
          />
          <LabelPassword onClick={() => push("/enviar-link")}>
            <Icon src="/img/icons/recovery-password.svg" />
            <p>Esqueci minha senha</p>
          </LabelPassword>
        </Inputs>

        <Button type="submit">Entrar</Button>

        <Register onClick={() => goToRegister()}>
          Não tem uma conta? <b>Cadastre-se</b>
        </Register>
      </Form> :
        <BackIos>
          <DivLogo onClick={() => push("/")}>
            <Logo src="/img/logo-sidebar.png" />
          </DivLogo>
          <BackIosButton onClick={() => _openApp()}>Voltar para o aplicativo</BackIosButton>
          <Button onClick={() => _goToLogin()}>Continuar no navegador</Button>
        </BackIos>
      }
      <ImageForm src="/img/home-kv.png" />
    </Container>
  );
}
