import { combineReducers } from "redux";

import userReducer from "./user.reducer";
import dependentsReducer from "./dependents.reducer";
import patientReducer from "./patient.reducer";
import utilsReducer from "./utils.reducer";
import buyReducer from "./buy.reducer";
import historyReducer from "./history.reducer";
import schedule from "./schedule.reducer";

export default combineReducers({
  userReducer,
  dependentsReducer,
  utilsReducer,
  patientReducer,
  historyReducer,
  buyReducer,
  schedule,
});
