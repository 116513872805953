import React from "react";
import {
  Differentials,
  LabelDifferentials,
  DifferentialsDiv,
  DifferentialsItem,
  DifferentialsImage,
  DifferentialsText,
} from "./styles";

import { DifferentialsJson } from "../objects";

export default function App() {
  return (
    <Differentials id="diferenciais" >
      <LabelDifferentials>Diferenciais</LabelDifferentials>

      <DifferentialsDiv>
        {DifferentialsJson.map((item, key) => (
          <DifferentialsItem>
            <DifferentialsImage src={item.img} />
            <DifferentialsText>{item.text}</DifferentialsText>
          </DifferentialsItem>
        ))}
      </DifferentialsDiv>
    </Differentials>
  );
}
