import styled from "styled-components";

export const Container = styled.div`
  width: 400px;
  max-height: 700px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;

  padding: 20px 10px;
  @media (max-width: 1024px) {
    margin-top: 30px;
    height: 25vh;
  }
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: transparent;
`;

export const LabelBack = styled.p`
  margin-left: 10px;
  color: #707070;
`;

export const IconBack = styled.img`
  width: 20px;
  height: 20px;
`;

export const LabelConfirm = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 15px;
  color: #707070;
`;

export const DivButtons = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ButtonConfirm = styled.div`
  width: 90px;
  height: 30px;
  margin-top: 8px;
  border-radius: 20px;
  padding: 5px;
  margin-left: 5px;
  text-align: center;
  font-size: 12px;
  color: white;
  background-color: #2196f3;
  cursor: pointer;
`;
export const ButtonCancel = styled.div`
  width: 90px;
  height: 30px;
  margin-top: 8px;
  border-radius: 20px;
  border: 1px solid #2196f3;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  color: #2196f3;
  background-color: white;
  cursor: pointer;
`;
