import { select, call, put } from "redux-saga/effects";
import { setLoading } from "../actions/utils.actions";
import { show } from "../../services/alert";
import api from "../../services/api";
import moment from "moment";

import { successScheduled, slotByPatient } from "../actions/schedule.actions";

import {
  SCHEDULE_SUCESS,
  SPECIALTY_SUCESS,
  CONFIRM_SCHEDULING_SUCESS,
  DOCTOR_SUCESS,
  APPOINTMENTS_BY_PATIENT,
  CANCEL_SCHEDULING_SUCESS,
  PRODUCTS_SUCCESS,
} from "../actions/action-types";

export function* findSlots(payload) {
  const { patientReducer } = yield select();
  let { date, specialtyId = 1, doctorId } = payload.payload;
  const age = Math.floor(
    moment().diff(patientReducer.patient.birthDate, "year")
  );

  if (age < 16) specialtyId = 2;

  yield put(setLoading(true));
  const dateNow = moment(date).format("YYYY-MM-DD");
  const data = {
    dateStart: dateNow,
    dateFinish: dateNow,
    state: 2,
    limit: 1000,
    offset: 0,
    specialtyId: specialtyId,
    doctorId: doctorId,
  };
  try {
    const request = () => api.post("/schedule/getAllSlots", data);
    const response = yield call(request);

    const date = response.data.data;

    const hourFormat = (date || [])
      .filter((data) => moment(data.startAt).isSameOrAfter(moment()))
      .map((data) => ({
        ...data,
        startAt: moment(data.startAt).format("HH:mm"),
      }));

    if (response.status === 200) {
      yield put({ type: SCHEDULE_SUCESS, payload: hourFormat });
    }
  } catch (error) {
    console.log("ERROR:::", error);
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* findSpecialties({ payload }) {
  const dateNow = moment().format("YYYY-MM-DD");
  const dateFinish = moment().endOf("month").format("YYYY-MM-DD");

  const data = {
    dateStart: dateNow,
    dateFinish: dateFinish,
    state: 2,
    limit: 10000,
    offset: 0,
  };

  try {
    const request = () => api.post("/schedule/getAllSpecialties", data);
    const response = yield call(request);

    const oldSpecialty = response.data;

    const specialtyArray = oldSpecialty.map((item, key) => {
      return { key: item.id, value: item.id, text: item.name };
    });

    if (response.status === 200) {
      yield put({ type: SPECIALTY_SUCESS, payload: specialtyArray });
    }
  } catch (error) {
    console.log("ERROR:::", error);
    show("Tente novamente");
  }
}

export function* findPatient({ payload }) {
  yield put(setLoading(true));
  try {
    const request = () => api.post("/schedule/scheduling", payload);
    const response = yield call(request);
    if (response.status === 200) {
      yield put({ type: CONFIRM_SCHEDULING_SUCESS, payload: response.data });
      yield put(successScheduled(true));
    }
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* findDoctorsBySpecialtyId({ payload }) {
  yield put(setLoading(true));
  const specialtyId = payload;
  const data = {
    idSpecialty: specialtyId,
  };
  try {
    const request = () =>
      api.post("/schedule/getAllDoctorsBySpecialtyId", data);
    const response = yield call(request);

    const doctors = response.data;

    const doctorsFormat = doctors.map((doctor) => {
      return { key: doctor.id, value: doctor.id, text: doctor.name };
    });

    if (response.status === 200) {
      yield put({ type: DOCTOR_SUCESS, payload: doctorsFormat });
    }
  } catch (error) {
    console.log("ERROR:::", error);
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* findSLotByPatient({ payload }) {
  yield put(setLoading(true));
  try {
    const request = () => api.post("/schedule/findSlotByPatient", payload);
    const response = yield call(request);
    if (response.status === 200) {
      yield put({ type: APPOINTMENTS_BY_PATIENT, payload: response.data });
    }
  } catch (error) {
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* cancelScheduling({ payload }) {
  const { userReducer } = yield select();
  yield put(setLoading(true));
  const slotId = payload;

  const data = {
    slotId: slotId,
    slotStateId: 7,
  };
  try {
    const request = () => api.post("/schedule/cancelScheduling", data);
    const response = yield call(request);
    if (response.status === 200) {
      yield put({ type: CANCEL_SCHEDULING_SUCESS, payload: response.data });
      yield put(slotByPatient(userReducer.user));
    }
  } catch (error) {
    console.log("ERROR:::", error);
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* findProducts({ payload }) {
  yield put(setLoading(true));
  const productId = 1; // refer telemedicina einstein

  try {
    const request = () => api.post("/schedule/findProducts", productId);
    const response = yield call(request);
    if (response.status === 200) {
      yield put({ type: PRODUCTS_SUCCESS, payload: response.data.data });
    }
  } catch (error) {
    show("Tente novamente");
  } finally {
    yield put(setLoading(false));
  }
}

export function* configSystem({ payload }) {
  yield put(setLoading(true));

  try {
    const request = () => api.get("/config-system");
    const response = yield call(request);

    const flagScheduling = response.data.configSystem.scheduling;

    localStorage.setItem("@flag_scheduling", JSON.stringify(flagScheduling));
  } catch (error) {
    if (error) {
      show(error);
    }
  } finally {
    yield put(setLoading(false));
  }
}
