import React, { useEffect, useState } from "react";
import {
  Header,
  Menu,
  Logo,
  Items,
  ItemMenu,
  ButtonMenu,
  Background,
  Options,
  OptionItem,
  User,
  ImageUser,
  Message,
} from "./styles";

import { push } from "../../../services/navigate";
import { useSelector, useDispatch } from "react-redux";
import { verifyAuth } from "../../../redux/actions/utils.actions";

import Slider from "infinite-react-carousel";

export default function App() {
  const dispatch = useDispatch();
  const [position, setPosition] = useState(0);

  const user = useSelector((state) => state.userReducer.user);
  const whiteLabelOptions = useSelector(
    (state) => state.utilsReducer.whiteLabelOptions
  );

  const [menuVisible, setMenuVisible] = useState(false);
  const [optionsVisible, setVisibleOptions] = useState(false);

  const options = [
    {
      label: "Ir para Home",
      action: () => goToHome(),
    },
    {
      label: "Sair",
      action: () => logout(),
    },
  ];

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);

    dispatch(verifyAuth({ landing: true }));

    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  useEffect(() => {
    if (user && user.name) {
      setMenuVisible(true);
    }
  }, [user]);

  function listenToScroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    setPosition(scrolled);
  }

  function goToHome() {
    push("/home");
    setVisibleOptions(!optionsVisible);
  }

  function logout() {
    localStorage.removeItem("@access_token");
    window.location.href = "/login";
    setVisibleOptions(!optionsVisible);
  }

  function goToLogin() {
    push("/login");
  }

  function logout() {
    localStorage.removeItem("@access_token");
    window.location.href = "/login";
    setVisibleOptions(!optionsVisible);
  }

  function getMessage() {
    const dateNow = new Date();
    const hour = dateNow.getHours();

    if (hour < 5) {
      return "Boa noite";
    }

    if (hour < 12) {
      return "Bom dia";
    }

    if (hour < 18) {
      return "Boa tarde";
    }

    return "Bem-vindo";
  }

  function toggleOptions() {
    setVisibleOptions(!optionsVisible);
  }

  return (
    <Header>
      <Menu visible={position}>
        <Logo visible={position} src="img/logo-sidebar.png" />

        <Items>
          <ItemMenu href="#como-funciona">Como funciona</ItemMenu>
          <ItemMenu href="#diferenciais">Diferenciais</ItemMenu>
          <ItemMenu href="#duvidas-frequentes">Dúvidas Frequentes</ItemMenu>
          <ButtonMenu visible={position} href="#contratos" contained>
            Contrate agora
          </ButtonMenu>

          {!menuVisible && (
            <ButtonMenu visible={position} onClick={goToLogin} outline>
              Entrar
            </ButtonMenu>
          )}

          {menuVisible && (
            <User>
              <Message white={whiteLabelOptions}>
                Olá, {getMessage()}. <br />{" "}
                <b>{`${user.name || ""} ${user.lastName || ""}`}</b>
              </Message>
              <ImageUser
                onClick={toggleOptions}
                src={`${user.image || "img/default.png"}`}
                onError={(e) => e.target.src = "img/default.png"}
              />
            </User>
          )}

          {optionsVisible && (
            <Options>
              {options.map((option) => (
                <OptionItem onClick={() => option.action()}>
                  {option.label}
                </OptionItem>
              ))}
            </Options>
          )}
        </Items>
      </Menu>

      <Slider arrows={false} autoplay={true} adaptiveHeight={true} dots>
        <div>
          <Background src="img/carrousel/conecta-img1.jpg" />
        </div>
        <div>
          <Background src="img/carrousel/conecta-img2.jpg" />
        </div>
        <div>
          <Background src="img/carrousel/conecta-img3.jpg" />
        </div>
        <div>
          <Background src="img/carrousel/conecta-img4.jpg" />
        </div>
        <div>
          <Background src="img/carrousel/conecta-img5.jpg" />
        </div>
        <div>
          <Background src="img/carrousel/conecta-img6.jpg" />
        </div>
      </Slider>
    </Header>
  );
}
