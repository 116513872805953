import React, { useState, useEffect } from 'react';
import {
  Container,
  Main,
  Titles,
  Title,
  SubTitle,
  DivLogo,
  Logo,
  Inputs,
  DivInput,
  ErrorMessage,
  Select,
  DivLogoWhite,
  Button,
  DivTerms,
  Terms,
} from './styles';

import axios from 'axios';

import Input from '../../components/input-custom';
import CheckBox from '../../components/checkbox';
import statesLong from './states-long';
import useYup from '@usereact/use-yup';
import {brasileiroSchema, estrangeiroSchema} from './validate';

import { useDispatch } from 'react-redux';
import { registerUser } from '../../redux/actions/user.actions';
import { push } from '../../services/navigate';
import { getPhoneMaskRule } from "../../utils/phoneMask"

export default function Example() {
  const dispatch = useDispatch();
  const [touched, setTouched] = useState(false);
  const [address, setAddress] = useState(false);

  const [phoneMaskRule, setPhoneMaskRule] = useState(getPhoneMaskRule(''));
  const [tryPhone, setTryPhone] = useState(false);

  const[selectedNationality, setSelectedNationality] = useState(false)

  const [user, setUser] = useState({
    name: '',
    lastName: '',
    birthDate: '',
    sex: '',
    nationality: '',
    documentType: '',
    phone: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    terms: false,
    planTerm: false,
    comunication: false,
  });

  let { errors } = useYup(user, user.nationality === 'B' ? brasileiroSchema : estrangeiroSchema, {
    validateOnChange: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setTouched({
      name: true,
      lastName: true,
      birthDate: true,
      sex: true,
      document: true,
      documentType: true,
      phone: true,
      email: true,
      nationality: true,
      confirmEmail: true,
      password: true,
      confirmPassword: true,
      cep: true,
      rg: true,
      address: true,
      stateAddress: true,
      numberAddress: true,
      cityAddress: true,
      terms: true,
      planTerm: true,
    });

    if (isEmpty(errors)) {
      const newCep = user.cep.replace(/\D/g,'');
      const newUser = {
        ...user,
        cep: newCep
      }
      setUser((prevState) => ({
        ...prevState,
        cep: newCep,
      }))
      dispatch(registerUser(newUser));
    }
  }

  function dontBlockEmoji(value) {
    return (
      value === 'email'|| value === 'confirmEmail' ||
      value ==='password' || value ==='confirmPassword'
      || value === 'birthDate' || (value === 'document' && user.nationality === 'B')
    )
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });

    //Block all non (alpha or numeric caracters)
    const reg = /^([a-zA-ZÀ-ú\s\d]+)$/gi
    if(!reg.test(value) && !dontBlockEmoji(name) && value.length > 0){
      return
    }
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    //Resets phone input on nationality change
    if(name === 'nationality'){
      setUser((prevState) => ({
        ...prevState,
        phone: '',
        cep: '',
        address: '',
        stateAddress: '',
        cityAddress: '',
        numberAddress: '',
        document: '',
        documentType: value === 'B' ? 'CPF' : 'PASSAPORTE'
      }));
      setAddress(false);
      setSelectedNationality(true)
    }
  }
  
  function handlePhone(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });

    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setPhoneMaskRule(getPhoneMaskRule(value))
  }

  function goToLogin() {
    push('/login');
  }

  async function getCep(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if(!address && user.nationality === 'E'){
      setAddress(true)
    }

    if (value && value.length === 9) {
      const cep = value.replace(/[^0-9]+/g, '');

      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

        setUser((prevState) => ({
          ...prevState,
          address: data.logradouro,
          cityAddress: data.localidade,
          stateAddress: statesLong[data.uf],
        }));
        setAddress(true);
      } catch (err) {
      } finally {
      }
    }
  }

  function disablePast(event) {
    event.preventDefault();
  }

  return (
    <Container>
      <DivLogo onClick={() => (window.location.href = '/home')}>
        <Logo src="/img/logo-sidebar.png" />
      </DivLogo>

      <DivLogoWhite onClick={() => (window.location.href = '/home')}>
        <Logo src="/img/logo-white.svg" />
      </DivLogoWhite>

      <Main onSubmit={handleSubmit}>
        <Titles>
          <Title>Cadastrar conta</Title>
          <SubTitle>Crie sua conta no Einstein Conecta</SubTitle>
        </Titles>

        <Inputs>
          <Input
            width="285px"
            onChange={handleChange}
            name="name"
            placeholder="Nome"
            maxLength={128}
            type="text"
            value={user.name}
            errorMessage={touched['name'] && errors && errors.name}
          />

          <Input
            width="285px"
            onChange={handleChange}
            name="lastName"
            noEmoji={true}
            placeholder="Sobrenome"
            maxLength={128}
            type="text"
            value={user.lastName}
            errorMessage={touched['lastName'] && errors && errors.lastName}
          />

          <Input
            width="620px"
            mask="99/99/9999"
            name="birthDate"
            value={user.birthDate}
            onChange={handleChange}
            placeholder="Data de Nascimento"
            errorMessage={touched['birthDate'] && errors && errors.birthDate}
          />

          <DivInput>
            <label>{user.nationality && 'Nacionalidade'}</label>

            <Select name="nationality" onChange={handleChange}>
              <option disabled selected value>
                Nacionalidade
              </option>
              <option value="B">Brasileira</option>
              <option value="E">Estrangeira</option>
            </Select>

            <ErrorMessage>{touched['nationality'] && errors && errors.nationality}</ErrorMessage>
          </DivInput>

          <DivInput>
            <label>{user.sex && 'Sexo'}</label>

            <Select name="sex" onChange={handleChange}>
              <option disabled selected value>
                Sexo
              </option>
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
            </Select>

            <ErrorMessage>{touched['sex'] && errors && errors.sex}</ErrorMessage>
          </DivInput>

          {selectedNationality ? user.nationality === "E" ? 
            <Input
              width="620px"
              onChange={handleChange}
              value={user.document}
              name="document"
              placeholder="Passaporte"
              type="text"
              maxLength={128}
              maskChar={''}
              errorMessage={touched['document'] && errors && errors.document}
            />
          :
            <>
              <Input
                width="285px"
                onChange={handleChange}
                value={user.document}
                name="document"
                placeholder="CPF"
                type="text"
                mask={'999.999.999-99'}
                errorMessage={touched['document'] && errors && errors.document}
              />

              <Input
                width="285px"
                onChange={handleChange}
                name="rg"
                placeholder="RG"
                maxLength={128}
                type="text"
                value={user.rg}
                errorMessage={touched['rg'] && errors && errors.rg}
              />
            </>
          : null}
          
          <Input
            width="620px"
            onChange={handlePhone}
            name="phone"
            placeholder={user.nationality === "E" ? "Código do País + DDD + Telefone" : "DDD + Telefone"}
            type="text"
            mask={user.nationality === "E" ? "+999999999999999" : phoneMaskRule.mask}
            value={user.phone}
            errorMessage={touched['phone'] && errors && errors.phone}
            maskChar={phoneMaskRule.maskChar}
          />

          <Input
            width="620px"
            autoComplete="off"
            mask={user.nationality === 'E' ? '9999999999999999' : "99999-999"}
            maskChar={user.nationality === 'E' ? '': '_'}
            name="cep"
            value={user.cep}
            onChange={getCep}
            placeholder="CEP"
            errorMessage={touched['cep'] && errors && errors.cep}
          />

          <Input
            visible={address}
            width="620px"
            name="address"
            value={user.address}
            onChange={handleChange}
            placeholder="Endereço"
            errorMessage={touched['address'] && errors && errors.address}
          />

          <Input
            visible={address}
            width="285px"
            name="numberAddress"
            value={user.numberAddress}
            onChange={handleChange}
            placeholder="Número"
            errorMessage={touched['numberAddress'] && errors && errors.numberAddress}
          />

          <Input
            visible={address}
            width="285px"
            name="complementAddress"
            value={user.complementAddress}
            onChange={handleChange}
            placeholder="Complemento"
            errorMessage={touched['complementAddress'] && errors && errors.complementAddress}
          />

          <Input
            visible={address}
            width="285px"
            name="stateAddress"
            value={user.stateAddress}
            onChange={handleChange}
            placeholder="Estado"
            errorMessage={touched['stateAddress'] && errors && errors.stateAddress}
          />

          <Input
            visible={address}
            width="285px"
            name="cityAddress"
            value={user.cityAddress}
            onChange={handleChange}
            placeholder="Cidade"
            errorMessage={touched['cityAddress'] && errors && errors.cityAddress}
          />

          <Input
            width="285px"
            onChange={handleChange}
            name="email"
            placeholder="E-mail"
            type="text"
            value={user.email}
            onPaste={(event) => disablePast(event)}
            errorMessage={touched['email'] && errors && errors.email}
          />

          <Input
            width="285px"
            onChange={handleChange}
            name="confirmEmail"
            placeholder="Confirme seu e-mail"
            type="text"
            value={user.confirmEmail}
            onPaste={(event) => disablePast(event)}
            errorMessage={touched['confirmEmail'] && errors && errors.confirmEmail}
          />

          <Input
            width="285px"
            onChange={handleChange}
            name="password"
            passwordShow={true}
            placeholder="Senha"
            type="password"
            value={user.password}
            onPaste={(event) => disablePast(event)}
            errorMessage={touched['password'] && errors && errors.password}
            height="65px"
            labelMessage={
              ' Sua senha deve conter no mínimo 8 dígitos, 1 caractere maiúsculo, 1 minúsculo, 1 número e um caractere especial (@#$&*)'
            }
          />

          <Input
            width="285px"
            onChange={handleChange}
            passwordShow={true}
            name="confirmPassword"
            placeholder="Repita a senha"
            type="password"
            value={user.confirmPassword}
            onPaste={(event) => disablePast(event)}
            height="65px"
            errorMessage={touched['confirmPassword'] && errors && errors.confirmPassword}
          />
        </Inputs>

        <DivTerms>
          <Terms onClick={() => setUser({ ...user, terms: !user.terms })}>
            <CheckBox checked={user.terms} />
            <p>
              Eu concordo que o Hospital Israelita Albert Einstein armazene e processe meus dados
              pessoais.{' '}
              <a target="_blank" href="/termos.pdf">
                Termos de Serviço.
              </a>
            </p>
          </Terms>
          <ErrorMessage>{touched && errors && errors.terms}</ErrorMessage>

          <Terms onClick={() => setUser({ ...user, planTerm: !user.planTerm })}>
            <CheckBox checked={user.planTerm} />
            <p>
              Termos do{' '}
              <a target="_blank" href="/contrato.pdf">
                contrato de prestação
              </a>{' '}
              de serviço
            </p>
          </Terms>
          <ErrorMessage>{touched && errors && errors.planTerm}</ErrorMessage>

          <Terms onClick={() => setUser({ ...user, comunication: !user.comunication })}>
            <CheckBox checked={user.comunication} />
            <p>
              Envio de comunicações relevantes para sua saúde e para a prestação de serviços nas
              unidades Einstein, em diferentes canais (físicos ou digitais), apurados com base no
              histórico de utilização dos serviços, demografia, residência e preferências pessoais
              informadas.
            </p>
          </Terms>
        </DivTerms>

        <Button type="submit">Cadastrar</Button>
      </Main>
    </Container>
  );
}
