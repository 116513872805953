import { call, put, all, select } from "redux-saga/effects";
import {
  setLoading,
  verifyAuth,
  reSendEmail,
  resetUploadDocs,
} from "../actions/utils.actions";
import {
  openModalUser,
  openModalVerification,
  openModalPin,
  setPhone,
  setUser,
  openModalCardNumber,
  setCurrentPrivatePlan
} from "../actions/user.actions";

import { setSelectedPatient } from "../actions/patient.actions";
import { getAllDependents } from "../actions/depedents.actions";
import { show } from "../../services/alert";
import { goBack, push } from "../../services/navigate";
import { generateDate } from "../../utils/date";
import api from "../../services/api";
import { videoCall } from "../../redux/actions/call.actions";
import { patientObjHasDocuments, stripPrefix } from "../../utils/documentHelpers"
import {compressImage, isImageType} from "../../utils/compression"

export function* login({ payload }) {
  yield put(setLoading(true));

  try {
    const { username, password } = payload;
    const request = () => api.post("/login", { username, password });
    const { data } = yield call(request);

    localStorage.setItem("@access_token", data.token);

    yield all([put(getAllDependents())]);
    yield (window.location.href = "/provedores");
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error === "UserNotConfirmedException"
    ) {
      return show(
        error.response.data.message,
        true,
        reSendEmail({ username: payload.username })
      );
    }
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* register({ payload }) {
  yield put(setLoading(true));

  const birthDate = generateDate(payload.birthDate);
  // console.log('payload', payload)
  const newUser = {
    name: payload.name,
    lastName: payload.lastName,
    birthDate,
    documentType: payload.documentType,
    nationality: payload.nationality,
    document: payload.document,
    phone: payload.phone,
    email: payload.email,
    password: payload.password,
    sex: payload.sex,
    rg: payload.rg,
    userTerms: true,

    // dados de endereço
    address: payload.cep,
    numberAddress: payload.numberAddress,
    stateAddress: payload.stateAddress,
    cityAddress: payload.cityAddress,
    streetAddress: payload.address,
    complementAddress: payload.complementAddress,
  };



  try {
    const request = () => api.post("/user/create", newUser);
    const { data } = yield call(request);

    const buy = localStorage.getItem("buy-flag");

    if (buy && data && data.tokenBuy) {
      localStorage.setItem("@buy_token", data.tokenBuy);
      localStorage.removeItem("buy-flag");
      
      const plan = localStorage.getItem("@current_plan");
      const selected = JSON.parse(plan)
      if(plan){
        return push(`/comprar/${selected.namePlan}`);
      }else{
        return push("/comprar");
      }
    }

    show(
      "Usuário cadastrado com sucesso, verifique seu e-mail para confirmar sua conta"
    );
    return push("/login");
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* editUser({ payload }) {
  yield put(setLoading(true));
  const birthDate = generateDate(payload.birthDate);

  const updateUser = {
    birthDate,
    documentType: payload.documentType,
    id_conecta: payload.id_conecta,
    lastName: payload.lastName,
    name: payload.name,
    phone: payload.phone,
    sex: payload.sex,
  };


  try {
    if (payload.image && typeof payload.image === "object") {
      const uploadPhoto = new FormData();
      uploadPhoto.append("file", payload.image);
      uploadPhoto.append("documentType", "PROFILE_PIC")

      const uploadFile = () => api.post("/upload/file", uploadPhoto);
      const uploadResponse = yield call(uploadFile);

      updateUser.image = stripPrefix(uploadResponse.data.location);
    }

    const uploadUser = () => api.put("/user/edit", updateUser);

    const { data } = yield call(uploadUser);
    yield all([put(openModalUser(false)), put(verifyAuth())]);

    show(data.message);
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* sendLinkRecovery({ payload }) {
  yield put(setLoading(true));

  try {
    const { username } = payload;
    const request = () => api.post("/user/reset-password", { username });
    yield call(request);
    push("/home");
    show("Enviamos um link de redefinição de senha para o e-mail informado");
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* recoveryPassword({ payload }) {
  yield put(setLoading(true));

  try {
    const { username, password, codeConfirm } = payload;
    const request = () =>
      api.post("/user/confirm-recover-password", {
        username,
        password,
        codeConfirm,
      });
    const { data } = yield call(request);
    yield show(data.message);
    push("/login");
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* changePassword({ payload }) {
  yield put(setLoading(true));

  const dataRequest = {
    username: payload.email,
    password: payload.lastPassword,
    newPassword: payload.newPassword,
  };

  try {
    const request = () => api.put("/user/password-change", dataRequest);
    const { data } = yield call(request);
    if (data && data.message) {
      yield show(data.message);
      yield put(openModalUser(false));
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* findCardNumber({ payload }) {
  yield put(setLoading(true));

  try {
    const request = () => api.post("/carplus/attendance", payload);
    const { data } = yield call(request);

    if (data && data.message) {
      show(data.message);
    } else {
      yield all([
        put(setSelectedPatient(true)),
        put(openModalCardNumber(false)),
      ]);
    }
  } catch (error) {
    console.log("ERRO::: ", error);
  } finally {
    yield put(setLoading(false));
  }
}

function getNameProvider(name, selected_provider) {
  let nameProvider = selected_provider
    .toLowerCase()
    .includes(name.toLowerCase());
  if (nameProvider) {
    return name;
  }
}

export function* findPhoneNumber({ payload }) {
  yield put(setLoading(true));

  const providerSelect = payload.empresas[0].descricao;

  const { phoneNumber } = payload;
  const number = { number: phoneNumber };

  try {
    if (getNameProvider("Vivo", providerSelect)) {
      const request = () => api.post("/vivo/verify-pin", number);
      const { data } = yield call(request);

      if (data) {
        yield all([put(openModalVerification(false)), put(openModalPin(true))]);
        yield all([put(setPhone(phoneNumber))]);
      }
    }

    if (getNameProvider("Claro", providerSelect)) {
      const request = () => api.post("/claro/verify-pin", number);
      const { data } = yield call(request);

      if (data) {
        yield all([put(openModalVerification(false)), put(openModalPin(true))]);
        yield all([put(setPhone(phoneNumber))]);
      }
    }
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* findPin({ payload }) {
  let provider = localStorage.getItem("@selected_provider");

  provider = JSON.parse(provider);

  const providerSelect = provider.descricao;

  yield put(setLoading(true));

  try {
    if (getNameProvider("Claro", providerSelect)) {
      const request = () => api.post("/claro/check-pin", payload);
      const { data } = yield call(request);

      if (data) {
        yield all([put(openModalPin(false))]);
        yield all([put(setSelectedPatient(true))]);
      }
    }
    if (getNameProvider("Vivo", providerSelect)) {
      const request = () => api.post("/vivo/check-pin", payload);
      const { data } = yield call(request);

      if (data) {
        yield all([put(openModalPin(false))]);
        yield all([put(setSelectedPatient(true))]);
      }
    }
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* sendUploadDocuments({ payload }) {
  yield put(setLoading(true));
  const user = yield select((state) => state.userReducer.user);
  const dependent = payload.dependent;
  const { files, selectedDocumentType } = yield select(
    (state) => state.utilsReducer.documentsUpload
  );

  async function createFileFromBlobUrl(url, name, type) {
    if (typeof window === "undefined") return;
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: type,
    };
    return new File([data], url, metadata);
  }

  async function createFilesArray() {
    const fileBuffers = await Promise.all(
      files.map((elem) => {
        console.log(elem)
        return createFileFromBlobUrl(elem.preview, elem.name, elem.type)
      }
      )
    );
    return fileBuffers;
  }

  async function compressImages(files) {
    const fileBuffers = await Promise.all(
      files.map(async (elem) =>
        await compressImage(elem)
      )
    );
    return fileBuffers;
  }

  const rawFileObjects = yield call(createFilesArray)
  const fileObjects = yield call(compressImages, rawFileObjects);
  const isDependent = dependent !== undefined && dependent !== null;
  const formData = new FormData();
  fileObjects.forEach((elem, idx) => {
    elem.originalname = files[idx].originalname;
    formData.append("files", elem, files[idx].originalName);
  });
  formData.set("selectedDocument", selectedDocumentType);

  if (isDependent) {
    formData.set("dependent", JSON.stringify(dependent));
  }

  try {
    const request = () =>
      api.post("/upload/signup_documents", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    const response = yield call(request);

    const signUpDocuments = response.data;

    if (response && response.message) {
      show(response.message);
    } else {
      goBack();
      yield put(resetUploadDocs());
      // yield put(verifyAuth());

      if (isDependent) {
        yield put(getAllDependents());
      }else{
        const newUser = {
          ...user,
          signUpDocuments: signUpDocuments.signUpDocuments,
        };
        yield put(setUser(newUser));
      }
    }
  } catch (error) {
    const { data } = error.response;
    show(data.message);
  } finally {
    yield all([put(setLoading(false)), put(verifyAuth())]);
  }
}
export function* sendUploadFiles({ payload }) {
  const getReducer = (state) => {
    return {
      patient: state.patientReducer.patient,
      disease: state.utilsReducer.currentDisease,
    };
  };


  async function compressImages(files) {
    const fileBuffers = await Promise.all(
      files.map(async (elem) => {
        if (isImageType(elem.type)) {
          const compressed = await compressImage(elem);
          // compressed.name = elem.name.toLowerCase()
          return compressed
        } 

        return new Promise((resolve, _reject) => resolve(elem));
      })
    );
    return fileBuffers;
  }

  const rawFiles = payload.getAll("files")
  const files = yield call(compressImages, rawFiles)

  payload.delete("files")
  files.forEach(file => payload.append("files", file, file.name))

  yield put(setLoading(true));

  try {
    const request = () => api.post("/upload/files", payload);
    const response = yield call(request);

    const docsReferences = response.data;

    if (response && response.message) {
      show(response.message);
    } else {
      const dataReducer = yield select(getReducer);
      yield all([put(videoCall({ docsReferences, dataReducer }))]);
    }
  } catch (error) {
    yield put(setLoading(false));
  }
}

export function* patientSelected({ payload }) {
  const patient = yield select((state) => state.patientReducer.patient);
  const titularId = yield select((state) => state.userReducer.user._id);
  const selectedPatient = payload;
  const selectedPatientHasDocs = patientObjHasDocuments(patient)

  const isDependent = patient._id !== titularId;

  if (!selectedPatient) return;

  if (selectedPatientHasDocs) return;
  if (isDependent) {
    push("/documentos", {
      dependent: JSON.stringify({
        firstName: patient.name || patient.firstName,
        lastName: patient.lastName,
        birthDate: patient.birthDate,
        documentNumber: patient.document || patient.documentNumber,
      }),
    });
    return;
  }
  push("/documentos");
}


export function* currentPrivatePlanRequested({ payload }) {
  const idConvenio = payload
  yield put(setLoading(true));

  try {
    const request = () => api.post(`/plans/getCurrentPlan/`, {idConvenio: idConvenio});
    const { data } = yield call(request);

    yield put(setCurrentPrivatePlan(data))
  } catch (error) {
  }
}