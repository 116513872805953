import React, { useEffect } from "react"
import Routes from "./routes"
import Loading from "./components/loading"
import GlobalStyles from "./global-styles"

import {
  Container,
  Options,
  OptionItem,
  User,
  ImageUser,
  Message,
  ModalConfirm,
  Buttons,
  Button,
} from "./global-styles"

import { useAlert } from "react-alert"
import { setAlert } from "./services/alert"
import { useSelector, useDispatch } from "react-redux"
import { openModalUser } from "./redux/actions/user.actions"
import {
  setVisibleOptions,
  modalPlanB2B,
  generateBuyToken,
} from "./redux/actions/utils.actions"
import { changePlan } from "./redux/actions/buy.actions"
import { push } from "./services/navigate"
import Modal from "./components/modal"

import { ThemeProvider } from "styled-components";
import theme from "./assets/theme"

export default function App() {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.utilsReducer.loading)
  const whiteLabelOptions = useSelector(
    (state) => state.utilsReducer.whiteLabelOptions
  )

  const changePlanUserB2B = useSelector(
    (state) => state.utilsReducer.changePlanUserB2B
  )
  const menuVisible = useSelector((state) => state.utilsReducer.menuVisible)
  const user = useSelector((state) => state.userReducer.user)
  const optionsVisible = useSelector(
    (state) => state.utilsReducer.optionsVisible
  )

  let provider = localStorage.getItem("@selected_provider");

  provider = JSON.parse(provider);

  const alert = useAlert()

  const options = [
    {
      id: "HOME",
      label: "Meu perfil",
      action: () => goToPerfil(),
    },
    {
      id: "DEPENDENTES",
      label: "Dependentes",
      action: () => goToDependents(),
    },
    {
      id: "ALTERAR_CONTRATO",
      label: "Alterar contrato",
      action: () => goToChangePlan(),
    },
    {
      id: "COMO_CANCELAR",
      label: "Como cancelar",
      action: () => goToCancel(),
    },
    {
      id: "TROCAR_PROVEDOR",
      label: "Trocar provedor",
      action: () => goToProvider(),
    },
    {
      id: "TROCAR_CARTAO_DE_CREDITO",
      label: "Trocar cartão de crédito",
      action: () => goToChangeCreditCard(),
    },
    {
      id: "SAIR",
      label: "Sair",
      action: () => logout(),
    },
  ]

  useEffect(() => {
    setAlert(alert)
  }, [])

  function goToChangePlan() {
    if (provider) {
      let idConvenio = provider.id_convenio;
      dispatch(changePlan(idConvenio));
      dispatch(setVisibleOptions(!optionsVisible));
    } else {
      dispatch(changePlan());
      dispatch(setVisibleOptions(!optionsVisible));
    }
  }

  function goToCancel() {
    push("/cancelar")
    dispatch(setVisibleOptions(!optionsVisible))
  }

  function goToPerfil() {
    push("/home")
    dispatch(openModalUser(true))
    dispatch(setVisibleOptions(!optionsVisible))
  }

  function goToDependents() {
    push("/dependentes")
    dispatch(setVisibleOptions(!optionsVisible))
  }

  function goToChangeCreditCard() {
    push("/trocar-cartao");
    dispatch(setVisibleOptions(!optionsVisible));
  }

  function goToBuy() {
    push("/comprar")
    dispatch(setVisibleOptions(!optionsVisible))
  }

  function goToProvider() {
    push("/provedores")
    localStorage.removeItem("@selected_provider")
    dispatch(setVisibleOptions(!optionsVisible))
  }

  function logout() {
    localStorage.removeItem("@access_token")
    window.location.href = "/login"
    dispatch(setVisibleOptions(!optionsVisible))
  }

  function getMessage() {
    const dateNow = new Date()
    const hour = dateNow.getHours()

    if (hour < 5) {
      return "Boa noite"
    }

    if (hour < 12) {
      return "Bom dia"
    }

    if (hour < 18) {
      return "Boa tarde"
    }

    return "Bem-vindo"
  }

  function toggleOptions() {
    dispatch(setVisibleOptions(!optionsVisible))
  }

  function closeModalConfirm() {
    dispatch(modalPlanB2B(false))
  }

  function acceptedModalConfirm() {
    dispatch(modalPlanB2B(false))
    dispatch(generateBuyToken())
  }

  function getCurrentOptions() {
    const hasPrivatePlan =
      provider.id_convenio == process.env.REACT_APP_ID_AGREEMENT_PARTICULAR;

    const isPlanAvulso = provider.subgrupo === 'PLANO AVULSO B2C';

    if (isPlanAvulso)
      return options.filter((elem) => (
        elem.id !== "TROCAR_CARTAO_DE_CREDITO"
      ));

    if (!hasPrivatePlan)
      return options.filter((elem) => (
        elem.id !== "COMO_CANCELAR" && elem.id !== "TROCAR_CARTAO_DE_CREDITO"
      ));

    return options;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <GlobalStyles />
        <Routes />

        {changePlanUserB2B && (
          <Modal isOpen={true}>
            <ModalConfirm>
              <h1>Deseja continuar ?</h1>
              <p>
                Você esta logado com um contrato corporativo que não permite
                alterações. Deseja adquirir um contrato particular?
              </p>
              <Buttons>
                <Button outline onClick={closeModalConfirm}>
                  Cancelar
                </Button>
                <Button contained onClick={acceptedModalConfirm}>
                  Continuar
                </Button>
              </Buttons>
            </ModalConfirm>
          </Modal>
        )}

        {menuVisible && (
          <User>
            <Message white={whiteLabelOptions}>
              Olá, {getMessage()}. <br />{" "}
              <b>{`${user.name || ""} ${user.lastName || ""}`}</b>
            </Message>
            <ImageUser
              onClick={toggleOptions}
              src={`${user.image || "img/default.png"}`}
              onError={(e) => e.target.src = "img/default.png"}
            />
          </User>
        )}

        {optionsVisible && (
          <Options>
            {getCurrentOptions().map((option) => (
              <OptionItem onClick={() => option.action()}>
                {option.label}
              </OptionItem>
            ))}
          </Options>
        )}

        {loading && <Loading />}
      </Container>
    </ThemeProvider>
  );
}
