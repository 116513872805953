import { call, put } from "redux-saga/effects";
import { setLoading } from "../actions/utils.actions";
import { show } from "../../services/alert";
import { setSelectedPatient } from "../actions/patient.actions";
import { push } from "../../services/navigate";
import api from "../../services/api";

export function* callVideoConference({ payload }) {
  let patient = {};
  let disease = {};
  let docsReferences = {};

  if (payload && payload.dataReducer) {
    patient = payload.dataReducer.patient;
    disease = payload.dataReducer.disease.titleDisease;
    docsReferences = payload.docsReferences;
  } else {
    patient = payload;
    disease = payload.titleDisease;
  }

  yield put(setLoading(true));

  const provider = JSON.parse(localStorage.getItem("@selected_provider"));

  let dataRequest = {};

  if (patient.holderId) {
    if (patient.idVida && patient.idVida > 0 && !patient._id) {
      dataRequest = {
        idVida: payload.idVida
          ? payload.idVida
          : payload.dataReducer.patient.idVida,
        provider: provider,
      };
    } else {
      dataRequest = {
        dependentId: payload._id
          ? payload._id
          : payload.dataReducer.patient._id,
        provider: provider,
      };
    }
  } else {
    dataRequest = {
      provider: provider,
    };
  }

  try {
    const request = () =>
      api.post("/truclinic", {
        ...dataRequest,
        docsReferences: docsReferences,
        titleDisease: disease,
      });
    const { data } = yield call(request);

    yield push("/home");
    yield put(setSelectedPatient(false));

    if (data && data.visitUrl) {
      window.location.href = data.visitUrl;
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      show(error.response.data.message);
    }
  } finally {
    yield put(setLoading(false));
  }
}
