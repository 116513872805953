import React, { useEffect } from "react";
import Container from "../../container";

import Disease from "./disease";
import SelectPatient from "./select-patient";
import { useSelector } from "react-redux";
import { setSelectedPatient } from "../../redux/actions/patient.actions";

export default function App() {
  const selectedPatient = useSelector(
    (state) => state.patientReducer.patientSelected
  );

  useEffect(() => {
    localStorage.removeItem("@change_plan");
    localStorage.removeItem("@current_plan");
    localStorage.removeItem("@buy_token");
  }, []);

  return (
    <Container>
      {selectedPatient ? (
        <Disease onClose={setSelectedPatient} />
      ) : (
        <SelectPatient setSelectedPatient={setSelectedPatient} />
      )}
    </Container>
  );
}
