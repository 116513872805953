import React from "react";
import SideBar from "../components/side-bar";
import MenuMobile from "../components/menu-mobile";

import { Container, Main } from "./styles";

export default function App(props) {
  return (
    <Container>
      <SideBar />
      <MenuMobile />

      <Main>{props.children}</Main>
    </Container>
  );
}
