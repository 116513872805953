import React, { useEffect } from "react";
import {
  Instructions,
  LabelInstructions,
  InstructionsDiv,
  InstructionsItem,
  DivImageInstruction,
  ImageInstruction,
  NumberImageInstruction,
  TitleInstruction,
  TextInstruction,
} from "./styles";

import { AdvantagesJson } from "../objects";

export default function App() {
  return (
    <Instructions id="como-funciona">
      <LabelInstructions>Como funciona</LabelInstructions>

      <InstructionsDiv>
        {AdvantagesJson.map((item, index) => (
          <InstructionsItem key={index}>
            <DivImageInstruction>
              <ImageInstruction src={item.img} />
              <div id="layer" />
            </DivImageInstruction>
            <TitleInstruction>{item.title}</TitleInstruction>
            <TextInstruction>{item.label}</TextInstruction>
            {item.disclaimer && <TextInstruction>{item.disclaimer}</TextInstruction>}
          </InstructionsItem>
        ))}
      </InstructionsDiv>
    </Instructions>
  );
}
