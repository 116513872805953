import styled, { css } from "styled-components";

import { motion } from "framer-motion";
import animations from "../../utils/animation";

export const Container = styled(motion.div).attrs(animations)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  height: 100vh;
`;

export const DivLogo = styled.div`
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 30px;

  @media (max-width: 1180px) {
    display: none;
  }
`;

export const DivLogoWhite = styled.div`
  display: none;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 30px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0)
  );

  @media (max-width: 1180px) {
    display: flex;
  }
`;

export const Logo = styled.img`
  width: 200px;

  @media (max-width: 1024px) {
    width: 140px;
  }
`;

export const Steps = styled.div`
  width: 600px;
  background: "#F1F1F1";

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 320px) {
    width: 300px;
  }
`;

export const DivMain = styled.div`
  width: 100%;
  margin: 20px 0 40px;
  height: 100vh;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 0;
  }
`;

export const ContainerBuy = styled.div`
  width: auto;
  min-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DivButtonBack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1024px) {
    width: calc(100% - 40px);
    margin-left: 40px;
  }
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
`;

export const LabelBack = styled.p`
  margin-left: 10px;
  color: #707070;
`;

export const IconBack = styled.img`
  width: 20px;
  height: 20px;
`;

export const AlertDiv = styled.div`
  width: 784px;
  height: 481px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 2px 2px rgba(230, 230, 230, 1);
  margin-top: 200px;
`;

export const TitleAlert = styled.h1``;

export const SubTitleAlert = styled.p`
  color: #707070;
  width: 100%;
  text-align: center;
`;

export const IconAlert = styled.img`
  width: 70px;
  height: 70px;
  margin: 20px 0;
`;

export const DivLabels = styled.div`
  margin-left: 15px;
  margin-right: 20px;
`;
export const LabelSchedule = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 16px;
`;
export const SubLabelSchedule = styled.div`
  width: 100%;
  font-size: 14px;
  color: #909090;
  margin-top: 5px;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 40px;
  @media (max-width: 320px) {
    flex-direction: column;
  }
`;

export const Icon = styled.img`
  width: 50px;
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
`;

export const Button = styled.button`
  width: 160px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;

  ${(props) => props.contained && contained}
  ${(props) => props.outline && outline}

  @media (max-width: 1024px) {
    margin: 0 20px;
    min-width: 100px;
  }
`;

const contained = css`
  background: #e0832c;
  color: #fff;
`;

const outline = css`
  border: 1px solid #e0832c;
  color: #e0832c;
`;
