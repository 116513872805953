import {
  SET_LOADING_MODAL,
  SET_LOADING,
  MODAL_DEPENDENT,
  SET_LOGGED,
  SET_LOADED,
  VERIFY_AUTH,
  SET_DISEASE,
  SEND_EMAIL,
  SET_ACTIVE_STEP,
  SET_LABEL_WHITE,
  SET_MENU_VISIBLE,
  SET_LIST_DISEASES,
  SET_VISIBLE_OPTIONS,
  MODAL_PLAN_B2B,
  SET_DOCS_UPLOAD_STEP,
  SET_DOCS_UPLOAD_STEPS,
  SET_DOCS_UPLOAD_FILES,
  SKIP_PROFILE_PIC_UPLOAD_STEP,
  SET_SELECTED_DOC_TYPE,
  RESET_UPLOAD_DOCS,
  SET_MODAL_CONFIRM,
  MODAL_CONFIRM_DISMISSED
} from "../actions/action-types";

import {
  initialUploadDocsSteps
} from "../../pages/upload-documents"

const initialState = {
  loadingModal: false,
  loading: false,
  modalDependent: false,
  logged: false,
  loaded: false,
  activeStep: 1,
  whiteLabelOptions: false,
  menuVisible: false,
  currentDisease: {},
  diseases: [],
  optionsVisible: false,
  changePlanUserB2B: false,
  documentsUpload: {
    currentStep: 0,
    files: [],
    selectedDocumentType: "",
    skipProfilePic: false,
    steps: initialUploadDocsSteps
  },
  modalConfirm: {
    visible: false,
    text: "",
    loading: false,
    cancelText: "Cancelar",
    confirmText: "Confirmar"
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MODAL_PLAN_B2B:
      return {
        ...state,
        changePlanUserB2B: action.payload,
      };
    case SET_VISIBLE_OPTIONS:
      return {
        ...state,
        optionsVisible: action.payload,
      };
    case SET_LIST_DISEASES:
      return {
        ...state,
        diseases: action.payload,
      };
    case SET_MENU_VISIBLE:
      return {
        ...state,
        menuVisible: action.payload,
      };
    case SET_LABEL_WHITE:
      return {
        ...state,
        whiteLabelOptions: action.payload,
      };
    case SET_LOADING_MODAL:
      return {
        ...state,
        loadingModal: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case MODAL_DEPENDENT:
      return {
        ...state,
        modalDependent: action.payload,
      };
    case SET_LOGGED:
      return {
        ...state,
        logged: action.payload,
      };
    case SET_LOADED:
      return {
        ...state,
        loaded: action.payload,
      };
    case VERIFY_AUTH:
      return {
        ...state,
      };
    case SET_DISEASE:
      return {
        ...state,
        currentDisease: action.payload,
      };
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_DOCS_UPLOAD_STEP:
      return {
        ...state,
        documentsUpload: {
          ...state.documentsUpload,
          currentStep: action.payload,
        },
      };
    case SET_DOCS_UPLOAD_STEPS:
      return {
        ...state,
        documentsUpload: {
          ...state.documentsUpload,
          steps: action.payload
        }
      }
    case SET_DOCS_UPLOAD_FILES:
      return {
        ...state,
        documentsUpload: {
          ...state.documentsUpload,
          files: action.payload,
        },
      };
    case SKIP_PROFILE_PIC_UPLOAD_STEP:
      return {
        ...state,
        documentsUpload: {
          ...state.documentsUpload,
          skipProfilePic: !state.documentsUpload.skipProfilePic
        }
      }
    case SET_SELECTED_DOC_TYPE:
      return {
        ...state,
        documentsUpload: {
          ...state.documentsUpload,
          selectedDocumentType: action.payload
        }
      }
    case RESET_UPLOAD_DOCS:
      return {
        ...state,
        documentsUpload: {
          ...initialState.documentsUpload
        }
      }
    case SET_MODAL_CONFIRM:
      return {
        ...state,
        modalConfirm: {
          ...action.payload
        }
      }
    case MODAL_CONFIRM_DISMISSED:
        return {
          ...state,
          modalConfirm: {
            ...state.modalConfirm,
            visible: false
          }
        }
    default:
      return state;
  }
};
