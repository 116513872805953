import { SET_PATIENT, PATIENT_SELECTED } from "../actions/action-types";

const initialState = {
  patient: {},
  patientSelected: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PATIENT:
      return {
        ...state,
        patient: action.payload,
      };
    case PATIENT_SELECTED:
      return {
        ...state,
        patientSelected: action.payload,
      };
    default:
      return state;
  }
};
