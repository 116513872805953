import React, { useEffect, useState } from "react";

import Container from "../../container";
import {
  Main,
  ContainerHistory,
  DivHistory,
  DateHour,
  First,
  Second,
  Header,
  ButtonDocuments,
  TitleScheduled,
  SubTitleSchedule,
  DivTitle,
  TitleSchedule,
  Infos,
  SelectPatient,
  DivSelect,
} from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { getHistory } from "../../redux/actions/history.actions";

import ModalHistory from "./modal-history";

export default function App() {
  const dispatch = useDispatch();

  const historyList = useSelector((state) => state.historyReducer.historyList);
  const user = useSelector((state) => state.userReducer.user);
  const dependentsProps = useSelector(
    (state) => state.dependentsReducer.dependents
  );

  const [modalHistory, setModalHistory] = useState(false);

  useEffect(() => {
    dispatch(
      getHistory({
        documentNumber: user.document,
        namePatient: `${user.name} ${user.lastName}`,
      })
    );
  }, []);

  function openDocuments(history) {
    if (history && history.documentsUser) {
      setModalHistory(history);
    }
  }

  function formatDate(date) {
    return date;
  }

  function handlePatient({ target }) {
    const parsedPatient = JSON.parse(target.value);

    const data = {
      documentNumber: parsedPatient.documentNumber || parsedPatient.document,
      namePatient: `${parsedPatient.name || parsedPatient.firstName} ${
        parsedPatient.lastName
      }`,
    };

    dispatch(getHistory(data));
  }

  return (
    <Container>
      <Main>
        <ContainerHistory>
          <DivTitle>
            <TitleSchedule>Histórico de consultas</TitleSchedule>
            <SubTitleSchedule>
              Consulte as informações das suas consultas
            </SubTitleSchedule>
          </DivTitle>

          <SelectPatient>
            <h4>Selecionar paciente</h4>
            <span>
              Selecione o paciente para visualizar a documentação dos
              atendimentos.
            </span>

            <DivSelect htmlFor="selectpatient">
              <select id="selectpatient" onChange={handlePatient}>
                <option
                  label={`${user.name} ${user.lastName}`}
                  value={JSON.stringify(user)}
                />
                {(dependentsProps || []).map((dependent) => {
                  return (
                    <option
                      label={`${dependent.firstName} ${dependent.lastName}`}
                      value={JSON.stringify(dependent)}
                    />
                  );
                })}
              </select>
              <img src="img/arrow-down.png" />
            </DivSelect>
          </SelectPatient>

          {(historyList || []).length === 0 && (
            <Header>
              <DivTitle>
                <TitleScheduled>
                  Você não realizou nenhuma consulta
                </TitleScheduled>
              </DivTitle>
            </Header>
          )}

          {(historyList || []).map((item) => {
            return (
              <DivHistory onClick={() => openDocuments(item)}>
                <First>
                  <DateHour>
                    <p>{formatDate(item.date)}</p>
                    <p>{item.hour}</p>
                  </DateHour>
                  <Infos>
                    <p>Pronto atendimento virtual</p>
                    <b>{item.namePatient}</b>
                  </Infos>
                </First>
                <Second>
                  <ButtonDocuments>Ver documentos emitidos</ButtonDocuments>
                </Second>
              </DivHistory>
            );
          })}
        </ContainerHistory>

        <ModalHistory
          isOpen={modalHistory}
          onClose={() => setModalHistory(false)}
        />
      </Main>
    </Container>
  );
}
