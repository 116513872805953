import React, { useEffect } from "react";
import {
  Container,
  Main,
  Titles,
  Title,
  SubTitle,
  DiasesList,
  DiasesItem,
  DivImageDiases,
  DiasesLabel,
  ButtonBack,
  LabelBack,
  IconBack,
  AuxItem,
} from "./styles";

import { useDispatch, useSelector } from "react-redux";

import { push } from "../../../services/navigate";
import { getDiseases, setDisease } from "../../../redux/actions/utils.actions";

export default function App(props) {
  const dispatch = useDispatch();

  const diseases = useSelector((state) => state.utilsReducer.diseases);

  useEffect(() => {
    dispatch(getDiseases());
  }, []);

  function goToScreening(disease) {
    if (disease && disease.flagDisease !== 1) {
      dispatch(setDisease(disease));
      return push("/triagem");
    }
    dispatch(setDisease(disease));
    return push("/orientacoes");
  }

  return (
    <Container>
      <Main>
        <ButtonBack
          type="button"
          onClick={() => dispatch(props.onClose(false))}
        >
          <IconBack src="img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        <Titles>
          <Title>Motivo da consulta</Title>
          <SubTitle>
            Selecione abaixo o <b>motivo principal</b> da sua consulta:
          </SubTitle>
        </Titles>

        <DiasesList>
          {diseases.map((item) => {
            if (item.showFirst) {
              return (
                <AuxItem>
                  <DiasesItem onClick={() => goToScreening(item)}>
                    {item.image && <DivImageDiases src={item.image} />}
                  </DiasesItem>
                  <DiasesLabel>{item.titleDisease}</DiasesLabel>
                </AuxItem>
              );
            }
          })}
        </DiasesList>

        <DiasesList>
          {diseases.map((item) => {
            if (!item.showFirst) {
              return (
                <AuxItem>
                  <DiasesItem
                    onClick={() => goToScreening(item)}
                    another={true}
                  >
                    {item.image && <DivImageDiases src={item.image} />}
                  </DiasesItem>
                  <DiasesLabel>{item.titleDisease}</DiasesLabel>
                </AuxItem>
              );
            }
          })}
        </DiasesList>
      </Main>
    </Container>
  );
}
