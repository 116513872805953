import styled, { css } from "styled-components";

import { motion } from "framer-motion";
import animations from "../../../utils/animation";

export const Container = styled(motion.div).attrs(animations)`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    overflow: auto;
`;

export const DivLogo = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;

    @media (max-width: 1024px) {
        display: none;
    }
`;

export const Logo = styled.img`
    width: 200px;
`;

export const Main = styled.div`
    min-width: 700px;
    width: 45%;
    height: auto 85%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: 1024px) {
        min-width: 100%;
        width: 100%;
    }
`;

export const Titles = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (max-width: 1024px) {
        align-items: center;
    }
`;

export const Title = styled.h2`
    margin-bottom: 5px;
    font-size: 18px;
`;

export const SubTitle = styled.p`
    color: #707070;
`;

export const Infos = styled.div`
    box-shadow: 0px 0px 9px 0px rgba(150, 150, 150, 0.4);
    width: 628px;
    min-height: 450px;
    height: auto;
    padding: 20px 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px 0 0;
    line-height: 1.5;

    @media (max-width: 1024px) {
        width: 80%;
    }
`;
export const ImageAlert = styled.img`
    margin: 0 0 30px;
`;

export const Texts = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        color: #909090;
        width: 500px;
        text-align: center;
        line-height: 1.5
    }

    ul {
        width: 450px;
        margin: 30px 0;
    }

    li {
        color: #707070;
    }

    @media (max-width: 1024px) {
        width: 80%;

        p {
            width: 80%;
        }

        ul {
            width: 80%;
        }
    }
`;

export const Buttons = styled.div`
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 70px 0 0;
`;

export const Button = styled.button`
    width: ${(props) => (props.width ? props.width : "160px")};
    height: 40px;
    border-radius: 30px;
    display: flex;
    margin: 0 10px;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
        margin: 0 20px;
        min-width: 100px;
    }

    ${(props) => props.contained && contained}
    ${(props) => props.outline && outline}
`;

const contained = css`
    background: #2196f3;
    color: #fff;
`;

const outline = css`
    border: 1px solid #2196f3;
    color: #2196f3;
`;

export const AnotherDiv = styled.div`
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    h4 {
    }

    input {
        margin: 30px 0 0;
        width: 60%;
        padding: 10px 0px;
        border: none;
        border-bottom: 2px solid #909090;
    }

    p {
        margin: 30px 0 0;
        line-height: 1.5;
        text-align: center;
        width: 100%;
        font-size: 14px;
        color: #707070;
    }
`;
