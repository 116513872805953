import styled from "styled-components"

import { motion } from "framer-motion"
import animations from "../../utils/animation"

export const Container = styled(motion.div).attrs(animations)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`

export const ImageForm = styled.div`
  background-image: url("${(props) => props.src}");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  width: 85%;
  height: 100%;

  display: flex;
  justify-content: center;

  h1 {
    font-size: 30px;
    padding-top: 30px;
    margin-left: 120px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

export const Providers = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`

export const ItemProvider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: #707979;
    margin: 5px 0;
    font-weight: bold;
  }
`

export const ImageProvider = styled.div`
  box-shadow: ${(props) => props.active && "0px 0px 2px 1px #4eacd4;"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;


  img {
    width: 100px;
    height: 100px;
  }
`

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 20px;
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 150px;
  height: 30px;
  border-radius: 15px;
  margin-top: 8px 0 6px;
  line-height: 25px;
  font-size: 14px;
  color: #fff;
  background-color: #2196f3;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`

export const Main = styled.form`
  max-width: 405px;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 300;
  background: #fcfcfc;

  @media (max-width: 1024px) {
    width: 100%;
    background-color: #fff;
  }
`

export const DivLogo = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 10px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`
export const DivBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  padding: 0 20px;
  max-height: 100vh;
`

export const Logo = styled.img`
  width: 150px;
  margin-left: 20px;
`

export const Labels = styled.div`
  width: 100%;
  padding: 5px;

  h2 {
    font-weight: bold;
  }

  h3 {
    font-weight: bold;
    font-size: 18px;
  }

  p {
    color: #707070;
    margin: 15px 0;
    font-size: 16px
  }
`

export const ButtonBack = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background: transparent;
`

export const LabelBack = styled.p`
  margin-left: 10px;
  color: #707070;
`

export const LabelItem = styled.p`
  margin-left: 10px;
`

export const IconBack = styled.img`
  width: 20px;
  height: 20px;
`

export const ProviderDescriptionContainer = styled.div`
  text-align: center;
  height: 3.8em;

  p {
    font-weight: bold;
  }
`