import * as yup from "yup"

export default yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("E-mail é obrigatório")
    .test("email-test", "E-mail inválido", function (value) {
      const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

      if (!emailRegexp.test(value)) {
        return false
      } else {
        return true
      }
    }),
  password: yup
    .string()
    .trim()
    .required("Senha é obrigatória")
    .min(8, "Mínimo 8 caracteres"),
})
