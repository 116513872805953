import {LabelDisclaimer} from "./plans-page/styles";

export const AdvantagesJson = [
  {
    img: "img/thumb-01.png",
    title: "Adquira o Einstein Conecta",
    label:
      "Basta escolher a opção de contrato (individual, familiar ou consulta avulsa) pelo site ou aplicativo. É simples e sem burocracia.",
  },
  {
    img: "img/thumb-02.png",
    title: "Receba orientação médica de onde estiver",
    label:
      " Em seguida, você já pode passar por uma consulta com qualidade Einstein. Sem fila e sem espera!",
  },
  {
    img: "img/thumb-03.png",
    title: "Resultado, prescrição e orientação",
    label:
      "Você receberá orientações médicas, receitas e atestados. Os documentos possuem assinatura com certificação digital.",
    disclaimer: "*Exames não inclusos.",
  },
];

export const DifferentialsJson = [
  {
    img: "img/einstein-icon.png",
    text: "Médicos com qualidade Einstein",
  },
  {
    img: "img/diretrizes-icon.png",
    text: "Diretrizes internacionais de atendimento",
  },
  {
    img: "img/sistema-agil-icon.png",
    text: "Sistema ágil e descomplicado",
  },
  {
    img: "img/qualidade-icon.png",
    text: "Serviço de alta qualidade com custo acessível",
  },
  {
    img: "img/pediatrico-icon.png",
    text: "Atendimento adulto e pediátrico",
  },
  {
    img: "img/24horas-icon.png",
    text: "Disponível 24 horas por dia, 7 dias por semana",
  },
];

export const PlansJson = {
  1: {
    description: "Contrato Individual",
    namePlan: "Individual",
    typePlan: "Contrato anual",
    oldPrice: 120,
    newPrice: 59,
    advantages: [
      "Acesso imediato",
      "Seu atendimento de qualquer lugar",
      "Atendimento 24h por dia",
      "Para uso exclusivo de 1 pessoa",
      "Consultas ilimitadas durante o período da pandemia"
    ],
    labelTypePlan: "mensais",
    single: true,
  },
  2: {
    description: "Contrato Familiar 1 dependente",
    namePlan: "Familiar",
    typePlan: "Contrato anual",
    oldPrice: 100,
    newPrice: 39,
    advantages: [
      "Acesso imediato",
      "Seu atendimento de qualquer lugar",
      "Atendimento 24h por dia",
      "Adicione até 9 membros",
      "Consultas ilimitadas durante o período da pandemia"
    ],
    labelTypePlan: "mensais \n por membro",
  },
  3: {
    description: "Consulta Avulsa",
    namePlan: "Consulta Avulsa",
    typePlan: "Consulta Avulsa",
    newPrice: 39,
    advantages: [
      "Acesso imediato",
      "Seu atendimento de qualquer lugar",
      "Atendimento 24h por dia",
    ],

    disclaimer: "* Após contratação, você terá 24 horas para realizar a consulta.",
    labelTypePlan: "pagamento único",
    single: true,
  },
};

export const TopicsJson = [
  {
    title: "Como são feitas as avaliações médicas pelo Einstein Conecta?",
    text:
      "Todas as avaliações são realizadas por videochamada, através da internet, de forma que tanto médico quanto paciente veem um ao outro ao vivo, podendo interagir livremente como em uma consulta face a face. Para o exame físico, o médico observará durante a consulta, através da câmera, o estado geral, o padrão respiratório, sinais de dor e desconforto, além de poder avaliar garganta, olhos e lesões de pele. O médico também poderá pedir ao paciente para realizar determinadas manobras que ajudem a elucidar melhor o diagnóstico e otimizar a conduta. No caso de atendimentos pediátricos, o médico poderá pedir o auxílio do responsável para obter outros dados importantes na avaliação do exame físico. Ele poderá orientar o responsável a auxiliá-lo na avaliação de lesões de pele (textura, descamação, calor local), na avaliação da dor em algum membro ou região do corpo, na avaliação da perfusão da pele, na contagem da frequência respiratória – número de respirações por minuto – entre outros.",
    type: "text",
  },
  {
    title: "Como é feito o diagnóstico?",
    text:
      "Os dados obtidos pelo médico durante a avaliação possibilitarão a construção de uma hipótese diagnóstica, embasada nos mais recentes protocolos clínicos nacionais e internacionais, devidamente adaptados para as avaliações por telemedicina. Todos os atendimentos geram um relatório completo, contendo a hipótese diagnóstica e orientações médicas personalizadas, que serão enviados ao paciente por e-mail. Caso seja pertinente à situação, poderão ser encaminhadas também receitas médicas ou atestados.",
    type: "text",
  },
  {
    title: "E se o médico não conseguir fazer a avaliação a distância?",
    text:
      "Em mais de 80% dos casos a avaliação por telemedicina pelo Einstein Conecta é suficiente para uma orientação médica segura. No entanto, quando é identificado um sinal de gravidade que indique que a consulta por telemedicina não é segura ou houver a necessidade de avaliação física presencial para um diagnóstico mais preciso, o paciente será orientado a procurar um atendimento presencial. Mesmo nestes casos um relatório, explicando as razões do encaminhamento, é enviado ao paciente, que pode levar ao local de atendimento para otimizar a continuidade do cuidado. ",
    type: "text",
  },
  {
    title: "Quem realiza os atendimentos do Einstein Conecta?",
    text:
      "O paciente será atendido pela equipe médica com a qualidade Einstein, com ampla experiência em atendimento de emergências, treinada em atendimento por telemedicina e nos protocolos Einstein de atendimento.",
    type: "text",
  },
];
