import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 0 10px;

  b {
    margin: 5px 0;
  }

  p {
    margin: 0 !important;
  }
`;

export const Main = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 70px 0 0 70px;

  @media (max-width: 1024px) {
    align-items: center;
    margin: 70px 0 0;
  }
`;

export const Titles = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h4`
  margin-bottom: 5px;
`;

export const ContainerHistory = styled.div`
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const SelectPatient = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin: 30px 0;

  h4 {
    margin: 0;
    text-align: left;
    font-weight: bold;
  }

  span {
    width: 300px;
    color: #858585;
  }

  @media (max-width: 1024px) {
    align-items: center;
    justify-content: center;

    h4 {
      width: 300px;
      text-align: left;
      font-weight: bold;
    }

    span {
      color: #858585;
    }
  }
`;

export const DivSelect = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bababa;
  border-radius: 5px;
  background: #fff;
  margin: 10px 0 0;
  height: 30px;
  width: 350px;
  position: relative;
  cursor: pointer;

  select {
    cursor: pointer;
    width: 100%;
    z-index: 100;
    -webkit-appearance: none;
  }

  img{
    cursor: pointer;
    height: 10px;
    position: absolute;
    right: 10px;
  }

  @media (max-width: 1024px) {
    width: 250px;

    select {
      width: 200px;
    }
  }
`;

export const DivHistory = styled.div`
  width: 920px;
  height: 85px;
  background: #fff;
  margin: 20px 0;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 80%;
    height: auto;
    min-height: 150px;
    padding: 10px 15px;
  }
`;

export const First = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0 15px;
    color: #707070;
  }
`;

export const Second = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
    justify-content: ${(props) => (props.topDiv ? "center" : "flex-end")};
    height: ${(props) => (!props.topDiv ? "20px" : "auto")};
    margin-bottom: ${(props) => (!props.topDiv ? "10px" : "0")};
  }
`;

export const Button = styled.button`
  width: 83px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  margin: 0 5px;

  ${(props) => props.blue && blue}
  ${(props) => props.gray && gray}

  @media (max-width: 1024px) {
    margin: 10px;
  }
`;

export const ButtonDocuments = styled.button`
  width: 203px;
  height: 30px;
  border-radius: 20px;
  color: #fff;
  margin: 0 5px;
  background-color: #2196f3;
  font-weight: bold;

  @media (max-width: 1024px) {
    margin: 10px;
  }
`;

const blue = css`
  background-color: rgb(57, 65, 132);
`;

const gray = css`
  background-color: #d1d1d1;
`;

export const DateHour = styled.div`
  text-align: center;
  border-right: 1px solid #bdbdbd;
`;

export const DivDetails = styled.div`
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  p {
    font-weight: lighter;
    width: 70px;
    font-size: 12px;
    margin: 0 5px;
    height: 100%;
  }

  @media (max-width: 1024px) {
    margin: 10px;
    align-items: center;
    justify-content: flex-end;

    p {
      width: auto;
    }
  }
`;

export const Icon = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ImageUser = styled.img`
  width: 41px;
  height: 41px;
  max-width: 41px;
  max-height: 41px;
  border-radius: 40px;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px;
  margin-left: 5%;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;
export const Header = styled.div`
  width: 920px;
  height: 55px;
  background: #fff;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    padding: 20px 20px;
    width: 100%;
    margin: 0;
  }
`;

export const BodyScheduled = styled.div`
  display: flex;
  width: 60%;
  height: 100%;
  margin-top: 15px;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
`;
export const HeaderScheduled = styled.div`
  display: flex;
  width: 70%;
  height: 80%;
  flex-direction: column;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const TitleScheduled = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 16px;
  margin: 30px 0;
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleSchedule = styled.h4`
  width: 250px;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 5px;
`;

export const SubTitleSchedule = styled.p`
  display: flex;
  font-size: 14px;
  color: #909090;
`;
