import React, { useState } from "react";
import {
  Container,
  Main,
  Titles,
  Title,
  SubTitle,
  DivLogo,
  Logo,
  Infos,
  Texts,
  Buttons,
  Button,
  AnotherDiv,
  ImageAlert,
} from "./styles";

import { push } from "../../../services/navigate";
import { show } from "../../../services/alert";

export default function Covid(props) {
  const [disease, setDisease] = useState(null);

  function handleSubmit() {
    if (props.disease.titleDisease === "Outros" && !disease) {
      return show("Informe um motivo para consulta");
    }

    props.initVideoCall();
  }

  function goToHome() {
    push("/home");
  }

  function handleChange(e) {
    setDisease(e.target.value.trim());
  }

  return (
    <Container>
      <DivLogo>
        <Logo src="img/logo-sidebar.png" />
      </DivLogo>

      <Main>
        <Titles>
          <Title>{props.disease.titleDisease}</Title>
          <SubTitle>{props.disease.subTitleDisease}</SubTitle>
        </Titles>

        <Infos>
          {props.disease.titleDisease !== "Outros" && <ImageAlert src="img/alert-message.svg" />}

          <Texts>
            <p>{props.disease.textDisease}</p>
            {props.disease.guidelines && (
              <ul>
                {props.disease.guidelines.length > 0 &&
                  props.disease.guidelines.map((guideline) => <li>{guideline.text}</li>)}
              </ul>
            )}

            {props.disease.titleDisease !== "Outros" &&
              !props.disease.titleDisease.includes("Dúvidas") && <p>Deseja continuar?</p>}

            {props.disease.titleDisease.includes("Dúvidas") && (
              <p>
                Para check-up, doenças crônicas e consulta pediátrica de rotina, por favor procure
                um atendimento presencial.
              </p>
            )}
          </Texts>

          {props.disease.titleDisease === "Outros" && (
            <AnotherDiv>
              <h4>Por favor, descreva o motivo do seu atendimento</h4>
              <input placeholder="Digite o motivo" onChange={handleChange} type="text" />

              <p>
                Obs. O Pronto Atendimento Virtual do Hospital Israelita Albert Einstein é voltado
                para situações clínicas agudas de baixa complexidade. Casos crônicos e outras
                solicitações fogem do escopo do serviço e costumam ser encaminhadas para atendimento
                presencial.
              </p>
            </AnotherDiv>
          )}

          <Buttons>
            <Button outline onClick={goToHome}>
              Voltar
            </Button>
            {props.disease.titleDisease.includes("Dúvidas") && (
              <Button contained width="250px" onClick={handleSubmit}>
                Avançar
              </Button>
            )}
            {props.disease.titleDisease.includes("Outros") && (
              <Button contained width="250px" onClick={handleSubmit}>
                Avançar
              </Button>
            )}
            {!props.disease.titleDisease.includes("Dúvidas") &&
              !props.disease.titleDisease.includes("Outros") && (
                <Button contained width="250px" onClick={handleSubmit}>
                  Continuar mesmo assim
                </Button>
              )}
          </Buttons>
        </Infos>
      </Main>
    </Container>
  );
}
