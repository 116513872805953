import styled, { css } from "styled-components";

const active = css`
  background-color: #2196f3;
  color: #fff;
`;
export const Container = styled.div``;

export const Div = styled.div`
  padding: 40px 0 0 0;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px;
  margin-left: 5%;
  @media (max-width: 1024px) {
    margin: 0;
  }
`;
export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 8%;
`;
export const DivPatient = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  @media (max-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`;
export const DivBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: white;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const ButtomSchedule = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TitleSchedule = styled.h4`
  width: 250px;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 5px;
`;

export const SubTitleSchedule = styled.p`
  width: 100%;
  font-size: 14px;
  color: #909090;
`;
export const SubTitleInfo = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 16px;
  color: #707070;
`;
export const TitleInfo = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 15px;
  color: #b3b3b3;
  @media (max-width: 1024px) {
    margin-top: 0;
    margin-right: 10px;
  }
`;
export const DivScheduled = styled.div`
  display: flex;
  width: 80%;
  height: 100%;
  flex-direction: row;
  padding: 10px;
  margin-left: 20px;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: 0;
  }
`;
export const DivBoxScheduledIcons = styled.div`
  display: flex;
  width: 168px;
  height: 84px;
  flex-direction: column;
  background-color: #f1f1f1;
`;
export const DivHeader = styled.div`
  display: flex;
  width: 60%;
  flex-direction: row;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const BoxDateTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 83px;
  flex-direction: column;
  color: white;
  margin-right: 1px;
  background-color: #2196f3;
`;
export const TitleDate = styled.div`
  font-size: 16px;
`;
export const TitleTime = styled.div`
  font-size: 12px;
  margin-left: 10px;
`;

export const DivBoxScheduledInfo = styled.div`
  display: flex;
  flex-direction: row;
  border: 60px;
  border-color: red;
  justify-content: flex-start;
  width: 100%;
  height: 84px;
  background-color: white;
`;
export const BoxTextScheduled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83px;
  height: 87px;
  flex-direction: column;
  color: white;
  margin-right: 1px;
`;
export const DivButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 25%;
  align-items: center;
  background-color: white;
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    background-color: white;
    margin: 0;
  }
`;
export const Button = styled.button`
  width: 83px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  margin: 0 5px;

  ${(props) => props.blue && blue}
  ${(props) => props.gray && gray}

  @media (max-width: 1024px) {
    margin: 10px;
    width: 60%;
    height: 30px;
  }
`;

const blue = css`
  background-color: #2196f3;
`;

const gray = css`
  background-color: #d1d1d1;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
  margin-top: 20px;
  @media (max-width: 500px) {
    padding: 20px 20px;
    margin: 0;
  }
`;

export const BodyScheduled = styled.div`
  display: flex;
  width: 55%;
  height: 100%;
  margin-top: 15px;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
`;
export const HeaderScheduled = styled.div`
  display: flex;
  width: 70%;
  height: 80%;
  flex-direction: column;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const TitleScheduled = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 14px;
  padding: 15px;
`;

export const SubTitleScheduled = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  color: #909090;
`;
export const ButtonOk = styled.div`
  width: 120px;
  height: 30px;
  border-radius: 15px;
  padding: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #2196f3;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  margin: 20px 0 10px 0;
`;
