import React from "react";
import { FiVideo, FiHelpCircle, FiTrello, FiAlignRight } from "react-icons/fi";

import { MdAttachMoney } from "react-icons/md";

export default [
  {
    icon: <FiAlignRight />,
    label: "Como funciona",
    route: "#como-funciona",
  },
  {
    icon: <FiTrello />,
    label: "Diferenciais",
    route: "#diferenciais",
  },
  {
    icon: <FiHelpCircle />,
    label: "Dúvidas Frequentes",
    route: "#duvidas-frequentes",
  },
  {
    icon: <MdAttachMoney />,
    label: "Contratos",
    route: "#contratos",
  },
];
