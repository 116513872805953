import styled, { css } from "styled-components";

export const Header = styled.div`
  position: relative;
`;

export const Menu = styled.div`
  display: flex;
  position: fixed;
  transition: all 0.3s;
  height: ${(props) => (props.visible ? "70px" : "104px")};
  background: #fff;
  z-index: 300;
  width: 100%;
  min-width: 1120px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0px 5px 5px 0px rgba(204, 204, 204, 1);

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Logo = styled.img`
  height: ${(props) => (props.visible ? "30px" : "49px")};
  transition: all 0.3s;
  margin: 0 20px;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
`;

export const ItemMenu = styled.a`
  cursor: pointer;
  margin: 0 20px;
  color: #404040;
`;

export const ButtonMenu = styled.a`
  height: ${(props) => (props.visible ? "30px" : "40px")};
  width: ${(props) => (props.visible ? "120px" : "165px")};
  font-size: ${(props) => (props.visible ? "12px" : "14px")};

  transition: all 0.3s;
  border-radius: 30px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.contained && contained}
  ${(props) => props.outline && outline}
`;

const contained = css`
  background: #e0832c;
  color: white;
`;

const outline = css`
  border: 1px solid #e0832c;
  color: #e0832c;
  background: transparent;
`;

export const Background = styled.img`
  width: 100%;
  margin: 104px 0 0;
  min-width: 1120px;

  @media (max-width: 1024px) {
    margin: 0;
    width: 100%;
    min-width: 100%;
    margin: 90px 0 0;
    height: auto;
  }
`;

export const User = styled.div`
  width: 190px;
  height: 55px;
  z-index: 300;
  width: 190px;
  height: 55px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Message = styled.p`
  color: ${(props) => (props.white ? "#fff" : "#000")};
  text-align: right;
  font-size: 14px;
  margin: 0;
`;

export const ImageUser = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 36px;
`;

export const Options = styled.div`
  position: absolute;
  width: 171px;
  height: 120px;
  top: 80px;
  right: 25px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const OptionItem = styled.button`
  cursor: pointer;
  width: 75%;
  color: #d1d1d1;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 8px;
  border-bottom: 0.5px solid #808080;
  margin: 10px 0;
`;
