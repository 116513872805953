import React, { useEffect } from "react"
import { Route, Redirect } from "react-router-dom"
import Loading from "../components/loading"
import { verifyAuth } from "../redux/actions/utils.actions"
import { useDispatch, useSelector } from "react-redux"

export default function PrivateRoute(props) {
  const isLoaded = useSelector((state) => state.utilsReducer.loaded)
  const isLogged = useSelector((state) => state.utilsReducer.logged)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(verifyAuth())
  }, [])

  function returnPrivateRoute() {
    const provider = localStorage.getItem("@selected_provider")

    if (!isLogged) {
      return <Redirect to="/login" />
    }

    if (!provider && !window.location.href.includes("/provedores")) {
      return <Redirect to="/provedores" />
    }

    return <Route {...props} />
  }

  return !isLoaded ? <Loading /> : returnPrivateRoute()
}
