import { all, call, put, select, take, delay } from "redux-saga/effects";
import { setLoading } from "../actions/utils.actions";
import { setDependents, getAllDependents } from "../actions/depedents.actions";
import { openModalDependent, setModalConfirm } from "../actions/utils.actions";
import { show } from "../../services/alert";
import api from "../../services/api";
import { generateDate } from "../../utils/date";
import { stripPrefix } from "../../utils/documentHelpers";
import { useSelector } from "react-redux";

export function* getAll({ payload }) {
  yield put(setLoading(true));

  const provider = JSON.parse(localStorage.getItem("@selected_provider"));

  try {
    const request = () => api.post("/dependent/list", provider);
    const { data } = yield call(request);
    yield put(setDependents(data.dependents));
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* addDependent({ payload }) {
  yield put(setLoading(true));

  let provider = localStorage.getItem("@selected_provider");

  provider = JSON.parse(provider);

  const birthDate = generateDate(payload.birthDate);

  const newDependent = {
    documentNumber: payload.document,
    documentType: payload.documentType,
    firstName: payload.firstName,
    lastName: payload.lastName,
    gender: payload.gender,
    birthDate,
    rg: payload.rg,
    idConvenio: provider.id_convenio,
  };

  const uploadPhoto = new FormData();
  uploadPhoto.append("file", payload.image);
  uploadPhoto.append("documentType", "PROFILE_PIC");

  try {
    if (payload.image) {
      const uploadFile = () => api.post("/upload/file", uploadPhoto);
      const uploadResponse = yield call(uploadFile);
      newDependent.image = stripPrefix(uploadResponse.data.location);
    }

    const createDependent = () => api.post("/dependent/new", newDependent);
    yield call(createDependent);
    yield put(openModalDependent(false));
    yield put(getAllDependents());
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* editDependent({ payload }) {
  yield put(setLoading(true));

  const birthDate = generateDate(payload.birthDate);

  const updateDependent = {
    dependentId: payload.dependentId,
    documentNumber: payload.document,
    documentType: payload.documentType,
    firstName: payload.firstName,
    lastName: payload.lastName,
    gender: payload.gender,
    birthDate,
    rg: payload.rg,
  };
  try {
    if (payload.image && typeof payload.image === "object") {
      const uploadPhoto = new FormData();
      uploadPhoto.append("file", payload.image);
      uploadPhoto.append("documentType", "PROFILE_PIC");

      const uploadFile = () => api.post("/upload/file", uploadPhoto);
      const uploadResponse = yield call(uploadFile);

      updateDependent.image = stripPrefix(uploadResponse.data.location);
    }

    const updloadDependent = () =>
      api.put("/dependent/update", updateDependent);

    yield call(updloadDependent);

    yield all([put(openModalDependent(false)), put(getAllDependents())]);
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false));
  }
}
export function* removeDependent({ payload }) {
  const currentDependents = yield select(
    (state) => state.dependentsReducer.dependents
  );
  const idConvenio = payload.provider.id_convenio;
  const dependentId = payload._id;
  const dependent = currentDependents.find((elem) => elem._id === payload._id);

  const confirmModalProps = {
    visible: true,
    confirmText: "Remover",
    cancelText: "Cancelar",
    text: `Deseja remover ${dependent.firstName} dos seus dependentes?`,
    loading: false,
  };

  // Show confirmation modal
  yield put(setModalConfirm(confirmModalProps));

  // Wait for user confirm
  const { type } = yield take(({ type }) => type.startsWith("MODAL_CONFIRM"));
  if (type === "MODAL_CONFIRM_DISMISSED") return;
  if (type !== "MODAL_CONFIRM_ACCEPTED") return;

  yield put(
    setModalConfirm({
      ...confirmModalProps,
      visible: false,
    })
  );
  yield put(setLoading(true))

  try {
    const deleteDependent = () =>
      api.post("/dependent/erase", { idConvenio, dependentId });

    const deleteResponse = yield call(deleteDependent);

    yield put(setLoading(false))

    show(deleteResponse.data.message);

    const newDependents = currentDependents.filter(
      (elem) => elem._id !== payload._id
    );
    yield put(setDependents(newDependents));
  } catch (error) {
    show(error.response.data.message);
  } finally {
    yield put(setLoading(false))
  }
}
