import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  SideBar,
  DivLogo,
  Logo,
  MenuList,
  MenuItem,
  MenuLabel,
} from "./styles";

import itemsMenu from "../../utils/items-side-bar";
import api from "../../services/api";

import { push } from "../../services/navigate";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPatient } from "../../redux/actions/patient.actions";
import { slotByPatient } from "../../redux/actions/schedule.actions";
import { verifyAuth } from "../../redux/actions/utils.actions";


export default function App() {
  const dispatch = useDispatch();

  const [activeRoute, setActiveRoute] = useState("/home");
  const appointments = useSelector((state) => state.schedule.scheduled);

  const user = useSelector((state) => state.userReducer.user);
  const host = window.location.href;

  const [flagScheduling, setFlagScheduling] = useState(false);

  let provider = localStorage.getItem("@selected_provider");

  provider = JSON.parse(provider);

  function getFlagScheduling() {
    api.get("/config-system").then((result) => {
      const flag = result.data.configSystem[0].scheduling;
      setFlagScheduling(flag);
    });
  }

  useEffect(() => {
    dispatch(slotByPatient(user));
    getFlagScheduling();
    const activeRoute = host.split("/")[3];
    if (!activeRoute) {
      return setActiveRoute(`/home`);
    }

    setActiveRoute(`/${activeRoute}`);
  }, []);

  function changeRoute(infosRoute) {
    if (infosRoute.needEgilibility && !user.checkElegibility) {
      return;
    }
    push(infosRoute.route);
    setActiveRoute(infosRoute.route);
    dispatch(verifyAuth())
  }

  function messageToltip(infosRoute) {
    if (infosRoute.needEgilibility && !user.checkElegibility)
      return "Não foi possível checar a sua elegibilidade, favor verificar se você possui algum contrato!";
  }

  function goToHome() {
    push("/home");
    dispatch(setSelectedPatient(false));
  }

  return (
    <SideBar>
      <DivLogo onClick={() => goToHome()}>
        <Logo src="img/logo-sidebar.png" />
      </DivLogo>

      <MenuList>
        {itemsMenu.map((itemMenu, key) => {
          if (host.includes("telemedicina.einstein.br") && itemMenu.debug)
            return;

          return (
            <MenuItem
              notEligibility={
                !user.checkElegibility && itemMenu.needEgilibility
              }
              active={itemMenu.route === activeRoute}
              onClick={() => changeRoute(itemMenu)}
              data-tip={messageToltip(itemMenu)}
              needScheduling={
                (itemMenu.needScheduling && !flagScheduling) ||
                (itemMenu.needScheduling &&
                  provider.validar_tipo_elegibilidade != 0)
              }
              needScheduled={
                (itemMenu.needScheduled && appointments.length === 0) ||
                (itemMenu.needScheduled &&
                  provider.validar_tipo_elegibilidade != 0)
              }
            >
              {itemMenu.icon && itemMenu.icon}
              <MenuLabel active={itemMenu.route === activeRoute}>
                {itemMenu.label}
              </MenuLabel>
            </MenuItem>
          );
        })}
      </MenuList>
    </SideBar>
  );
}
