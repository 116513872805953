import * as yup from "yup"
import moment from "moment"
import { cpf as cpfTest } from "cpf-cnpj-validator"

export const brasileiroSchema =  yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("Nome é obrigatório")
    .min(3, "Nome mínimo 3 caracteres"),
  lastName: yup
    .string()
    .trim()
    .required("Sobrenome é obrigatório")
    .min(3, "Sobrenome mínimo 3 caracteres"),
  birthDate: yup
    .string()
    .trim()
    .required("Data de nascimento é obrigatória")
    .test("date-test", "Data inválida", function (value) {
      if (value) {
        let date = value.replace(/_/g, "")
        if (date.length < 10) {
          return false
        }
        date = moment(value, "DD/MM/YYYY")
        if (!date.isValid()) {
          return false
        }

        function calcAge(birth) {
          const auxBirth = new Date(birth)
          const diference = Date.now() - auxBirth.getTime()
          const age = new Date(diference)

          return age.getUTCFullYear() - 1970
        }

        const age = calcAge(date)

        if (age < 0 || age > 120) {
          return false
        }

        return date
      }
    }),
  gender: yup.string().trim().required("Sexo é obrigatório"),
  document: yup
    .string()
    .trim()
    .required("CPF é obrigatório")
    .test("cpf-test", "CPF Inválido", function (value) {
      if (value) {
        const cpf = value.replace(/[^0-9]+/g, "")
        if (!cpfTest.isValid(cpf)) {
          return false
        } else {
          return true
        }
      }
    }),
  rg: yup.string().trim().required("RG é obrigatório"),
})


//ESTRANGEIRO


export const estrangeiroSchema =  yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("Nome é obrigatório")
    .min(3, "Nome mínimo 3 caracteres"),
  lastName: yup
    .string()
    .trim()
    .required("Sobrenome é obrigatório")
    .min(3, "Sobrenome mínimo 3 caracteres"),
  birthDate: yup
    .string()
    .trim()
    .required("Data de nascimento é obrigatória")
    .test("date-test", "Data inválida", function (value) {
      if (value) {
        let date = value.replace(/_/g, "")
        if (date.length < 10) {
          return false
        }
        date = moment(value, "DD/MM/YYYY")
        if (!date.isValid()) {
          return false
        }

        function calcAge(birth) {
          const auxBirth = new Date(birth)
          const diference = Date.now() - auxBirth.getTime()
          const age = new Date(diference)

          return age.getUTCFullYear() - 1970
        }

        const age = calcAge(date)

        if (age < 0 || age > 120) {
          return false
        }

        return date
      }
    }),
  gender: yup.string().trim().required("Sexo é obrigatório"),
  document: yup
    .string()
    .required('Documento é obrigatório'),
})
