import React, { useState } from "react";
import {
  Container,
  ButtonBack,
  IconBack,
  DivInput,
  DivInputCustom,
  Title,
  SubTitle,
  DivButtons,
  ButtonConfirm,
  InputCardNumber,
} from "./styles";

import "./styles.css";

import {
  findCardNumber,
  openModalCardNumber,
} from "../../../../../redux/actions/user.actions";

import { useDispatch } from "react-redux";
import Modal from "../../../../../components/modal-provider";

export default function App(props) {
  const dispatch = useDispatch();

  const [ref, setRef] = useState(null);

  function checkCardNumber(cardNumber) {
    const user = {};
    const provider = JSON.parse(localStorage.getItem("@selected_provider"));
    user.empresas = [provider];
    user.cardNumber = cardNumber;
    dispatch(findCardNumber(user));
  }

  function handleChangeNumberCard(e) {
    const { value } = e.currentTarget;
    setRef(value);
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <Container>
        <ButtonBack
          type="button"
          onClick={() => dispatch(openModalCardNumber(false))}
        >
          <IconBack src="img/close-icon.png"></IconBack>
        </ButtonBack>

        <Title>Número da carteirinha</Title>
        <SubTitle>
          Para acessar o serviço de Telemedicina Einstein, por favor, informe o
          número de sua carteirinha:
        </SubTitle>

        <DivInput>
          <DivInputCustom>
            <InputCardNumber
              mask="9999999999999999"
              maskChar=""
              maxLength="16"
              placeholder="Insira o número da carteirinha"
              type="text"
              onChange={handleChangeNumberCard}
            ></InputCardNumber>
          </DivInputCustom>
        </DivInput>

        <DivButtons>
          <ButtonConfirm
            onClick={() => {
              checkCardNumber(ref);
            }}
          >
            CONTINUAR
          </ButtonConfirm>
        </DivButtons>
      </Container>
    </Modal>
  );
}
