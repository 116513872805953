import React, { useEffect } from "react";
import { Container, Body } from "./styles";

import Header from "./header";
import Advantages from "./advantages";
import Instructions from "./instructions";
import Differentials from "./differentials";
import Topics from "./topics";
import Plans from "./plans-page";
import Contact from "./contact";
import Footer from "./footer";

import { useDispatch, useSelector } from "react-redux";
import { getPlans } from "../../redux/actions/buy.actions";

import { PlansJson } from "./objects";
import MenuLanding from "../../components/menu-landing";
import ShowsCreditCardsInline from "../../components/credit-card-inline";

export default function App() {
  const dispatch = useDispatch();
  const plansAPI = useSelector((state) => state.buyReducer.plans);

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  return (
    <Container>
      <MenuLanding />
      <Header />
      <Body>
        <Advantages />
        <Instructions />
        <Differentials />
        <Topics />
        <Plans plansAPI={plansAPI} plansJSON={PlansJson} />
        <ShowsCreditCardsInline />
        <Contact />
        <Footer />

      </Body>
    </Container>
  );
}
