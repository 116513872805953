import React, { useState } from "react";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import moment from "moment"

import {
  Container,
  ListPatient,
  PatientSection,
  ImagePatient,
  NamePatient,
  ButtoAddPatient,
  ImgButtonAddPatient,
  ButtonBack,
  LabelBack,
  IconBack,
  DivArrow
} from "./styles";

import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../components/modal";
import {
  setPatient,
  setSelectedPatient,
} from "../../../redux/actions/patient.actions";

import { slotByMonth, slotByPatient } from "../../../redux/actions/schedule.actions"

import { push } from "../../../services/navigate";

export default function App(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userReducer.user);

  const [hiddenArrow, setHiddenArrow] = useState(true);
  
  const dependentsProps = useSelector(
    (state) => state.dependentsReducer.dependents
  );

  const scheduleInitial = {
    date: moment().format("YYYY-MM-DD").toString(),
    user: user,
  }

  function setPatientFC(patient) {
    dispatch(setPatient(patient));
    dispatch(setSelectedPatient(true));
    dispatch(slotByMonth(scheduleInitial))
    dispatch(slotByPatient(user))
    props.onClose()
  }

  function getScrollPosition(el) {
    setHiddenArrow(false);
    const elemento = el.target;
    if (elemento.scrollHeight - elemento.scrollTop === elemento.clientHeight) {
      setHiddenArrow(true);
    }
  }

  function goToDependents() {
    props.onClose();
    push("/dependentes");
  }

  function cutName(fisrtName, lastName) {
    let fullName = `${fisrtName} ${lastName}`;

    // if (fullName && fullName.length > 15) {
    //   return fisrtName;
    // }
    return fullName;
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <Container>
        <ButtonBack type="button" onClick={() => props.onClose()}>
          <IconBack src="img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        <h3>Selecione para quem é o atendimento</h3>
        <ListPatient onScroll={getScrollPosition}>
          <PatientSection onClick={() => setPatientFC(user)}>
            <ImagePatient src={user.image || "img/default.png"} />
            <NamePatient>{`${cutName(user.name, user.lastName)}`}</NamePatient>
          </PatientSection>

          {(dependentsProps || []).map((patientItem) => (
            <PatientSection onClick={() => setPatientFC(patientItem)}>
              <ImagePatient
                src={
                  (patientItem.signUpDocuments &&
                    patientItem.signUpDocuments.image) ||
                  "img/default.png"
                }
                onError={(e) => e.target.src = "img/default.png"}
              />
              <NamePatient>{`${cutName(
                patientItem.firstName,
                patientItem.lastName
              )}`}</NamePatient>
            </PatientSection>
          ))}

          <PatientSection onClick={() => goToDependents()}>
            <ButtoAddPatient>
              <ImgButtonAddPatient src="img/icons/addDependent.svg" />
            </ButtoAddPatient>
            <NamePatient>Adicionar</NamePatient>
          </PatientSection>
        </ListPatient>
        {!hiddenArrow && (
          <DivArrow>
            <ArrowDownwardIcon />
          </DivArrow>
        )}
      </Container>
    </Modal>
  );
}
