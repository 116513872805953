import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { videoCall } from "../../redux/actions/call.actions";

import {
  Container,
  Main,
  Titles,
  Title,
  SubTitle,
  DivLogo,
  Logo,
  Infos,
  Texts,
  Buttons,
  Button,
  ButtonBack,
  LabelBack,
  IconBack,
} from "./styles";

import { push } from "../../services/navigate";
import { show } from "../../services/alert";

export default function Covid(props) {
  const dispatch = useDispatch();

  const patient = useSelector((state) => state.patientReducer.patient);
  const disease = useSelector((state) => state.utilsReducer.currentDisease);

  function goToUploads() {
    push("/arquivos");
  }

  function goToHome() {
    push("/home");
  }

  function goToScreening() {
    dispatch(videoCall({ ...patient, titleDisease: disease.titleDisease }));
  }

  return (
    <Container>
      <DivLogo onClick={() => goToHome()}>
        <Logo src="img/logo-sidebar.png" />
      </DivLogo>

      <Main>
        <ButtonBack type="button" onClick={goToHome}>
          <IconBack src="img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        <Titles>
          <Title>Sua consulta está prestes a começar</Title>
          <SubTitle>Dicas para uma melhor experiência:</SubTitle>
        </Titles>

        <Infos>
          <Texts>
            <img src="img/sem-som.png" />
            <p>
              Procure um local silencioso, privativo e com boa iluminação. Se
              possível, use fones de ouvido.
            </p>
          </Texts>
          <Texts>
            <img src="img/wifi.png" />
            <p>
              Prefira conexão Wi-Fi e tente ficar próximo ao roteador de
              internet. 3G/4G também funcionam, mas verifique se está com um bom
              sinal da antena. Evite se deslocar durante a consulta.
            </p>
          </Texts>
          <Texts>
            <img src="img/carteirinha.png" />
            <p>
              Antes de começar, tenha em mãos um documento com foto para sua
              correta identificação. Ele será solicitado logo no primeiro
              momento.
            </p>
          </Texts>
          <Texts>
            Você deseja fazer upload de alguma foto ou documento antes da
            consulta?
          </Texts>

          <Buttons>
            <Button outline onClick={() => goToUploads()}>
              Fazer upload
            </Button>

            <Button contained width="250px" onClick={() => goToScreening()}>
              Ir para a consulta
            </Button>
          </Buttons>
        </Infos>
      </Main>
    </Container>
  );
}
