import styled from "styled-components"

import { motion } from "framer-motion"
import animations from "../../../utils/animation"

export const Container = styled.div`
  position: relative;
  background-image: url("img/back.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-width: 1000px;

  @media (max-width: 1024px) {
    min-width: 100%;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
  }
`

export const Layer = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    bottom: 0;

    background: linear-gradient(
      to top,
      rgba(0, 0, 0) 0%,
      rgba(0, 0, 0) 20%,
      rgba(0, 0, 0, 0.7) 40%,
      rgba(0, 0, 0, 0.3) 60%,
      rgba(0, 0, 0, 0.2) 80%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`

export const User = styled.div`
  position: absolute;
  width: 190px;
  height: 55px;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  @media (max-width: 768px) {
    display: none;
  }
`

export const Message = styled.p`
  color: #ddd;
  text-align: right;
  font-size: 14px;
`

export const ImageUser = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 36px;
`

export const Options = styled.div`
  position: absolute;
  width: 171px;
  height: 150px;
  top: 80px;
  right: 25px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    display: none;
  }
`

export const OptionItem = styled.button`
  cursor: pointer;
  width: 75%;
  color: #d1d1d1;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 8px;
  border-bottom: 0.5px solid #808080;
  margin: 10px 0;
`

export const Main = styled.div`
  position: absolute;
  bottom: 0;
  height: 250px;
  width: 100%;
  min-width: 958px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    justify-content: flex-end;
    bottom: 20px;
    width: 80%;
    min-width: 80%;
    height: 85%;
  }
`

export const Alert = styled.div`
  width: 850px;
  height: 55px;
  padding: 0 20px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: ${props => props.marginTop || '0'}px;

  button {
    color: #ededed;
    width: 137px;
    height: 31px;
    background: #2196f3;
    border-radius: 4px;
  }

  @media (max-width: 1024px) {
    position: absolute;
    top: 40px;
    width: 100%;
    min-height: 60px;

    button {
      font-size: 10px;
    }
  }
`

export const AlertMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  p {
    margin-left: 10px;
    color: #ededed;
    font-size: 13px;
  }

  @media (max-width: 1024px) {
    p {
      font-size: 11px;
    }
  }
`

export const RowMain = styled.div`
  width: 850px;
  border-radius: 4px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`

export const InitCall = styled.div`
  border-radius: 3px;
  width: 850px;
  height: 121px;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 15px;

  @media (max-width: 1024px) {
    width: 100%;
    height: 110px;
    background: transparent;
  }
`

export const ButtonInit = styled.button`
  cursor: pointer;
  /* cursor: ${(props) => !props.elegibility && "not-allowed"}; */
  width: 100%;
  height: 72px;
  background: #e0832c;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const ScheduleInit = styled.button`
  cursor: ${(props) => !props.elegibility && "not-allowed"};
  width: 188px;
  height: 72px;
  background: #2196f3;
  color: #fff;
  font-weight: bold;
  display: ${(props) => !props.flagScheduling && "none" || props.notElegibilityInternal && "none" || "flex"};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin-left: 5px;

  @media (max-width: 1024px) {
    width: 30%;
  }
`
