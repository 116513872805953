import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 0;
`;

export const Main = styled.div`
  width: 900px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  margin: 0 0 20px;

  @media (max-width: 1024px) {
    margin: 50px 10px 20px;
  }
`;

export const LabelBack = styled.p`
  margin-left: 10px;
  color: #707070;
`;

export const IconBack = styled.img`
  width: 20px;
  height: 20px;
`;

export const Titles = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 18px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const DiasesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 50px;
  
  @media (max-width: 1024px) {
    align-items: center;
    justify-content: center;
  }
`;

export const DiasesItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 108px;
  background: ${(props) => (props.another ? "#fff" : "#dedede")};
  box-shadow: ${(props) =>
    props.another ? "0px 0px 9px 1px rgba(219,219,219,1)" : ""};
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    opacity: 0.5;
  }
`;

export const DivImageDiases = styled.img`
  width: 62px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageDiases = styled.img`
  width: 20px;
  height: 20px;
`;

export const DiasesLabel = styled.p`
  font-size: 11px;
  line-height: 1.5;
  color: #707070;
  max-width: 90%;
  height: 30px;
  margin: 10px 0 0;
`;

export const AuxItem = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   margin: 5px;
   width: 140px;
   height: 150px;
`;