import React, { useState, useEffect } from "react";
import {
  Main,
  Titles,
  Title,
  SubTitle,
  Infos,
  Info,
  LabelInfo,
  DivImageInfo,
  Form,
  DivTextArea,
  LabelTextArea,
  TextArea,
  ErrorMessage,
  DivButton,
  Button,
  Container,
} from "./styles";
import { FiMail, FiPhoneCall } from "react-icons/fi";

import useYup from "@usereact/use-yup";
import validationSchema from "./validate";
import Input from "../../../components/input-custom";

import { sendEmail } from "../../../redux/actions/utils.actions";
import { useDispatch, useSelector } from "react-redux";

export default function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);

  const [maskPhone, setMaskPhone] = useState("");
  const [tryPhone, setTryPhone] = useState(false);

  const [touched, setTouched] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
    document: "",
  });

  let { errors } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  useEffect(() => {
    if (user && user.email) {
      setValues({
        ...user,
        email: user.email,
        name: user.name,
        document: user.document,
        phone: user.phone
      });
    }
  }, [user]);

  function isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setTouched({
      name: true,
      email: true,
      message: true,
      document: true,
      phone: true
    });
    
    if (isEmpty(errors)) {
      dispatch(
        sendEmail({
          name: values.name,
          subject: "Suporte",
          email: values.email,
          document: values.document,
          phone: values.phone,
          text: values.text,
        })
      );
    }
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      [name]: true,
    });
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function clearMask(e){
    setMaskPhone("")
  }

  function setMask(e){
    let phone = e.currentTarget.value
    phone = phone.replace(/_/g, '').replace('(', '').replace(')', '').replace(/-/g, '').replace(/ /g, '').replace(/[a-z]+/g, '').replace(/[A-Z]+/g, '');
    setMaskPhone("")

    if(phone.length >= 11){
      setMaskPhone("(99) 99999-9999")
    }else{
      setMaskPhone("(99) 9999-9999")
    }
    if(phone.length <= 9) setMaskPhone("")
  }

  function handlePhone(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });

    let phone = value.replace(/_/g, '').replace('(', '').replace(')', '').replace(/-/g, '').replace(/ /g, '').replace(/[a-z]+/g, '').replace(/[A-Z]+/g, '');

    setValues((prevState) => ({
      ...prevState,
      [name]: phone,
    }));
  }

  return (
    <Container>
      <Main>
        <Titles>
          <Title>Entre em contato</Title>
          <SubTitle>
            Estamos aqui para falar com você, sempre que precisar. Ficou com
            alguma dúvida? Então entre em contato conosco:
          </SubTitle>
        </Titles>

        <Infos>
          <Info>
            <DivImageInfo>
              <FiMail color="#fff" />
            </DivImageInfo>
            <LabelInfo href="mailto:einsteinconecta@einstein.br">einsteinconecta@einstein.br</LabelInfo>
          </Info>
          <Info>
            <DivImageInfo>
              <FiPhoneCall color="#fff" />
            </DivImageInfo>
            <LabelInfo href="tel:1121517690">(11) 2151-7690</LabelInfo>
          </Info>
        </Infos>

        <Form onSubmit={handleSubmit}>
          <Input
            width="450px"
            onChange={handleChange}
            name="name"
            placeholder="Nome"
            type="text"
            value={values.name}
            errorMessage={touched["name"] && errors && errors.name}
          />

          <Input
            width="450px"
            onChange={handlePhone}
            maxLength={11}
            onClick={clearMask}
            onBlur={setMask}
            onSelect={clearMask}
            name="phone"
            placeholder="DDD + Telefone"
            type="text"
            mask={maskPhone}
            value={values.phone}
            errorMessage={touched['phone'] && errors && errors.phone}
          />

          <Input
            width="450px"
            onChange={handleChange}
            name="email"
            placeholder="E-mail"
            type="text"
            value={values.email}
            errorMessage={touched["email"] && errors && errors.email}
          />

          <Input
            width="450px"
            onChange={handleChange}
            name="document"
            placeholder="CPF"
            type="text"
            mask={"999.999.999-99"}
            value={values.document}
            errorMessage={touched["document"] && errors && errors.document}
          />

          <DivTextArea>
            <LabelTextArea>Mensagem</LabelTextArea>
            <TextArea
              onChange={handleChange}
              name="text"
              type="text"
              value={values.text}
            />
            <ErrorMessage>
              {touched["text"] && errors && errors.text}
            </ErrorMessage>
          </DivTextArea>

          <DivButton>
            <Button type="submit">Enviar</Button>
          </DivButton>
        </Form>
      </Main>
    </Container>
  );
}
