import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import PublicRoute from "./public.routes";
import PrivateRoute from "./private.routes";
import BuyRoute from "./buy.routes";
import PrivatePlanRoute from "./private-plan.routes";

// auth
import Login from "../auth/login";
import Register from "../auth/register";
import SendLinkRecovery from "../auth/send-link";
import RecoveryPassword from "../auth/recovery-password";

// pages
import BuyB2C from "../pages/buy-b2c";
import Home from "../pages/home";
import ListDependent from "../pages/dependent/list-dependent";
import Landing from "../pages/landing/";
import Contact from "../pages/contact";
import ChangeCreditCard from "../pages/change-credit-card";
import CheckTerms from "../pages/check-terms";
import Screening from "../pages/screening";
import Schedule from "../pages/schedule";
import Scheduled from "../pages/appointments";
import History from "../pages/history";
import Cancel from "../pages/cancel-plan";
import Descount from "../pages/discount";
import UploadFiles from "../pages/upload-files";
import UploadDocuments from "../pages/upload-documents";
import Guidelines from "../pages/guidelines";
import SelectProvider from "../pages/select-provider";

import { setNavigator } from "../services/navigate";

const LinkDescount = () => (
  <a href="http://localhost:36108/payment/samsung">SAMSUNG DESCONTO</a>
);

export default function App() {
  return (
    <BrowserRouter ref={(nav) => setNavigator(nav)}>
      <Switch>
        <BuyRoute path="/comprar" component={BuyB2C} />
        <BuyRoute path="/comprar/:plan" component={BuyB2C} />

        <PublicRoute path="/link" exact component={LinkDescount} />

        <Route path="/cupom-samsung" exact component={Descount} />

        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/login/:payment" component={Login} />
        <PublicRoute path="/cadastro" component={Register} />
        <PublicRoute path="/cadastro/:plan" component={Register} />
        <PublicRoute path="/enviar-link" exact component={SendLinkRecovery} />
        <PublicRoute
          path="/recuperar-senha"
          exact
          component={RecoveryPassword}
        />

        <PrivateRoute path="/home" exact component={Home} />
        <PrivateRoute path="/dependentes" exact component={ListDependent} />
        <PrivateRoute path="/fale-conosco" exact component={Contact} />
        <PrivateRoute path="/termos" exact component={CheckTerms} />
        <PrivateRoute path="/triagem" exact component={Screening} />
        <PrivateRoute path="/historico" exact component={History} />
        <PrivateRoute path="/agendamento" exact component={Schedule} />
        <PrivateRoute path="/consultas-agendadas" exact component={Scheduled} />
        <PrivateRoute path="/arquivos" exact component={UploadFiles} />
        <PrivateRoute path="/orientacoes" exact component={Guidelines} />
        <PrivateRoute path="/provedores" exact component={SelectProvider} />
        <PrivateRoute path="/documentos" exact component={UploadDocuments} />
        <PrivateRoute path="/cancelar" exact component={Cancel} />

        <PrivatePlanRoute path="/trocar-cartao" exact component={ChangeCreditCard} />

        <Route component={Landing} />
      </Switch>
    </BrowserRouter>
  );
}
