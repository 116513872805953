import axios from "axios";
import getDeviceInfo from './deviceReport'

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

const api = axios.create({
  baseURL: URL_CONECTA,
});

api.interceptors.request.use((config) => {
  try {
    let token = localStorage.getItem("@access_token");
    let buyToken = localStorage.getItem("@buy_token");

    if (token) {
      config.headers["x-access-token"] = `Bearer ${token}`;
    }

    if (buyToken) {
      config.headers["x-buy-token"] = `Bearer ${buyToken}`;
    }
    

    //Relatorio de acesso
    const deviceData = getDeviceInfo()
    if(deviceData){
      config.headers['Device-Data'] = JSON.stringify(deviceData)
    }

  } catch (err) {
    localStorage.removeItem("@access_token");
  } finally {
    return config;
  }
});

export default api;
