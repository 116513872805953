import { array } from "yup";
import {
  SCHEDULE_SUCESS,
  SPECIALTY_SUCESS,
  DOCTOR_SUCESS,
  CONFIRM_SCHEDULING_SUCESS,
  APPOINTMENTS_BY_PATIENT,
  CANCEL_SCHEDULING_SUCESS,
  SCHEDULE_CLEAR,
  CLEAR_SLOT_BY_PATIENT,
  SCHEDULED_SUCCESS,
  PRODUCTS_SUCCESS,
  SUCCESS_SCHEDULED_CLEAR,
  APPOINTMENT_OLD,
} from "../actions/action-types";

const initialState = {
  scheduleResult: [],
  specialty: [],
  doctors: [],
  slotStateId: null,
  scheduled: [],
  successScheduled: false,
  product: null,
  appointmentOld: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_SUCESS:
      return {
        ...state,
        scheduleResult: action.payload,
      };
    case SCHEDULE_CLEAR:
      return {
        ...state,
        scheduleResult: [],
      };
    case APPOINTMENTS_BY_PATIENT:
      return {
        ...state,
        scheduled: action.payload,
      };
    case CLEAR_SLOT_BY_PATIENT:
      return {
        ...state,
        scheduled: [],
      };
    case SPECIALTY_SUCESS:
      return {
        ...state,
        specialty: action.payload,
      };
    case DOCTOR_SUCESS:
      return {
        ...state,
        doctors: action.payload,
      };
    case CONFIRM_SCHEDULING_SUCESS:
      return {
        ...state,
        slotStateId: action.payload,
      };
    case CANCEL_SCHEDULING_SUCESS:
      return {
        ...state,
        slotStateId: action.payload,
      };
    case SCHEDULED_SUCCESS:
      return {
        ...state,
        successScheduled: action.payload,
      };
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        product: action.payload,
      };
    case SUCCESS_SCHEDULED_CLEAR:
      return {
        ...state,
        successScheduled: null,
      };
    case APPOINTMENT_OLD:
      return {
        ...state,
        appointmentOld: action.payload,
      };
    default:
      return state;
  }
};
