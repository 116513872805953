import styled from "styled-components";

import { motion } from "framer-motion";
import animations from "../utils/animation";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;

export const Main = styled(motion.div).attrs(animations)`
  width: 88%;
  min-height: 600px;
  background: #f1f1f1;
  margin-left: 210px;

  @media (max-width: 1024px) {
    min-height: 100vh;
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: 1744px) {
    width: 89vw;
  }
`;
