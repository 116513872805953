import React, { useEffect } from "react"
import {
  Container,
  Texts,
  Advantages,
  ListAdvantages,
  ImageAdvantage,
  Circle,
} from "./styles"

export default function App() {
  return (
    <Container>
      <Texts>
        <h1>Em casa, sim. Com orientação médica, sempre.</h1>
        <p>
          O Einstein Conecta é o serviço que coloca você em contato direto com os médicos com a mesma qualidade Einstein por meio do celular ou computador, a qualquer
          hora do dia e de onde você estiver, basta ter conexão com a internet.
          Você poderá resolver casos com sintomas não urgentes, como:
        </p>
      </Texts>

      <Advantages>
        <ListAdvantages>
          <li>
            <Circle /> Diarreia
          </li>
          <li>
            <Circle /> Dor de cabeça
          </li>
          <li>
            <Circle /> Dor de garganta
          </li>
          <li>
            <Circle /> Dor muscular
          </li>
          <li>
            <Circle /> Infecção urinária
          </li>
          <li>
            <Circle /> Febre
          </li>
          <li>
            <Circle /> Sinusite
          </li>
          <li>
            <Circle /> Resfriado
          </li>
        </ListAdvantages>

        <ImageAdvantage src="img/advantage.png" />
      </Advantages>
    </Container>
  )
}
