import styled, { css } from "styled-components";

import { motion } from "framer-motion";
import animations from "../../../utils/animation";

export const AlertImage = styled.img`
  margin: 0 0 20px;
`;

export const Container = styled(motion.div).attrs(animations)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  overflow: auto;
`;

export const DivLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 200px;
`;

export const Main = styled.div`
  min-width: 700px;
  width: 45%;
  height: 85%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
  }
`;

export const Titles = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 18px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const Infos = styled.div`
  box-shadow: 0px 0px 9px 0px rgba(150, 150, 150, 0.4);
  width: 628px;
  height: 450px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 0 0;
  line-height: 1.5;

  @media (max-width: 1024px) {
    width: 80%;
    height: 490px;
  }
`;

export const Texts = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    color: #909090;
    width: 500px;
    margin: 20px 0;
    text-align: center;
  }

  @media (max-width: 1024px) {
    p {
      width: 80%;
    }
  }
`;

export const Buttons = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0 0;
`;

export const Button = styled.button`
  width: 160px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.contained && contained}
  ${(props) => props.outline && outline}
`;

const contained = css`
  background: #2196f3;
  color: #fff;
`;

const outline = css`
  border: 1px solid #2196f3;
  color: #2196f3;
`;
