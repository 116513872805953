import React, { useState, useEffect } from 'react';
import {
  Container,
  Main,
  InformationPlan,
  Plan,
  Titles,
  Title,
  SubTitle,
  TitlePlan,
  SubTitlePlan,
  OldPrice,
  NewPrice,
  ListAdvantages,
  Advantage,
  IconAdvantage,
  LabelAdvantage,
  ButtonPlan,
  Price,
  DetailsPrice,
  DetailsPlan,
  TitleDetails,
  SubTitlesDetails,
  ChoiceDependentes,
  CountDependents,
  AddDependentCount,
  PricePlan,
  LabelPrice,
  ValuePrice,
  DivButtonPayment,
  ButtonPayment,
  LabelLoading,
  DivChangePlan,
  ChangePlan,
  IconDiv,
  InfoChangePlan,
  Descount,
  TitleFinanceOfficer,
  SelectFinanceOfficer,
  LabelDiscount,
  FinanceOfficerError,
  LabelDisclaimer,
} from './styles';

import { useSelector, useDispatch } from 'react-redux';
import { differenceInYears, parseISO } from 'date-fns';
import {
  getPlansByCategory,
  registerPayment,
  validateVoucher,
  setVoucher,
} from '../../../redux/actions/buy.actions';

import { FiChevronDown } from 'react-icons/fi';
import { show } from '../../../services/alert';
import { setActiveStep } from '../../../redux/actions/utils.actions';
import Modal from './modal-finance';
import ShowsCreditCardsInline from '../../../components/credit-card-inline';

export default function App(props) {
  const dispatch = useDispatch();
  const isDescount = localStorage.getItem('discount');

  const currentPlan = useSelector((state) => state.buyReducer.currentPlan);
  const plansCategory = useSelector((state) => state.buyReducer.plansCategory);
  const user = useSelector((state) => state.userReducer.user);
  const userData = useSelector((state) => state.buyReducer.dataUser);
  const voucherValues = useSelector((state) => state.buyReducer.voucherValues);

  const [infoPlan, setInfoPlan] = useState({});
  const [voucherInfo, setVoucherInfo] = useState({});
  const [voucherDiscount, setVoucherDiscount] = useState(false);
  const [divChangePlan, setDivChangePlan] = useState(false);

  const [discount, setDiscount] = useState(null);
  const [discountValid, setDiscountValid] = useState(false);

  const changePlan = JSON.parse(localStorage.getItem('@change_plan'));

  const [responsible, setResponsible] = useState(null);
  const [responsibleError, setResponsibleError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const _isMenor = () => {
    return differenceInYears(new Date(), parseISO(user.birthDate)) < 16;
  };
  const _hasFinanceOfficerRegistered = () => {
    return user.financeResponsible ? true : false;
  };
  function setFinancialOfficer(value) {
    setResponsible(value);
    setResponsibleError(false);
  }

  useEffect(() => {
    if (isDescount) {
      setDiscount('SAMSUNG');
      setDiscountValid(true);
    }
  }, [isDescount]);

  useEffect(() => {
    // console.log(voucherInfo);
    setVoucherInfo(voucherValues);
  }, [voucherValues]);

  useEffect(() => {
    if (_isMenor()) {
      setResponsible(true);
    }

    if (_hasFinanceOfficerRegistered()) {
      setResponsible(user.financeResponsible.hasFinanceResponsible);
    }

    if (changePlan || !divChangePlan) {
      setDivChangePlan(changePlan);
    }

    if (currentPlan) {
      dispatch(getPlansByCategory(currentPlan.category || 2));
    }
  }, []);

  useEffect(() => {
    // this is when user want change plan
    if (
      changePlan &&
      !changePlan.TypeProduct.toLowerCase().includes('avulso') &&
      plansCategory &&
      plansCategory.length > 0
    ) {
      let countArray = parseInt(currentPlan.dependentsCount);

      if (countArray === 9) {
        countArray = 8;
      }

      return setInfoPlan({
        id: plansCategory[countArray].Id,
        dependents: plansCategory[countArray].DependentsCount,
        totalValue: plansCategory[countArray].Amount + ',00',
        description: plansCategory[countArray].Description,
      });
    }

    // this is when user want buy new plan
    if (plansCategory && plansCategory.length > 0) {
      return setInfoPlan({
        id: plansCategory[0].Id,
        dependents: plansCategory[0].DependentsCount,
        totalValue: plansCategory[0].Amount + ',00',
        description: plansCategory[0].Description,
      });
    }
  }, [plansCategory]);

  function decreaseDependent() {
    dispatch(setVoucher({}));

    if (!changePlan || changePlan.TypeProduct.toLowerCase().includes('avulso')) {
      let plan = plansCategory.filter(
        (plan) => plan.DependentsCount === infoPlan.dependents - 1
      )[0];

      if (plan) {
        return setInfoPlan({
          id: plan.Id,
          dependents: plan.DependentsCount,
          totalValue: plan.Amount + ',00',
          description: plan.Description,
        });
      }
    }
    if (
      changePlan &&
      !changePlan.TypeProduct.toLowerCase().includes('avulso') &&
      parseInt(infoPlan.dependents) > parseInt(changePlan.dependentsCount) + 1
    ) {
      let plan = plansCategory.filter(
        (plan) => plan.DependentsCount === infoPlan.dependents - 1
      )[0];

      if (plan) {
        return setInfoPlan({
          id: plan.Id,
          dependents: plan.DependentsCount,
          totalValue: plan.Amount + ',00',
          description: plan.Description,
        });
      }
    }
    return show('Selecione um contrato com número maior de dependentes');
  }

  function addDependent() {
    let plan = plansCategory.filter((plan) => plan.DependentsCount === infoPlan.dependents + 1)[0];

    if (plan) {
      setInfoPlan({
        id: plan.Id,
        dependents: plan.DependentsCount,
        totalValue: plan.Amount + ',00',
        description: plan.Description,
      });

      dispatch(setVoucher({}));
    }
  }

  function confirmPayment(financeOfficer) {
    if (infoPlan.id) {
      if (
        !userData ||
        !userData.address ||
        userData.address == null ||
        userData.address == '' ||
        !userData.cep ||
        userData.cep == null ||
        userData.cep == '' ||
        !userData.cityAddress ||
        userData.cityAddress == null ||
        userData.cityAddress == '' ||
        !userData.stateAddress ||
        userData.stateAddress == null ||
        userData.stateAddress == ''
      ) {
        dispatch(setActiveStep(1));
        return show('Preencha seu endereço corretamente');
      }

      setShowModal(false);

      if (responsible == null) {
        return setResponsibleError(true);
      }

      if (responsible && !_hasFinanceOfficerRegistered() && !financeOfficer.email) {
        return setShowModal(true);
      }

      const payload = {
        user: {
          _id: user._id,
          documentNumber: user.document,
        },
        cep: userData.cep,
        address: userData,
        hasFinanceOfficer: responsible,
        financeOfficer: financeOfficer,
        dependentsCount: infoPlan.dependents || 0,
        idProduct: infoPlan.id,
        voucher: discount,
        voucherValues,
      };

      localStorage.removeItem('discount');

      if (discountValid && !currentPlan.detached) {
        return dispatch(
          registerPayment({
            ...payload,
            discount: true,
          })
        );
      }

      dispatch(registerPayment(payload));
    }
  }

  function handleDescount(event) {
    const { value } = event.target;
    setDiscount(value);
  }

  async function applyDiscount() {
    if (discount && discount.toString().toUpperCase() === 'SAMSUNG') {
      setVoucherInfo({});
      setVoucherDiscount(false);
      return setDiscountValid(true);
    } else {
      setVoucherDiscount(true);
      const payload = {
        amount: parseInt(infoPlan.totalValue),
        voucher: discount && discount.toString().toUpperCase(),
        user: {
          _id: user._id,
          documentNumber: user.document,
        },
      };

      dispatch(validateVoucher(payload));
      return setDiscountValid(false);
    }
  }

  return (
    <Container>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={(obj) => confirmPayment(obj)}
      />
      <Main>
        <Titles>
          <Title>Confirme seu pedido</Title>
          <SubTitle>Confira seus benefícios e confirme seu contrato.</SubTitle>
        </Titles>

        <InformationPlan>
          {divChangePlan && (
            <ChangePlan>
              <DivChangePlan outline>
                <h3 outline>contrato Atual</h3>

                <InfoChangePlan>
                  <h4>{divChangePlan.DescriptionProduct}</h4>
                  <p>
                    R$ {divChangePlan.Amount}
                    {divChangePlan.TypeProduct.toLowerCase().includes('avulso')
                      ? ',00'
                      : ',00 /mês '}
                  </p>
                  {!divChangePlan.TypeProduct.toLowerCase().includes('avulso') && (
                    <p>Número de dependentes: {divChangePlan.dependentsCount}</p>
                  )}
                </InfoChangePlan>
              </DivChangePlan>
              <IconDiv>
                <FiChevronDown size="40px" />
              </IconDiv>
              <DivChangePlan contained>
                <h3>Novo contrato</h3>

                <InfoChangePlan>
                  <h4>{infoPlan.description || currentPlan.description}</h4>
                  <p>R$ {infoPlan.totalValue} /mês</p>
                  <p>Número de dependentes: {infoPlan.dependents}</p>
                </InfoChangePlan>
              </DivChangePlan>
            </ChangePlan>
          )}

          {!divChangePlan && (
            <Plan>
              <TitlePlan>{currentPlan.namePlan}</TitlePlan>
              <SubTitlePlan>{currentPlan.typePlan}</SubTitlePlan>

              {currentPlan.oldPrice && (
                <OldPrice>
                  de <b>R$ {currentPlan.oldPrice}</b>
                </OldPrice>
              )}

              <NewPrice>
                <Price>R$ {currentPlan.Amount}</Price>
                <DetailsPrice>
                  <p>,00</p>
                  <span>{currentPlan.labelTypePlan}</span>
                </DetailsPrice>
              </NewPrice>

              <ListAdvantages>
                {(currentPlan.advantages || []).map((advantage) => (
                  <Advantage>
                    <IconAdvantage src="/img/tick.png" />
                    <LabelAdvantage>{advantage}</LabelAdvantage>
                  </Advantage>
                ))}
              </ListAdvantages>
            </Plan>
          )}

          {!plansCategory && <LabelLoading>Carregando...</LabelLoading>}

          {plansCategory && plansCategory.length > 0 && (
            <DetailsPlan>
              <TitleDetails>Contrato escolhido</TitleDetails>

              <SubTitlesDetails>
                <h4>Adquira o Einstein Conecta</h4>
                <p>{infoPlan.description || currentPlan.description}</p>
              </SubTitlesDetails>

              {!currentPlan.single && (
                <ChoiceDependentes>
                  <p>Selecione a quantidade de dependentes</p>

                  <CountDependents>
                    <AddDependentCount onClick={() => decreaseDependent()}>-</AddDependentCount>
                    <p>{infoPlan.dependents}</p>
                    <AddDependentCount onClick={() => addDependent()}>+</AddDependentCount>
                  </CountDependents>
                </ChoiceDependentes>
              )}

              {!currentPlan.detached && (
                <Descount>
                  <label>Código promocional</label>
                  <input
                    placeholder="Insira o código promocional"
                    type="text"
                    value={discount}
                    onChange={handleDescount}
                  />
                  <button onClick={applyDiscount}>Aplicar desconto</button>
                </Descount>
              )}

              {!currentPlan.detached && discountValid && (
                <LabelDiscount>Você ganhou um mês gratuito</LabelDiscount>
              )}

              {!currentPlan.detached && discountValid && (
                <LabelDiscount size="10px" color="#b34f4f">
                  O valor do primeiro mês será estornado logo após a compra. A partir do segundo
                  mês, será cobrado o valor integralmente.
                </LabelDiscount>
              )}

              {voucherInfo &&
                voucherInfo.discountInReal &&
                voucherDiscount && ( //aqui
                  <LabelDiscount>Você ganhou um desconto {console.log(voucherInfo)}</LabelDiscount>
                )}

              {!currentPlan.detached &&
                voucherInfo &&
                voucherInfo.discountInReal &&
                voucherDiscount && (
                  <LabelDiscount size="10px" color="#b34f4f">
                    O valor de desconto com o voucher utilizado foi de : R$
                    {voucherInfo.discountInReal}
                  </LabelDiscount>
                )}

              <div>
                <TitleFinanceOfficer>
                  O titular do plano é o responsável pelo pagamento?
                </TitleFinanceOfficer>
                <SelectFinanceOfficer>
                  <div
                    onClick={() =>
                      _hasFinanceOfficerRegistered() || _isMenor() ? '' : setFinancialOfficer(false)
                    }
                  >
                    <input
                      width="315px"
                      name="responsible"
                      value={true}
                      disabled={_isMenor() || _hasFinanceOfficerRegistered()}
                      checked={responsible == false}
                      onClick={() => setFinancialOfficer(false)}
                      type="radio"
                    />
                    {' Sim'}
                  </div>

                  <div
                    onClick={() =>
                      _hasFinanceOfficerRegistered() || _isMenor() ? '' : setFinancialOfficer(true)
                    }
                  >
                    <input
                      width="315px"
                      style={{ marginLeft: 40, borderColor: 'green' }}
                      name="responsible"
                      value={true}
                      disabled={_isMenor() || _hasFinanceOfficerRegistered()}
                      checked={responsible == true}
                      onClick={() => setFinancialOfficer(true)}
                      type="radio"
                    />
                    {' Não'}
                  </div>
                </SelectFinanceOfficer>
                {responsibleError && (
                  <FinanceOfficerError>*resposta obrigatória</FinanceOfficerError>
                )}
              </div>
              <PricePlan>
                <LabelPrice>Total</LabelPrice>
                <ValuePrice>
                  {!currentPlan.detached && <span>12x </span>}
                  R$
                  {/* Se for currentPlan.newPrice o contrato é individual */}
                  {voucherValues.amountWithDiscount ||
                    infoPlan.totalValue ||
                    `${currentPlan.newPrice},00`}
                </ValuePrice>
              </PricePlan>

              <DivButtonPayment onClick={confirmPayment}>
                <ButtonPayment>Comprar</ButtonPayment>
              </DivButtonPayment>

              {currentPlan.disclaimer && (
                <LabelDisclaimer>{currentPlan.disclaimer}</LabelDisclaimer>
              )}
            </DetailsPlan>
          )}
        </InformationPlan>
        <ShowsCreditCardsInline isBuyPage />
      </Main>
    </Container>
  );
}
