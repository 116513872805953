import React, { useState, useEffect } from "react";
import * as S from "./styles";

import { push } from "../../../services/navigate";
import plans from "./plans";
import { show } from "../../../services/alert";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPlan, getPlans } from "../../../redux/actions/buy.actions";

export default function App(props) {
  const dispatch = useDispatch();
  const plansAPI = useSelector((state) => state.buyReducer.plans);

  const [divChangePlan, setDivChangePlan] = useState(false);
  const changePlan = JSON.parse(localStorage.getItem("@change_plan"));

  useEffect(() => {
    if (changePlan || !divChangePlan) {
      setDivChangePlan(changePlan);
    }
    dispatch(getPlans());
  }, []);

  function setPlan(plan) {
    if (plan.single && changePlan && !changePlan.TypeProduct.toLowerCase().includes('avulso')) {
      return show("Impossivel alterar");
    }
    props.handleNext();
    if (plan) {
      localStorage.setItem("@current_plan", JSON.stringify(plan));
      dispatch(setCurrentPlan(plan));
      push(`/comprar/${plan.namePlan}`);
      // window.location.reload();
    }
  }

  return (
    <S.Container>
      <S.Main>
        <S.Titles>
          <S.Title>Selecione o seu contrato</S.Title>
          <S.SubTitle>Escolha o contrato de sua preferência.</S.SubTitle>
        </S.Titles>

        <S.Plans>
          {!plansAPI && <S.LabelLoading>Carregando...</S.LabelLoading>}
          <S.PlansDiv>
            {plansAPI &&
              plansAPI.length > 0 &&
              plansAPI.map((planAPI) => {
                let plan = plans[planAPI.category];
                if (plan) {
                  return (
                    <S.Plan key={plan.namePlan}>
                      <S.CardHeading>
                        <S.TitlePlan>{plan.namePlan}</S.TitlePlan>
                        <S.SubTitlePlan namePlan={plan.namePlan}>{plan.typePlan}</S.SubTitlePlan>
                        {plan.oldPrice
                          && (
                            <S.OldPrice>
                              de <b>R$ {plan.oldPrice},00</b>
                            </S.OldPrice>)
                        }

                        <S.NewPrice>
                          <S.Price>R$ {planAPI.Amount}</S.Price>
                          <S.DetailsPrice>
                            <p>,00</p>
                            <span>{plan.labelTypePlan}</span>
                          </S.DetailsPrice>
                        </S.NewPrice>
                      </S.CardHeading>
                      <S.ListAdvantagesPlan>
                        {plan.advantages.map((advantage) => (
                          <S.Advantage>
                            <S.IconAdvantage src="/img/tick.png" />
                            <S.LabelAdvantage>{advantage}</S.LabelAdvantage>
                          </S.Advantage>
                        ))}
                      </S.ListAdvantagesPlan>
                      <S.ButtonPlan
                        namePlan={plan.namePlan}
                        onClick={() => setPlan({ ...plan, ...planAPI })}
                      >
                        Contrate agora
                            </S.ButtonPlan>
                      { plan.disclaimer && <S.LabelDisclaimer>{plan.disclaimer}</S.LabelDisclaimer>}
                    </S.Plan>
                  );
                }
              })}
          </S.PlansDiv>
        </S.Plans>
      </S.Main>
    </S.Container>
  );
}
