import React, { useState, useEffect } from "react";
import "moment/locale/pt-br";
import "semantic-ui-react";
import moment from "moment";
import { Select } from "semantic-ui-react";
import { show } from "../../services/alert";
import { DateInput } from "semantic-ui-calendar-react";
import {
  slotByMonth,
  specialtiesByMonth,
  doctorsBySpecialtyId,
  checkPatient,
  clearSchedule,
  findProducts,
  clearSuccessScheduled,
  slotByPatient,
  cancelScheduling,
  appointmentOld,
} from "../../redux/actions/schedule.actions";

import {
  ScheduleHour,
  ScheduleDay,
  BodySchedule,
  AvailableHours,
  HourButton,
  DateInputDiv,
  LabelSchedule,
  SubLabelSchedule,
  CircleSchedule,
  GridContainer,
  DivLabels,
  ButtonAdvance,
  Header,
  Grid,
  ScheduleGrid,
  BodyScheduled,
  HeaderScheduled,
  TitleScheduled,
  Img,
  Icon,
  SubTitleScheduled,
  ButtonOk,
  ChoicePatient,
  PatientSelected,
  ImagePatientSelected,
  InfoPatientSelected,
  NamePatientSelected,
  NivelPatientSelected,
  DivScheduled,
  GridScheduled,
  DivSelect,
  SelectSpecialtyDoctor,
  LabelSelect,
  DateCalendarDisableClick,
  GridFilterSpecialty,
  GridFilterDoctor,
  DivFilters,
  TitleMessage,
} from "./styles";

import "./styles.css";

import { useSelector, useDispatch } from "react-redux";
import Container from "../../container";
import { push } from "../../services/navigate";
import api from "../../services/api";

import Patient from "./modal-patient";

import Confirm from "./modal-confirm";
import { setPatient, setSelectedPatient } from "../../redux/actions/patient.actions";
import { openModalPatient } from "../../redux/actions/user.actions";
import { verifyAuth } from "../../redux/actions/utils.actions";

function DateTimeFormInline() {
  // provider selected
  let provider = localStorage.getItem("@selected_provider");
  provider = JSON.parse(provider);

  const dispatch = useDispatch();

  const [date, setDate] = useState("");
  const [keyHourSelected, setKeyHourSelected] = useState("");

  const specialty = useSelector((state) => state.schedule.specialty);
  const doctorsProps = useSelector((state) => state.schedule.doctors);
  const schedule = useSelector((state) => state.schedule.scheduleResult);
  const product = useSelector((state) => state.schedule.product);
  const successScheduled = useSelector(
    (state) => state.schedule.successScheduled
  );
  const appointments = useSelector((state) => state.schedule.scheduled);
  const appointmentsOld = useSelector((state) => state.schedule.appointmentOld);
  const modalPatient = useSelector((state) => state.userReducer.modalPatient)

  const user = useSelector((state) => state.userReducer.user);
  const patient = useSelector((state) => state.patientReducer.patient);
  const patientSelected = useSelector((state) => state.patientReducer.patientSelected);
  if(!patientSelected){
    dispatch(setPatient(user))
  }
  const [modalConfirm, setModalConfirm] = useState(false);
  const [doctorId, setDoctor] = useState(false);
  const [specialtyId, setSpecialty] = useState(false);
  const [touched, setTouched] = useState(false);
  const [slot, setSelectedSlot] = useState(null);

  const [flagScheduling, setFlagScheduling] = useState(false);

  const scheduleInitial = {
    date: moment().format("YYYY-MM-DD").toString(),
    user: user,
  };

  function setModalPatient(bool) {
    dispatch(openModalPatient(bool))
  }

  async function getFlagScheduling() {
    return await api.get("/config-system").then((result) => {
      const flag = result.data.configSystem[0].scheduling;
      setFlagScheduling(flag);
      return flag;
    });
  }
  
  useEffect(() => {
    getFlagScheduling().then((flag) => {
      if (
        !flag ||
        (user &&
          !user.checkElegibility &&
          provider.validar_tipo_elegibilidade != 0) ||
        (user &&
          user.checkElegibility &&
          provider.validar_tipo_elegibilidade != 0)
      ) {
        return push("/home");
      }
      dispatch(clearSuccessScheduled());
      dispatch(findProducts());
      setModalPatient(true);
      dispatch(specialtiesByMonth());
      dispatch(clearSchedule());
      dispatch(slotByPatient(user));
      dispatch(slotByMonth(scheduleInitial));
    });
  }, []);

  function handleChange(event, { value: date }) {
    setDate(date);
    setTouched(true);
    date = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD").toString();
    const params = {
      date: date,
      doctorId: doctorId,
      specialtyId: specialtyId,
      user: user,
    };
    dispatch(slotByMonth(params));
  }

  function changeSpecialty(_, data) {
    setSpecialty(data.value);
    dispatch(doctorsBySpecialtyId(data.value));
    setDate("");
    dispatch(clearSchedule());
  }
  function changeDoctor(_, data) {
    setDoctor(data.value);
    const doctor = data.value;
  }

  function selectHour(value) {
    setKeyHourSelected(value);
  }

  function confirmScheduling(slot) {
    patient.empresas = [provider];

    let userSelected = patient;
    if (patient && patient.holderId) {
      userSelected.fullName = `${patient.firstName} ${patient.lastName}`;
      userSelected.idVida = patient.idVida;
    } else {
      userSelected.fullName = `${patient.name} ${patient.lastName}`;
    }

    const nameCurrent = userSelected.fullName;
    const documentCurrent = userSelected.document;
    const birthDateCurrent = userSelected.birthDate;

    const slotId = slot.id;
    const slotSpecialtyCurrent = slot.Specialty.id;

    if (appointments.length > 0) {
      let verifySlot = appointments.map((slotSelected) => {
        const nameScheduled = `${slotSelected.Patient.name} ${slotSelected.Patient.lastName}`;
        let birthDateScheduled = slotSelected.Patient.birthDate;
        const documentScheduled = slotSelected.Patient.document;

        birthDateScheduled = moment(birthDateScheduled).format("YYYY-MM-DD");

        const slotOld = slotSelected.id;
        const slotSpecialtyOld = slotSelected.Specialty.id;

        if (documentCurrent) {
          if (
            slotSpecialtyCurrent === slotSpecialtyOld &&
            documentCurrent === documentScheduled
          ) {
            dispatch(appointmentOld(slotSelected));
            return slotOld;
          } else {
            return false;
          }
        } else if (
          slotSpecialtyCurrent === slotSpecialtyOld &&
          nameScheduled === nameCurrent &&
          birthDateScheduled === birthDateCurrent
        ) {
          dispatch(appointmentOld(slotSelected));
          return slotOld;
        } else {
          return false;
        }
      });

      let verifier = verifySlot.find((element) => element != false);
      if (verifier) {
        setSelectedSlot(slotId);
        setModalConfirm(true);
      } else {
        dispatch(checkPatient({ patient, slot: { slotId: slotId } }));
      }
    } else {
      dispatch(checkPatient({ patient, slot: { slotId: slotId } }));
    }
  }

  const handleClose = (confirm) => {
     patient.empresas = [provider];
    if (confirm) {
      dispatch(checkPatient({ patient, slot: { slotId: slot } }));
      dispatch(cancelScheduling(appointmentsOld.id));
      setModalConfirm(false);
    } else {
      setModalConfirm(false);
    }
  };

  function goToScheduled() {
    dispatch(verifyAuth())
    push("/consultas-agendadas");
  }
  function goToHome() {
    setSelectedPatient(false);
    push("/home");
  }
  function arraySlotMapped(schedule, appointments) {
    let arrSched = [];
    let scheduled = appointments;
    var scheduleArray = schedule,
      seen = Object.create(null),
      result = scheduleArray.filter((o) => {
        var key = ["startAt"].map((k) => o[k]).join("|");
        if (!seen[key]) {
          seen[key] = true;
          return true;
        }
      });
    arrSched = result;

    if (scheduled && scheduled.length > 0) {
      arrSched = [];
      let patientSchedule = patient;

      if (patient.holderId) {
        patientSchedule.firstNameSchedule = patient.firstName;
      } else {
        patientSchedule.firstNameSchedule = patient.name;
      }

      for (let arr of result) {
        let exists = false;
        for (let schedule of scheduled) {
          if (
            arr.finishAt === schedule.finishAt &&
            patient.birthDate === schedule.Patient.birthDate.split(" ")[0] &&
            patientSchedule.firstNameSchedule === schedule.Patient.name
          ) {
            exists = true;
          }
        }
        if (!exists) arrSched.push(arr);
      }
    }
    return arrSched;
  }
 
  function getUserPicture() {
    if (patient.email && patient.image) return patient.image;

    if (patient.signUpDocuments && patient.signUpDocuments.image)
      return patient.signUpDocuments.image;

    return "img/default.png";
  }

  return (
    <Container>
      <BodySchedule>
        {successScheduled && (
          <HeaderScheduled>
            <Header>
              {/* <DivTitle>
              <TitleScheduled>Agendamento</TitleScheduled>
              <SubTitleSchedule>Agende um novo atendimento</SubTitleSchedule>
            </DivTitle> */}
              <BodyScheduled>
                <TitleScheduled>
                  Agendamento efetuado com sucesso !
                </TitleScheduled>
                <Img src="img/verification.png" />
                <SubTitleScheduled>
                  Em caso de dúvidas, entre em contato:
                </SubTitleScheduled>
                <DivScheduled>
                  <GridScheduled>
                    <Icon src="img/icon@.png" />
                    <DivLabels>
                      <LabelSchedule>Por e-mail</LabelSchedule>
                      <SubLabelSchedule>
                        einsteinconecta@einstein.br
                      </SubLabelSchedule>
                    </DivLabels>
                    <Icon src="img/phone.png" />
                    <DivLabels>
                      <LabelSchedule>Por telefone</LabelSchedule>
                      <SubLabelSchedule>(11) 2151-7690</SubLabelSchedule>
                    </DivLabels>
                  </GridScheduled>
                </DivScheduled>

                <ButtonOk
                  onClick={() => {
                    goToScheduled();
                  }}
                >
                  OK
                </ButtonOk>
              </BodyScheduled>
            </Header>
          </HeaderScheduled>
        )}

        {!successScheduled && (
          <Grid>
            <Header>
              {/* {<DivTitle>
                <TitleScheduled>Agendamento</TitleScheduled>
                <SubTitleSchedule>Agende um novo atendimento</SubTitleSchedule>
              </DivTitle>} */}

              <TitleScheduled>Paciente selecionado</TitleScheduled>

              <ChoicePatient>
                <PatientSelected>
                  <ImagePatientSelected
                    src={getUserPicture()}
                  />
                  <InfoPatientSelected>
                    <NamePatientSelected>{`${
                      patient.name || patient.firstName
                    } ${patient.lastName}`}</NamePatientSelected>
                    <NivelPatientSelected>
                      {patient.email ? "Titular" : "Dependente"}
                    </NivelPatientSelected>
                  </InfoPatientSelected>
                </PatientSelected>
              </ChoicePatient>

              {product && !product.suggestsSpecialty && (
                <DivFilters>
                  <GridFilterSpecialty>
                    <GridContainer>
                      <CircleSchedule>1</CircleSchedule>
                      <DivLabels>
                        <LabelSchedule>Selecione a especialidade</LabelSchedule>
                        <SubLabelSchedule>
                          Selecione a especialidade desejada:
                        </SubLabelSchedule>
                      </DivLabels>
                    </GridContainer>
                    <DivSelect>
                      <SelectSpecialtyDoctor>
                        <LabelSelect>
                          <SubLabelSchedule>Especialidade</SubLabelSchedule>
                        </LabelSelect>
                        <Select
                          placeholder="Selecione a especialidade"
                          options={specialty}
                          onChange={changeSpecialty}
                        ></Select>
                      </SelectSpecialtyDoctor>
                    </DivSelect>
                  </GridFilterSpecialty>

                  <GridFilterDoctor>
                    <GridContainer>
                      <CircleSchedule>2</CircleSchedule>
                      <DivLabels>
                        <LabelSchedule>Selecione o profissional</LabelSchedule>
                        <SubLabelSchedule>
                          Selecione o profissional desejado:
                        </SubLabelSchedule>
                      </DivLabels>
                    </GridContainer>
                    <DivSelect>
                      <SelectSpecialtyDoctor>
                        <LabelSelect>
                          <SubLabelSchedule>Profissional</SubLabelSchedule>
                        </LabelSelect>
                        <Select
                          placeholder="Selecione o profissional"
                          options={doctorsProps}
                          onChange={changeDoctor}
                        ></Select>
                      </SelectSpecialtyDoctor>
                    </DivSelect>
                  </GridFilterDoctor>
                </DivFilters>
              )}
            </Header>

            {!product ||
              (product && !product.suggestsDate && (
                <ScheduleGrid>
                  <ScheduleDay>
                    <GridContainer>
                      <CircleSchedule>3</CircleSchedule>
                      <DivLabels>
                        <LabelSchedule>Selecione a data</LabelSchedule>
                        <SubLabelSchedule>
                          Selecione o dia que gostaria de ser atendido
                        </SubLabelSchedule>
                      </DivLabels>
                    </GridContainer>

                    <DateInputDiv>
                      <DateCalendarDisableClick />
                      <DateInput
                        placeholder="Date"
                        popupPosition="bottom right"
                        className="example-calendar-input"
                        name="date"
                        closable
                        inline
                        animation="scale"
                        duration={200}
                        hideMobileKeyboard
                        value={date}
                        iconPosition="left"
                        minDate={moment()}
                        maxDate={moment().add(3, "M")}
                        marked={date}
                        markColor={"blue"}
                        preserveViewMode={false}
                        autoComplete="off"
                        onChange={handleChange}
                      />
                    </DateInputDiv>
                  </ScheduleDay>
                  <ScheduleHour>
                    <GridContainer>
                      <CircleSchedule>4</CircleSchedule>
                      <DivLabels>
                        <LabelSchedule>Selecione o horário</LabelSchedule>
                        <SubLabelSchedule>
                          Selecione o horário que gostaria de ser atendido
                        </SubLabelSchedule>
                      </DivLabels>
                    </GridContainer>

                    <AvailableHours>
                      {schedule &&
                        arraySlotMapped(schedule, appointments).map((slot) => (
                          <HourButton
                            active={slot.id === keyHourSelected}
                            onClick={() => {
                              selectHour(slot.id);
                            }}
                          >
                            {slot.startAt}
                          </HourButton>
                        ))}
                    </AvailableHours>
                  </ScheduleHour>
                </ScheduleGrid>
              ))}
            {product && product.suggestsDate && (
              <ScheduleGrid>
                <ScheduleHour>
                  <GridContainer>
                    <DivLabels>
                      <LabelSchedule>Selecione o horário</LabelSchedule>
                      <SubLabelSchedule>
                        O sistema permite agendamento apenas para o mesmo dia
                        das 08:00 às 23:30.
                      </SubLabelSchedule>
                    </DivLabels>
                  </GridContainer>

                  <AvailableHours>
                    {schedule &&
                      arraySlotMapped(schedule, appointments).map((slot) => (
                        <HourButton
                          active={slot.id === keyHourSelected}
                          onClick={() => {
                            selectHour(slot.id);
                          }}
                        >
                          {slot.startAt}
                        </HourButton>
                      ))}
                    {!schedule ||
                      (schedule.length === 0 && (
                        <TitleMessage>
                          Não há mais horários disponíveis para agendamento.
                          Retorne à tela anterior e clique em iniciar
                          atendimento. Você será atendido pelo primeiro médico
                          disponível.
                        </TitleMessage>
                      ))}
                  </AvailableHours>
                </ScheduleHour>
              </ScheduleGrid>
            )}

            {schedule && schedule.length > 0 && (
              <ButtonAdvance
                onClick={() => {
                  if (schedule) {
                    schedule.map((sche) => {
                      if (sche.id === keyHourSelected) {
                        confirmScheduling(sche);
                      }
                    });
                  } else show("Selecione a data/hora do seu atendimento");
                }}
              >
                Avançar
              </ButtonAdvance>
            )}

            {!schedule ||
              (schedule.length === 0 && (
                <ButtonAdvance onClick={() => goToHome()}>Voltar</ButtonAdvance>
              ))}
          </Grid>
        )}
      </BodySchedule>
      <Confirm
        isOpen={modalConfirm}
        handleClose={handleClose}
        onClose={() => setModalConfirm(false)}
      />
      <Patient isOpen={modalPatient} onClose={() => setModalPatient(false)} />
    </Container>
  );
}
export default DateTimeFormInline;
