import styled from "styled-components";

import { motion } from "framer-motion";
import animations from "../../utils/animation";

export const Container = styled(motion.div).attrs(animations)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const ImageForm = styled.div`
    background-image: url('${(props) => props.src}');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;

    @media (max-width: 1024px) {
      display: none
    }
`;

export const Label = styled.div`
  margin: 20px 0;
  width: 280px;
  line-height: 1.5;
  font-size: 14px;
  color: #707070;
  text-align: center;
`;

export const Form = styled.form`
  max-width: 465px;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 150px 0 0;
`;

export const DivLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 20px;
`;

export const Logo = styled.img`
  width: 220px;
`;

export const Inputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

export const DivInput = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CustomInput = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #000;
  margin: 15px 0;
  width: 335px;
  height: 33px;
`;

export const Icon = styled.img``;

export const Input = styled.input`
  background-color: transparent;
  margin-left: 5px;
  width: 85%;
  border: 0;
`;

export const LabelPassword = styled.div`
  width: 335px;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    color: #005a9c;
    font-size: 15px;
  }
`;

export const Button = styled.button`
  border: 0;
  border-radius: 200px;
  font-weight: bolder;
  color: #005a9c;
  margin: 0 auto;
  width: 305px;
  height: 37px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`;

export const Register = styled.p`
  cursor: pointer;
  color: #707070;
  margin: 80px auto 0;
  b {
    color: #005a9c;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  width: 100%;
  padding: 3px 0;
  font-size: 10px;
`;
