import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  DivLogo,
  Logo,
  Inputs,
  DivInput,
  CustomInput,
  Input,
  Label,
  Button,
  ImageForm,
  ErrorMessage,
} from "./styles";

import { useHistory } from "react-router-dom";
import { FiLock } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { recoveryPassword } from "../../redux/actions/user.actions";

import useYup from "@usereact/use-yup";
import validationSchema from "./validate";
import { show } from "../../services/alert";
import { push } from "../../services/navigate";

export default function App() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [touched, setTouched] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  let { errors } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  function handleSubmit(e) {
    e.preventDefault();
    setTouched(true);

    try {
      const email = localStorage.getItem("@email");
      const codeConfirm = window.location.href.split("=")[1];

      if (!errors.password && !errors.confirmPassword) {
        const data = {
          username: email,
          password: values.password,
          codeConfirm,
        };

        dispatch(recoveryPassword(data));
      }
    } catch (error) {
      show("Ocorreu um erro ao atualizar sua senha");
    }
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function goToHome() {
    push("/home");
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <DivLogo onClick={goToHome}>
          <Logo src="img/logo-sidebar.png" />
        </DivLogo>

        <Inputs>
          <Label>
            Insira o e-mail para enviarmos o link com a para redefinir sua senha
          </Label>
          <DivInput>
            <CustomInput>
              <FiLock color="#707070" />
              <Input
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Nova senha"
                type="password"
              />
            </CustomInput>
            <ErrorMessage>{touched && errors && errors.password}</ErrorMessage>
          </DivInput>

          <DivInput>
            <CustomInput>
              <FiLock color="#707070" />
              <Input
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                placeholder="Confirme sua nova senha"
                type="password"
              />
            </CustomInput>
            <ErrorMessage>
              {touched && errors && errors.confirmPassword}
            </ErrorMessage>
          </DivInput>
        </Inputs>

        <Button type="submit">Alterar senha</Button>
      </Form>

      <ImageForm src="img/home-kv.png" />
    </Container>
  );
}
