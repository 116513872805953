import React, { useState, useEffect } from "react";
import {
  Container,
  Layer,
  Main,
  Alert,
  AlertMessage,
  RowMain,
  InitCall,
  ButtonInit,
  ScheduleInit,
} from "./styles";

import api from "../../../services/api";

import { useSelector, useDispatch } from "react-redux";
import { FiAlertTriangle, FiCalendar } from "react-icons/fi";
import { push } from "../../../services/navigate";
import {
  openModalUser,
  openModalVerification,
  openModalCardNumber,
  openModalPin,
  openModalPatient,
} from "../../../redux/actions/user.actions";

import {
  setPatient,
  setSelectedPatient,
} from "../../../redux/actions/patient.actions";

import { setLabelWhite } from "../../../redux/actions/utils.actions";
import ReactTooltip from "react-tooltip";

import Profile from "../profile";
import Patient from "./modal-patient";
import VerificationCardNumber from "./verification/verification-card-number";
import VerificationTel from "./verification/verification-tel";
import VerificationPin from "./verification/verification-token";
import { verifyAuth } from "../../../redux/actions/utils.actions";
import { getAllDependents } from "../../../redux/actions/depedents.actions";

import { patientObjHasDocuments } from "../../../utils/documentHelpers"

export default function App(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const modalUser = useSelector((state) => state.userReducer.modalUser);
  const modalVerification = useSelector(
    (state) => state.userReducer.modalVerification
  );
  const modalCardNumber = useSelector(
    (state) => state.userReducer.modalCardNumber
  );
  const modalPin = useSelector((state) => state.userReducer.modalPin);
  const modalPatient = useSelector((state) => state.userReducer.modalPatient);
  const dependentsProps = useSelector(
    (state) => state.dependentsReducer.dependents
  );

  const [flagScheduling, setFlagScheduling] = useState(false);

  function getFlagScheduling() {
    api.get("/config-system").then((result) => {
      const flag = result.data.configSystem[0].scheduling;
      setFlagScheduling(flag);
    });
  }

  let provider = localStorage.getItem("@selected_provider");

  provider = JSON.parse(provider);

  useEffect(() => {
    getFlagScheduling();
    dispatch(setLabelWhite(true));

    return () => dispatch(setLabelWhite(false));
  }, []);

  // function messageToltip(elegibility) {
  //   if (!elegibility)
  //     return "Não foi possível checar a sua elegibilidade, favor verificar se você possui algum contrato!";

  //   return "Clique aqui para iniciar atendimento";
  // }

  function messageSchedule(elegibility) {
    if (!elegibility)
      return "Não foi possível checar a sua elegibilidade, favor verificar se você possui algum contrato!";

    return "Clique aqui para agendar uma consulta";
  }

  function goToSchedule() {
    push("/agendamento");
    dispatch(verifyAuth())
  }

  // function goToBuy() {
  //   dispatch(generateBuyToken());
  // }

  function goToDocuments() {
    push("/documentos");
  }

  function initVideoCall() {
    dispatch(getAllDependents())
    dispatch(verifyAuth())
    if (dependentsProps && dependentsProps.length > 0) {
      dispatch(openModalPatient(true));
    } else {
      dispatch(setPatient(user));
      if (user.checkElegibility && provider.validar_tipo_elegibilidade == 0) {
        dispatch(setSelectedPatient(true));
      }
      if (provider && provider.validar_tipo_elegibilidade === 1) {
        dispatch(openModalCardNumber(true));
      }
      if (provider && provider.validar_tipo_elegibilidade === 2) {
        dispatch(openModalVerification(true));
      }
      // dispatch(props.setSelectedPatient(true))
    }
  }

  return (
    <Container>
      <Layer />

      <Main>
        {/*
         {!user.checkElegibility && (
          <Alert>
            <AlertMessage>
              <FiAlertTriangle color={"#fff"} />
              <p>Você não tem um contrato ativo, adquira um contrato.</p>
            </AlertMessage>

            <button onClick={() => goToBuy()}>Ir para contratos</button>
          </Alert>
         )}*/}

        {!patientObjHasDocuments(user) && (
          <Alert marginTop={10}>
            <AlertMessage>
              <FiAlertTriangle color={"#fff"} />
              <p>
                Você ainda não fez upload de documentos pessoais, complete seu
                cadastro para uma melhor experiência.
              </p>
            </AlertMessage>

            <button onClick={() => goToDocuments()}>Ir para documentos</button>
          </Alert>
        )}

        <RowMain>
          <InitCall>
            <ButtonInit
              // elegibility={user.checkElegibility}
              // data-tip={messageToltip(user.checkElegibility)}
              onClick={() => initVideoCall()}
            >
              Iniciar Atendimento
            </ButtonInit>
            <ScheduleInit
              elegibility={user.checkElegibility}
              notElegibilityInternal={provider.validar_tipo_elegibilidade != 0}
              data-tip={messageSchedule(user.checkElegibility)}
              flagScheduling={flagScheduling}
              onClick={() =>  goToSchedule()}
              disabled={!user.checkElegibility}
            >
              <FiCalendar size={25} /> <p>Agendar</p>
            </ScheduleInit>
          </InitCall>
        </RowMain>
      </Main>

      <ReactTooltip />

      <Patient
        isOpen={modalPatient}
        onClose={() => {
          openModalPatient();
        }}
      />
      <Profile isOpen={modalUser} onClose={openModalUser} />
      <VerificationCardNumber
        isOpen={modalCardNumber}
        onClose={() => {
          openModalCardNumber();
        }}
      />

      <VerificationTel
        isOpen={modalVerification}
        onClose={() => {
          openModalVerification();
        }}
      />
      <VerificationPin
        isOpen={modalPin}
        onClose={() => {
          openModalPin();
        }}
      />
    </Container>
  );
}
