import { SET_PATIENT, PATIENT_SELECTED } from "./action-types"

export const setPatient = (patient) => ({
  type: SET_PATIENT,
  payload: patient,
})

export const setSelectedPatient = (bool) => ({
  type: PATIENT_SELECTED,
  payload: bool,
})
