import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  DivLogo,
  Logo,
  Button,
  Wrapp
} from "./styles";

import {
  setMenuVisible,
  checkTerms
} from "../../redux/actions/utils.actions";

export default function App() {
  const user = useSelector((state) => state.userReducer.user);

  const dispatch = useDispatch();

  dispatch(setMenuVisible(false));

  function setTermsTrue() {
    dispatch(checkTerms());
  }

  return (
    <Container>
        <DivLogo>
          <Logo src="img/logo-sidebar.png" />
        </DivLogo>

        <Wrapp>
          <iframe src="https://termos-de-servico-conecta.telemedicinaeinstein.com.br/" width="100%" height="100%" />
        </Wrapp>
        {
          (user.checkTerms) ? 
          null :
          <Button onClick={() => setTermsTrue()}>
            Concordar e continuar
          </Button>
        }
        
        

      </Container>
  );
}
