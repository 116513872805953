import styled, { css } from "styled-components";

const active = css`
  color: #2196f3;
`;

export const ContainerMenu = styled.div`
  display: none;
  position: absolute;
  z-index: 3000;
  width: 100%;

  @media (max-width: 1024px) {
    display: flex;
  }
`;

export const Menu = styled.div`
  position: fixed;
  width: 80%;
  height: 100vh;
  background: #fff;
  z-index: 6;

  top: 0;
  left: ${(props) => (props.showMenu ? 0 : "-800px")};
  transition: all 0.3s;
  box-shadow: 3px 0px 35px 0px rgba(77, 77, 77, 1);

  @media (max-width: 1024px) {
    width: 40%;
  }

  @media (max-width: 425px) {
    width: 80%;
  }
`;

export const HeaderMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  pointer-events: none;
  height: 75px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );
  z-index: 5;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
`;

export const ButtonMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
`;

export const LogoMenu = styled.img`
  width: 150px;
  height: 30px;
`;

export const Logged = styled.div`
  position: relative;
  background-color: #005a9c;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const User = styled.div`
  width: 180px;
  margin: 15px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const NameUser = styled.div`
  color: #fff;
  font-weight: lighter;
  font-size: 14px;
  text-align: right;
  width: 120px;
  margin: 0 10px;
`;

export const ImageUser = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;

  border-radius: 36px;
`;

export const Options = styled.div`
  width: 171px;
  border-radius: 4px;
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const OptionItem = styled.button`
  cursor: pointer;
  width: 85%;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 8px;
  border-bottom: ${(props) => (props.latest ? "" : "1px solid #fff")};
  margin: 5px 0;
`;

export const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 20px;
`;

export const MenuItem = styled.div`
  display: ${(props) => props.needScheduling && "none" || props.needScheduled && "none" || "flex"};
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  margin: 10px 0;
  ${(props) => props.active && active}
  &:hover {
    color: #2196f3;
  }
`;

export const MenuImage = styled.img``;

export const MenuLabel = styled.p`
  font-size: 14px;
  margin-left: 10px;
  color: #808080;

  ${(props) => props.active && active}

  &:hover {
    color: #2196f3;
  }
`;
