import styled from "styled-components"
import MaskedInput from "react-input-mask"

export const Container = styled.div`
  width: 450px;
  max-height: 600px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 40px 10px;

  @media (max-width: 1024px) {
    margin-top: 30px;
    /* height: 50vh; */
    max-height: 90vh;
  }
`

export const ButtonBack = styled.button`
  cursor: pointer;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background: transparent;
`

export const IconBack = styled.img`
  width: 15px;
  height: 15px;
`

export const DivInput = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
`

export const DivInputCustom = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
`

export const Input = styled.input`
  border: 2px solid #000;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  font-size: 16;
  margin-top: 10px;
`

export const DivButtons = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

export const ButtonConfirm = styled.div`
  width: 200px;
  height: 35px;
  margin-top: 8px;
  border-radius: 20px;
  padding: 7px;
  text-align: center;
  font-size: 12px;
  color: white;
  background-color: #2196f3;
  cursor: pointer;
`
export const ButtonCancel = styled.div`
  width: 90px;
  height: 30px;
  margin-top: 8px;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #2196f3;
  cursor: pointer;
`

export const Title = styled.div`
  display: flex;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 10px;
  color: black;
`
export const SubTitle = styled.div`
  display: flex;
  width: 90%;
  text-align: center;
  font-size: 14px;
  color: #707070;
  padding-bottom: 10px;
`
export const InputCardNumber = styled(MaskedInput)`
  width: 70%;
  height: 35%;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 10px;
`
