import React, { useState } from "react";
import {
  Topics,
  LabelTopics,
  TopicsDiv,
  TopicItem,
  TopicHeader,
  TopicBody,
  TopicText,
  TopicIcon,
  MoreTopics
} from "./styles";

import { TopicsJson } from "../objects";
import ModalTopics from "./modal-topics";

export default function App() {
  const [topicVisible, setTopicVisible] = useState(null);
  const [modalTopics, setModalTopics] = useState(false);

  function setTopic(key) {
    if (topicVisible === key) {
      return setTopicVisible(null);
    }
    setTopicVisible(key);
  }

  function onClose() {
    setModalTopics(false);
  }

  function onOpen() {
    setModalTopics(true);
  }

  return (
    <Topics id="duvidas-frequentes">
      <LabelTopics>Dúvidas frequentes</LabelTopics>

      <TopicsDiv>
        {TopicsJson.map((item, key) => (
          <TopicItem onClick={() => setTopic(key)}>
            <TopicHeader>
              <TopicText>{item.title}</TopicText>
              <TopicIcon
                src={
                  topicVisible === key
                    ? "img/down-arrow.png"
                    : "img/right-arrow.png"
                }
              />
            </TopicHeader>

            {topicVisible === key && (
              <TopicBody>
                <p>{item.text}</p>
              </TopicBody>
            )}
          </TopicItem>
        ))}

        <MoreTopics onClick={onOpen}>Mostrar mais</MoreTopics>
      </TopicsDiv>

      <ModalTopics onClose={onClose} isOpen={modalTopics} />
    </Topics>
  );
}
