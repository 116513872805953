import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    background: #fcfcfc;


  @media (max-width: 1180px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const DivLogo = styled.div`
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 30px;

  @media (max-width: 1180px) {
    display: none;
  }
`;

export const DivLogoWhite = styled.div`
  display: none;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 30px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0)
  );

  @media (max-width: 1180px) {
    display: flex;
  }
`;

export const Logo = styled.img`
  width: 200px;

  @media (max-width: 1024px) {
    width: 140px;
  }
`;

export const Main = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 700px;
    height: 500px;
    background: #fff;
    box-shadow: 0px 0px 16px 5px rgba(212,212,212,1);

    p{
        width: 300px;   
        color: #707070;
        text-align: center;
    }

    @media (max-width: 1024px) {
        width: 100%;
        box-shadow: none;
        

        p{
            width: 80%;   
        }
    }
`;

export const Steps = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px 0 30px;
`;


export const Step = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 15px 10px;

    p{
        width: 300px;
        margin: 0 10px;
        text-align: left;
        font-size: 12px;
    }
        

    @media (max-width: 1024px) {
        p{
            width: 80%;   
        }
    }
`;

export const NumberStep = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    background: #e0832c;
    color: #fff;
    width: 30px; 
    height: 30px;
    border-radius: 50px;
`;

export const Button = styled.button`
  width: 160px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  background: #e0832c;
  color: #fff;

  @media (max-width: 1024px) {
    margin: 0 20px;
    min-width: 100px;
  }
`;