export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const CHECK_AUTH_REQUEST = 'CHECK_AUTH_REQUEST';
export const SET_USER = 'SET_USER';
export const SET_PATIENT = 'SET_PATIENT';
export const SET_DEPENDENTS = 'SET_DEPENDENTS';
export const SET_LOADING_MODAL = 'SET_LOADING_MODAL';
export const SET_LOADING = 'SET_LOADING';
export const ADD_DEPENDENT = 'ADD_DEPENDENT';
export const MODAL_DEPENDENT = 'MODAL_DEPENDENT';
export const SET_LOADED = 'SET_LOADED';
export const SET_LOGGED = 'SET_LOGGED';
export const VERIFY_AUTH = 'VERIFY_AUTH';
export const EDIT_DEPENDENT = 'EDIT_DEPENDENT';
export const EDIT_USER = 'EDIT_USER';
export const MODAL_USER = 'MODAL_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const CALL = 'CALL';
export const SET_PLAN = 'SET_PLAN';
export const SET_DATA_USER = 'SET_DATA_USER';
export const PATIENT_SELECTED = 'PATIENT_SELECTED';
export const SEND_LINK_RECOVERY = 'SEND_LINK_RECOVERY';
export const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';
export const GET_PLANS = 'GET_PLANS';
export const GET_PLANS_BY_CATEGORY = 'GET_PLANS_BY_CATEGORY';
export const SET_PLANS = 'SET_PLANS';
export const SET_PLANS_CATEGORY = 'SET_PLANS_CATEGORY';
export const SET_LABEL_WHITE = 'SET_LABEL_WHITE';
export const REGISTER_PAYMENT = 'REGISTER_PAYMENT';
export const SET_URL_PAYMENT = 'SET_URL_PAYMENT';
export const SET_DISEASE = 'SET_DISEASE';
export const SEND_EMAIL = 'SEND_EMAIL';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SCHEDULE_SUCESS = 'SCHEDULE_SUCESS';
export const SPECIALTY_SUCESS = 'SPECIALTY_SUCESS';
export const DOCTOR_SUCESS = 'DOCTOR_SUCESS';
export const CONFIRM_SCHEDULING_SUCESS = 'CONFIRM_SCHEDULING_SUCESS';
export const FIND_SLOTS = 'FIND_SLOTS';
export const FIND_SPECIALTIES = 'FIND_SPECIALTIES';
export const FIND_DOCTORS_BY_SPECIALTY_ID = 'FIND_DOCTORS_BY_SPECIALTY_ID';
export const CONFIRM_SCHEDULING = 'CONFIRM_SCHEDULING';
export const FIND_PATIENT = 'FIND_PATIENT';
export const DEPENDENTS_GETALL_REQUEST = 'DEPENDENTS_GETALL_REQUEST';
export const BUY_AUTH = 'BUY_AUTH';
export const CHECK_TERMS = 'CHECK_TERMS';
export const GENERATE_BUY_TOKEN = 'GENERATE_BUY_TOKEN';
export const SET_MENU_VISIBLE = 'SET_MENU_VISIBLE';
export const CHANGE_PLAN = 'CHANGE_PLAN';
export const GET_DISEASE = 'GET_DISEASE';
export const SET_LIST_DISEASES = 'SET_LIST_DISEASES';
export const SET_VISIBLE_OPTIONS = 'SET_VISIBLE_OPTIONS';
export const OPEN_MODAL_REQUEST = 'OPEN_MODAL_REQUEST';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SEND_CANCEL_REQUEST = 'SEND_CANCEL_REQUEST';
export const MODAL_PLAN_B2B = 'MODAL_PLAN_B2B';
export const FIND_SLOT_BY_PATIENT = 'FIND_SLOT_BY_PATIENT';
export const APPOINTMENTS_BY_PATIENT = 'APPOINTMENTS_BY_PATIENT';
export const CANCEL_SCHEDULING = 'CANCEL_SCHEDULING';
export const CANCEL_SCHEDULING_SUCESS = 'CANCEL_SCHEDULING_SUCESS';
export const SCHEDULE_CLEAR = 'SCHEDULE_CLEAR';
export const CLEAR_SLOT_BY_PATIENT = 'CLEAR_SLOT_BY_PATIENT';
export const GET_HISTORY = 'GET_HISTORY';
export const SET_HISTORY_LIST = 'SET_HISTORY_LIST';
export const SCHEDULED_SUCCESS = 'SCHEDULED_SUCCESS';
export const FIND_PRODUCTS = 'FIND_PRODUCTS';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
export const SUCCESS_SCHEDULED_CLEAR = 'SUCCESS_SCHEDULED_CLEAR';
export const GET_ASSIGN = 'GET_ASSIGN';
export const APPOINTMENT_OLD = 'APPOINTMENT_OLD';
export const GET_FLAG_SCHEDULING = 'GET_FLAG_SCHEDULING';
export const CHECK_CARD_NUMBER = 'CHECK_CARD_NUMBER';
export const CHECK_PHONE_NUMBER = 'CHECK_PHONE_NUMBER';
export const CHECK_PIN = 'CHECK_PIN';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const MODAL_VERIFICATION = 'MODAL_VERIFICATION';
export const MODAL_CARD_NUMBER = 'MODAL_CARD_NUMBER';
export const MODAL_PIN = 'MODAL_PIN';
export const MODAL_PATIENT = 'MODAL_PATIENT';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const SET_FILES = 'SET_FILES';
export const UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS';
export const NEXT_DOCS_UPLOAD_STEP = 'NEXT_DOCS_UPLOAD_STEP';
export const UNDO_DOCS_UPLOAD_STEP = 'UNDO_DOCS_UPLOAD_STEP';
export const SET_CURRENT_DOCS_UPLOAD_FILE = 'SET_CURRENT_DOCS_UPLOAD_FILE';
export const SET_DOCS_UPLOAD_STEP = 'SET_DOCS_UPLOAD_STEP';
export const SET_DOCS_UPLOAD_STEPS = 'SET_DOCS_UPLOAD_STEPS';
export const SET_DOCS_UPLOAD_FILES = 'SET_DOCS_UPLOAD_FILES';
export const SET_SELECTED_DOC_TYPE = 'SET_SELECTED_DOC_TYPE';
export const SKIP_PROFILE_PIC_UPLOAD_STEP = 'SKIP_PROFILE_PIC_UPLOAD_STEP';
export const SELECT_DOC_STEP_FILE = 'SELECT_DOC_STEP_FILE';
export const IGNORE_UPLOAD_DOCS = 'IGNORE_UPLOAD_DOCS';
export const RESET_UPLOAD_DOCS = 'RESET_UPLOAD_DOCS';
export const SET_CURRENT_PRIVATE_PLAN = 'SET_CURRENT_PRIVATE_PLAN';
export const FETCH_CURRENT_PRIVATE_PLAN = 'FETCH_CURRENT_PRIVATE_PLAN';
export const REMOVE_DEPENDENT = 'REMOVE_DEPENDENT';
export const SET_MODAL_CONFIRM = 'SET_MODAL_CONFIRM';
export const MODAL_CONFIRM_ACCEPTED = 'MODAL_CONFIRM_ACCEPTED';
export const MODAL_CONFIRM_DISMISSED = 'MODAL_CONFIRM_DISMISSED';
export const VALIDATE_VOUCHER = 'VALIDATE_VOUCHER';
export const SET_VOUCHER = 'SET_VOUCHER';
