export const TopicsJson = [
  {
    title: "Einstein Conecta é um tipo de convênio médico?",
    text:
      "Não, o Einstein Conecta é um serviço do Centro de Telemedicina do Hospital Israelita Albert Einstein. Realizamos orientação médica on-line para casos de baixa complexidade como resfriados, dor nas costas, infecção urinária, dor de cabeça, diarreia, entre outros. ",
    type: "text",
  },
  {
    title: "A realização de exames está inclusa na contratação?",
    text:
      "Não, a contratação do Einstein Conecta dá direito a ter acesso a orientações, mas não estão inclusos exames e nem a solicitação dos mesmos.",
    type: "text",
  },
  {
    title: "Preciso pagar toda vez que eu utilizar?",
    text:
      "Não, a contratação é anual: 12x de R$ 60,00 para opção individual e no caso de plano familiar, o valor será 12 x de R$ 40,00 por membro, sem limite de utilização. Temos ainda a opção de contratação de uma consulta avulsa pelo valor único de R$ 280,00, no caso de consulta avulsa a utilização estará disponível por 24 horas após a contratação.",
    type: "text",
  },
  {
    title: "Quais formas de pagamento são aceitas?",
    text: "Cartão de crédito de todas as bandeiras.",
    type: "text",
  },
  {
    title:
      "Posso me arrepender da compra, cancelar a contratação do serviço e receber meu dinheiro de volta?",
    text:
      "Os contratos são anuais, com pagamentos mensais renovados automaticamente. Caso se arrependa da compra, basta cancelar a renovação automática. Será cobrada uma multa correspondente a 10% das parcelas restantes.",
    type: "text",
  },
  {
    title: "Posso suspender o serviço? Por quanto tempo?",
    text:
      "Não, mas o serviço pode ser cancelado a qualquer momento. Os contratos são anuais, com pagamentos renovados mensalmente. Caso queira cancelar o serviço, basta cancelar a renovação automática. Será cobrada uma multa correspondente a 10% das parcelas restantes. Caso deseje retomar o serviço posteriormente, será necessária uma nova contratação.",
    type: "text",
  },
  {
    title: "É exclusivo para quem tem convênio com acesso ao Einstein?",
    text:
      "Não, o Einstein Conecta é um serviço particular, com opções de contratação anual individual e familiar. Mesmo quem tem convênio com acesso ao Einstein terá que fazer a contratação para utilizar o serviço, caso seu convênio não ofereça o serviço. ",
    type: "text",
  },
  {
    title: "Tenho que fazer um contrato para cada pessoa da família?",
    text:
      "Não. No contrato familiar você pode cadastrar dependentes pagando um valor adicional para cada um deles. ",
    type: "text",
  },
  {
    title: "Como funciona o atendimento?",
    text: "O atendimento é rapido e fácil",
    guidelines: [
      'Acesse o Einstein Conecta pelo link "telemedicina.einstein.br" ou pelo aplicativo "Einstein Conecta" , disponível nas lojas App Store ou Play Store',
      'Na área de login, digite o seu e-mail e senha cadastrados e clique em "Enviar". Obs.: no site, é preciso clicar no botão "Login" para acessar a área.',
      'Clique no botão "iniciar o atendimento"',
      "Selecione a pessoa (titular ou dependente) para a qual deseja atendimento",
      'Clique no botão "Iniciar atendimento" e pronto: em instantes você será atendido.',
    ],
    type: "pass",
  },
  {
    title: "Posso ser atendido sempre pelo mesmo médico?",
    text:
      "Não. O atendimento do Einstein Conecta é o mesmo de um pronto atendimento, ou seja, você será atendido pelo primeiro médico disponível.",
    type: "text",
  },
  {
    title: "É possível agendar um atendimento?",
    text:
      "No momento não é possível agendar atendimento. O sistema de atendimento é similar ao de um pronto atendimento.",
    type: "text",
  },
  {
    title: "Devo utilizar o Einstein Conecta em casos de emergência?",
    text:
      "Sempre que houver algum dos sintomas abaixo, recomendamos que o atendimento seja feito em um pronto atendimento presencial de sua rede credenciada ou que ligue para o 192 ou 193:",
    type: "topics",
    guidelines: [
      "• Falta de ar, dor no peito, palpitações ou arritmias",
      "• Convulsões ou alterações neurológicas",
      "• Perda de consciência/desmaio",
      "• Acidentes automobilísticos, ferimentos cortantes ou fraturas",
    ],
  },
  {
    title: "Como são feitas as avaliações médicas pelo Einstein Conecta?",
    text:
      "Todas as avaliações são realizadas por videochamada, através da internet, de forma que tanto médico quanto paciente veem um ao outro ao vivo, podendo interagir livremente como em uma consulta face a face. Para o exame físico, o médico observará durante a consulta, através da câmera, o estado geral, o padrão respiratório, sinais de dor e desconforto, além de poder avaliar garganta, olhos e lesões de pele. O médico também poderá pedir ao paciente para realizar determinadas manobras que ajudem a elucidar melhor o diagnóstico e otimizar a conduta. No caso de atendimentos pediátricos, o médico poderá pedir o auxílio do responsável para obter outros dados importantes na avaliação do exame físico. Ele poderá orientar o responsável a auxiliá-lo na avaliação de lesões de pele (textura, descamação, calor local), na avaliação da dor em algum membro ou região do corpo, na avaliação da perfusão da pele, na contagem da frequência respiratória – número de respirações por minuto – entre outros.",
    type: "text",
  },
  {
    title: "Como é feito o diagnóstico?",
    text:
      "Os dados obtidos pelo médico durante a avaliação possibilitarão a construção de uma hipótese diagnóstica, embasada nos mais recentes protocolos clínicos nacionais e internacionais, devidamente adaptados para as avaliações por telemedicina. Todos os atendimentos geram um relatório completo, contendo a hipótese diagnóstica e orientações médicas personalizadas, que serão enviados ao paciente por e-mail. Caso seja pertinente à situação, poderão ser encaminhadas também receitas médicas ou atestados.",
    type: "text",
  },
  {
    title: "E se o médico não conseguir fazer a avaliação a distância?",
    text:
      "Em mais de 80% dos casos a avaliação por telemedicina pelo Einstein Conecta é suficiente para uma orientação médica segura. No entanto, quando é identificado um sinal de gravidade que indique que a consulta por telemedicina não é segura ou houver a necessidade de avaliação física presencial para um diagnóstico mais preciso, o paciente será orientado a procurar um atendimento presencial. Mesmo nestes casos um relatório, explicando as razões do encaminhamento, é enviado ao paciente, que pode levar ao local de atendimento para otimizar a continuidade do cuidado. ",
    type: "text",
  },
  {
    title: "Quem realiza os atendimentos do Einstein Conecta?",
    text:
      "O paciente será atendido pela equipe médica com a qualidade Einstein, com ampla experiência em atendimento de emergências, treinada em atendimento por telemedicina e nos protocolos Einstein de atendimento.",
    type: "text",
  },
  {
    title: "Poderá ser atendida mais de uma queixa em um único atendimento?",
    text: "Sim, desde que as queixas estejam no escopo do atendimento.",
    type: "topics",
    guidelines: [
      "• Alergias e lesões na pele",
      "• Desconforto abdominal",
      "• Dor de cabeça",
      "• Dor de garganta",
      "• Dor lombar",
      "• Dor ou infecção nos olhos",
      "• Febre",
      "• Feridas e queimaduras",
      "• Náuseas e vômitos",
      "• Picadas",
      "• Sintomas da gripe e resfriados",
      "• Sintomas urinários",
    ],
  },
  {
    title: "Preciso agendar a orientação?",
    text:
      "Não. Basta acessar a qualquer momento o link ou aplicativo do Einstein Conecta e iniciar o atendimento.",
    type: "text",
  },
  {
    title: "As orientações on-line contemplam retorno?",
    text:
      "Não. O Conecta funciona como um atendimento similar ao de um Pronto Atendimento presencial, onde não há acompanhamento médico. Por isso, após a primeira orientação, não é feita uma orientação de retorno. ",
    type: "text",
  },

  {
    title: "Terei encaminhamento para exames?",
    text:
      "Não. Nestes casos, você será orientado a buscar um médico que atenda presencialmente em sua rede credenciada. Exceção temporária: O único exame solicitado pela equipe médica no momento é o RT-PCR para SARS-CoV-2 (COVID-19), caso os dados clínicos atendam a critérios pré-estabelecidos em nosso protocolo. A realização do mesmo será de responsabilidade do paciente em um laboratório de sua preferência. ",
    type: "text",
  },
  {
    title:
      "Posso receber orientação pediátrica para meus dependentes pelo Einstein Conecta?",
    text:
      'Se os seus dependentes estiverem inclusos em sua contratação, sim. Ao selecionar quem receberá atendimento na etapa "Selecionar o paciente" do aplicativo ou site, a equipe do Einstein encaminhará para o profissional adequado à idade do paciente. Médico Pediatra: crianças e jovens, de 0 a 15 anos. Médico Generalista: jovens e adultos, a partir de 16 anos.',
    type: "text",
  },
  {
    title: "Preciso estar presente durante o atendimento pediátrico?",
    text:
      "Sim. Para que seu dependente de 0 a 15 anos possa ser atendido, será necessário que você participe da chamada e esteja com os seus documentos e os documentos do paciente em mãos.",
    type: "text",
  },
  {
    title: "Como funciona o atendimento pediátrico?",
    text:
      "O pediatra vai interagir com o paciente e com seu responsável. Quanto mais colaborativo o paciente estiver, melhor será a avaliação. Procure escolher um local adequado para a videoconferência, onde bebê ou criança estejam confortáveis. Será perguntado pelo pediatra o que motivou o contato e o histórico médico recente, detalhes da evolução da queixa, sinais e sintomas associados, fatores de melhora ou piora e outras informações pertinentes.",
    type: "text",
  },
  {
    title: "Caso eu precise do meu prontuário, como faço para resgatar?",
    text:
      "Se for necessário o resgate do seu prontuário, será preciso entrar em contato com o suporte técnico Einstein pelo e-mail einsteinconecta@einstein.br  ou pelo telefone: (11) 2151-7690.",
    type: "text",
  },
  {
    title: "Como eu recebo prescrição médica?",
    text:
      "Após a sua triagem e atendimento virtual, você receberá em seu e-mail um relatório completo com as orientações gerais, uma sugestão diagnóstica, prescrição de medicamentos para alívio de sintomas e conduta sugerida. Todos os documentos emitidos são assinados com certificação digital e aceitos nas farmácias de todo o território nacional.",
    type: "text",
  },
  {
    title:
      "Estou na tela de espera e ainda não fui atendido. Qual o tempo de espera?",
    text:
      "Normalmente o tempo de espera é inferior a 5 minutos. Caso a previsão de espera seja superior a 10 minutos, você será avisado pela equipe da recepção.",
    type: "text",
  },
  {
    title:
      "A capacitação dos médicos do Einstein Conecta é semelhante a dos médicos que atendem no hospital?",
    text:
      "O requisito dos médicos é o mesmo para os atendimentos nas unidades físicas do Hospital Israelita Albert Einstein. Inclui ainda uma capacitação especial nos protocolos de atendimento por telemedicina e treinamento na atuação por videoconferência.      ",
    type: "text",
  },
  {
    title: "Quais especialidades médicas atendem no Einstein Conecta?",
    text:
      "O atendimento pelo Einstein Conecta não contempla especialidades médicas. A equipe é composta por médicos generalistas e pediatras. ",
    type: "text",
  },
  {
    title: "Os atestados são aceitos em todo o território nacional?",
    text:
      "Sim. Os atestados são assinados digitalmente com um certificado digital padrão ICP-Brasil, que por lei tem validade legal semelhante a um documento assinado fisicamente, com garantia de origem, veracidade e irrevogabilidade. Contém um código que pode dar acesso à versão original em nossos servidores.",
    type: "text",
  },
  {
    title:
      "Se algum sintoma estiver se agravando rapidamente, o que eu devo fazer?",
    text:
      "Idealmente, caso um sintoma se agrave o ideal é procurar um atendimento presencial em sua localidade. Caso tenha alguma dúvida sobre se isso é necessário, nossa equipe está igualmente à disposição para ajudá-lo a decidir.",
    type: "text",
  },
  {
    title: "Estou fora do Brasil, posso usar o serviço?",
    text:
      "Sim, você pode utilizar os serviços do Conecta a partir de qualquer lugar do mundo com acesso a banda larga de internet (exceto regiões com controle de acesso governamental)",
    type: "text",
  },
  {
    title: "A receita médica tem validade em outros países?",
    text:
      "Não. A receita médica tem validade apenas em território brasileiro. No entanto, nossa equipe está preparada para orientar a compra de medicamentos sintomáticos que estejam disponíveis na prateleira de farmácias em outros países, sem necessidade de receita médica. Por outro lado, caso seja necessária uma receita médica, recomendamos que procure um serviço médico local ou consulte seu seguro saúde para outras opções.",
    type: "text",
  },
  {
    title: "Quais serviços não são oferecidos?",
    type: "topics",
    guidelines: [
      "• Solicitação de exames (exceto RT-PCR para COVID-19, quando indicado)",
      "• Atestado médico para atividades físicas ou clube/piscina",
      "• Renovação de receita médica controlada (antibiótico, anticonvulsionante, hormônio, entre outros)",
      "• Encaminhamento para terapia ou avaliação para especialistas",
    ],
  },
  {
    title: "Posso usar minha rede móvel (ex.: 4G) para acessar o serviço?",
    text:
      "Sim, você pode acessar o serviço por meio de qualquer conexão com a internet.",
    type: "text",
  },
  {
    title: " O serviço é compatível com quais sistemas operacionais?",
    text:
      "O aplicativo do Einstein Conecta está disponível para os sistemas operacionais Android e iOS. Alternativamente, o sistema no site do Einstein Conecta é uma aplicação web que funciona nos navegadores Chrome ou Firefox, ou seja, não depende do sistema operacional (Windows, Linux, etc)",
    type: "text",
  },
  {
    title: "E se a minha conexão com a internet cair durante a consulta?",
    text:
      "Assim que restabelecer a sua conexão, reinicie o fluxo de atendimento para retomar a consulta.",
    type: "text",
  },
  {
    title:
      "O que devo fazer se a imagem da minha câmera não estiver funcionando?",
    text:
      "Você pode verificar se ela não está somente desativada na tela da videoconferência, tocando/clicando no botão com o desenho de uma câmera. Caso não resolva, entre em contato com o suporte técnico pelo telefone (11) 2151-7690.",
    type: "text",
  },
  {
    title: "Onde ficam armazenados os dados do meu prontuário eletrônico?",
    text:
      "Os prontuários eletrônicos são armazenados nos servidores do Einstein de forma segura, conforme determinação legal, garantindo a privacidade necessária ao acesso dos dados médicos dos pacientes.      ",
    type: "text",
  },
]
