import React, { useState } from "react";
import VerificationInput from "react-verification-input";
import {
  Container,
  ButtonBack,
  IconBack,
  DivInput,
  DivInputCustom,
  Title,
  SubTitle,
  DivButtons,
  ButtonConfirm,
  ButtonText,
  ButtonTextResend,
} from "./styles";

import "./styles.css";

import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../../../components/modal-provider";

import {
  findPin,
  openModalPin,
  openModalVerification,
  findPhoneNumber,
} from "../../../../../redux/actions/user.actions";

export default function App(props) {
  const dispatch = useDispatch();

  const phone = useSelector((state) => state.userReducer.phone);

  const [ref, setRef] = useState(null);
  const [resends] = useState(false);

  function checkToken(token) {
    const validaToken = { number: phone, pin: token };
    dispatch(findPin(validaToken));
  }

  function resendsToken() {
    const user = {};
    const provider = JSON.parse(localStorage.getItem("@selected_provider"));
    user.empresas = [provider];
    user.phoneNumber = phone;
    dispatch(findPhoneNumber(user));
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <Container>
        <ButtonBack type="button" onClick={() => dispatch(openModalPin(false))}>
          <IconBack src="img/close-icon.png"></IconBack>
        </ButtonBack>

        <Title>Validar Token</Title>
        <SubTitle>
          Digite o token de autenticação que foi enviado para o número{}:
        </SubTitle>

        <ButtonText
          onClick={() => {
            dispatch(openModalPin(false));
            dispatch(openModalVerification(true));
          }}
        >
          Alterar número
        </ButtonText>

        <DivInput>
          <DivInputCustom>
            <VerificationInput
              autoFocus
              length={4}
              removeDefaultStyles={false}
              container={{
                className: "container",
              }}
              characters={{
                className: "characters",
              }}
              character={{
                className: "character",
                classNameInactive: "character--inactive",
                classNameSelected: "character--selected",
              }}
              getInputRef={(inputRef) => setRef(inputRef)}
              inputField={{
                onChange() {},
              }}
            ></VerificationInput>
          </DivInputCustom>
        </DivInput>
        {resends && (
          <ButtonTextResend>Token reenviado com sucesso!</ButtonTextResend>
        )}
        {!resends && (
          <ButtonText onClick={resendsToken}>Reenviar Token</ButtonText>
        )}

        <DivButtons>
          <ButtonConfirm
            onClick={() => {
              checkToken(ref.value);
            }}
          >
            CONTINUAR
          </ButtonConfirm>
        </DivButtons>
      </Container>
    </Modal>
  );
}
