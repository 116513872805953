import React from "react";
import * as S from "./styles";

import { push } from "../../../services/navigate";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "../../../redux/actions/utils.actions";
import { setCurrentPlan } from "../../../redux/actions/buy.actions";


export default function App({ plansAPI, plansJSON }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userReducer.user);

  function setPlan(plan) {
    dispatch(setCurrentPlan(plan));
    dispatch(setActiveStep(2));
    localStorage.setItem("@current_plan", JSON.stringify(plan));

    if (user && user.name) {
      return push(`/comprar/${plan.namePlan}`);
    }

    localStorage.setItem("buy-flag", "true");
    return push(`/cadastro/${plan.namePlan}`);
  }

  return (
    <S.Plans id="contratos" src="img/contratos.png">
      <S.PlansLabel>contratos e valores</S.PlansLabel>
      <S.PlansDiv>
        {plansAPI &&
          plansAPI.length > 0 &&
          plansAPI.map((planAPI) => {
            let plan = plansJSON[planAPI.category];
            if (plan) {
              return (
                <S.Plan key={plan.namePlan}>
                  <S.CardHeading>
                    <S.TitlePlan>{plan.namePlan}</S.TitlePlan>
                    <S.SubTitlePlan namePlan={plan.namePlan}>{plan.typePlan}</S.SubTitlePlan>
                    {plan.oldPrice
                        && (
                        <S.OldPrice>
                          de <b>R$ {plan.oldPrice},00</b>
                        </S.OldPrice>)
                    }

                    <S.NewPrice>
                      <S.Price>R$ {planAPI.Amount}</S.Price>
                      <S.DetailsPrice>
                        <p>,00</p>
                        <span>{plan.labelTypePlan}</span>
                      </S.DetailsPrice>
                    </S.NewPrice>
                  </S.CardHeading>
                  <S.ListAdvantagesPlan>
                    {plan.advantages.map((advantage) => (
                      <S.Advantage>
                        <S.IconAdvantage src="/img/tick.png" />
                        <S.LabelAdvantage>{advantage}</S.LabelAdvantage>
                      </S.Advantage>
                    ))}
                  </S.ListAdvantagesPlan>
                  <S.ButtonPlan
                      namePlan={plan.namePlan}
                      onClick={() => setPlan({ ...plan, ...planAPI })}
                  >
                    Contrate agora
                  </S.ButtonPlan>
                  { plan.disclaimer && <S.LabelDisclaimer>{plan.disclaimer}</S.LabelDisclaimer> }
                </S.Plan>
              );
            }
          })}
      </S.PlansDiv>
    </S.Plans>
  )}

