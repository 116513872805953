import styled, { css } from "styled-components";

const active = css`
  color: #2196f3;
`;

export const SideBar = styled.div`
  width: 210px;
  position: fixed;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const DivLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 150px;
`;

export const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 20px;
`;

export const MenuItem = styled.div`
  display: ${(props) => props.needScheduling && "none" || props.needScheduled && "none" || "flex"};
  align-items: center;
  flex-direction: row;
  margin: 10px 0;
  cursor: ${(props) => (props.notEligibility ? "not-allowed" : "pointer")};
  ${(props) => props.active && active}
  &:hover {
    color: #2196f3;
  }
`;

export const MenuImage = styled.img``;

export const MenuLabel = styled.p`
  font-size: 14px;
  margin-left: 10px;
  color: #808080;

  ${(props) => props.active && active}

  &:hover {
    color: #2196f3;
  }
`;
