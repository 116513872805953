import React, { useState, useEffect } from "react";
import {
  ContainerMenu,
  HeaderMenu,
  LogoMenu,
  ButtonMenu,
  Menu,
  Logged,
  Options,
  OptionItem,
  User,
  NameUser,
  ImageUser,
  MenuList,
  MenuItem,
  MenuLabel,
} from "./styles";

import { FiMenu, FiX } from "react-icons/fi";

import itemsMenu from "../../utils/items-side-bar";
import api from "../../services/api";

import { push } from "../../services/navigate";
import { useDispatch, useSelector } from "react-redux";
import { openModalUser } from "../../redux/actions/user.actions";
import { changePlan } from "../../redux/actions/buy.actions";
import { verifyAuth } from "../../redux/actions/utils.actions";

export default function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [activeRoute, setActiveRoute] = useState("/home");
  const user = useSelector((state) => state.userReducer.user);
  const [flagScheduling, setFlagScheduling] = useState(false);
  const appointments = useSelector((state) => state.schedule.scheduled);

  const host = window.location.href;

  const dispatch = useDispatch();

  function getFlagScheduling() {
    api.get("/config-system").then((result) => {
      const flag = result.data.configSystem[0].scheduling;
      setFlagScheduling(flag);
    });
  }

  let provider = localStorage.getItem("@selected_provider");

  provider = JSON.parse(provider);

  useEffect(() => {
    getFlagScheduling();

    const activeRoute = window.location.href.split("/")[3];

    if (!activeRoute) {
      return setActiveRoute(`/home`);
    }

    setActiveRoute(`/${activeRoute}`);
  }, []);

  const options = [
    {
      id: "HOME",
      label: "Home",
      action: () => goToHome(),
    },
    {
      id: "MEU_PERFIL",
      label: "Meu perfil",
      action: () => goToPerfil(),
    },
    {
      id: "ALTERAR_CONTRATO",
      label: "Alterar contrato",
      action: () => goToChangePlan(),
    },
    {
      id: "COMO_CANCELAR",
      label: "Como cancelar",
      action: () => goToCancel(),
    },
    {
      id: "TROCAR_PROVEDOR",
      label: "Trocar provedor",
      action: () => goToProvider(),
    },
    {
      id: "TROCAR_CARTAO_DE_CREDITO",
      label: "Trocar cartão de crédito",
      action: () => goToChangeCreditCard(),
    },
    {
      id: "SAIR",
      label: "Sair",
      action: () => logout(),
    },
  ];

  function goToChangePlan() {
    dispatch(changePlan());
  }

  function goToHome() {
    push("/home");
  }

  function goToCancel() {
    push("/cancelar");
  }

  function goToChangeCreditCard() {
    push("/trocar-cartao");
  }

  function goToProvider() {
    push("/provedores");
    localStorage.removeItem("@selected_provider");
  }

  function goToPerfil() {
    push("/home");
    dispatch(openModalUser(true));
  }

  function logout() {
    localStorage.removeItem("@access_token");
    window.location.href = "/login";
  }

  function openMenu() {
    setShowMenu(true);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  function changeRoute(infosRoute) {
    if (infosRoute.needEgilibility && !user.checkElegibility) {
      return;
    }
    push(infosRoute.route);
    setActiveRoute(infosRoute.route);
    dispatch(verifyAuth())
  }

  function messageToltip(infosRoute) {
    if (infosRoute.needEgilibility && !user.checkElegibility)
      return "Não foi possível checar a sua elegibilidade, favor verificar se você possui algum contrato!";
  }

  function getMessage() {
    const dateNow = new Date();
    const hour = dateNow.getHours();

    if (hour < 5) {
      return "Boa noite";
    }

    if (hour < 12) {
      return "Bom dia";
    }

    if (hour < 18) {
      return "Boa tarde";
    }

    return "Bem-vindo";
  }

  function getCurrentOptions() {
    const hasPrivatePlan =
      provider.id_convenio == process.env.REACT_APP_ID_AGREEMENT_PARTICULAR;

    const isPlanAvulso = provider.subgrupo === 'PLANO AVULSO B2C';
  
    if (isPlanAvulso)
      return options.filter((elem) => (
        elem.id !== "TROCAR_CARTAO_DE_CREDITO"
      ));

    if (!hasPrivatePlan)
    return options.filter((elem) => (
      elem.id !== "COMO_CANCELAR" && elem.id !== "TROCAR_CARTAO_DE_CREDITO"
    ));

    return options;
  }

  return (
    <ContainerMenu>
      <HeaderMenu>
        <FiMenu
          style={{ pointerEvents: "all" }}
          size="25px"
          onClick={openMenu}
          color="#fff"
        />
        <LogoMenu src="img/logo-white.svg" />
      </HeaderMenu>

      <Menu showMenu={showMenu}>
        <Logged>
          <ButtonMenu onClick={closeMenu}>
            <FiX size="25px" color="#fff" />
          </ButtonMenu>

          <User>
            <NameUser>
              Olá, {getMessage()}. {user.name || user.firstName}
            </NameUser>
            <ImageUser
              src={user.image || "img/default.png"}
              onError={(e) => (e.target.src = "img/default.png")}
              o
            />
          </User>

          <Options>
            {getCurrentOptions().map((option, key) => (
              <OptionItem
                latest={key + 1 === options.length}
                onClick={() => option.action()}
              >
                {option.label}
              </OptionItem>
            ))}
          </Options>
        </Logged>

        <MenuList>
          {itemsMenu.map((itemMenu, key) => {
            if (host.includes("telemedicina.einstein.br") && itemMenu.debug)
              return;
            return (
              <MenuItem
                active={itemMenu.route === activeRoute}
                onClick={() => changeRoute(itemMenu)}
                data-tip={messageToltip(itemMenu)}
                needScheduling={
                  (itemMenu.needScheduling && !flagScheduling) ||
                  (itemMenu.needScheduling &&
                    provider.validar_tipo_elegibilidade != 0)
                }
                needScheduled={
                  (itemMenu.needScheduled && appointments.length === 0) ||
                  (itemMenu.needScheduling &&
                    provider.validar_tipo_elegibilidade != 0)
                }
              >
                {itemMenu.icon && itemMenu.icon}
                <MenuLabel active={itemMenu.route === activeRoute}>
                  {itemMenu.label}
                </MenuLabel>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </ContainerMenu>
  );
}
