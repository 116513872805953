import React, { useState } from "react";
import Modal from "../../../../components/modal";
import {
  Container,
  TopicsDiv,
  TopicItem,
  TopicHeader,
  TopicBody,
  TopicText,
  TopicIcon,
  UlCustom,
  LiCustom,
  IconClose,
} from "./styles";

import { TopicsJson } from "./objects";
import { AiOutlineClose } from "react-icons/ai";

export default function App(props) {
  const [topicVisible, setTopicVisible] = useState(null);

  function setTopic(key) {
    if (topicVisible === key) {
      return setTopicVisible(null);
    }
    setTopicVisible(key);
  }

  function returnDiv(item) {
    switch (item.type) {
      case "text":
        return (
          <TopicBody>
            <p>{item.text}</p>
          </TopicBody>
        );
      case "pass":
        return (
          <TopicBody>
            <p>{item.text}</p>
            <UlCustom>
              {item.guidelines.map((item, key) => (
                <LiCustom>
                  <b>{key + 1}</b> - {item}
                </LiCustom>
              ))}
            </UlCustom>
          </TopicBody>
        );
      case "topics":
        return (
          <TopicBody>
            <p>{item.text}</p>
            <UlCustom>
              {item.guidelines.map((item, key) => (
                <LiCustom>{item}</LiCustom>
              ))}
            </UlCustom>
          </TopicBody>
        );
    }
  }

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen}>
      <Container>
        <IconClose onClick={props.onClose}>
          <AiOutlineClose size="35px" color="#fff" />
        </IconClose>

        <TopicsDiv>
          {TopicsJson.map((item, key) => (
            <TopicItem onClick={() => setTopic(key)}>
              <TopicHeader>
                <TopicText>{item.title}</TopicText>
                <TopicIcon
                  src={
                    topicVisible === key
                      ? "img/down-arrow.png"
                      : "img/right-arrow.png"
                  }
                />
              </TopicHeader>

              {topicVisible === key && returnDiv(item)}
            </TopicItem>
          ))}
        </TopicsDiv>
      </Container>
    </Modal>
  );
}
