import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Main,
  DivLogo,
  Logo,
  ImageForm,
  Providers,
  ItemProvider,
  Labels,
  Button,
  DivBody,
  DivButton,
  ButtonBack,
  IconBack,
  LabelBack,
  ImageProvider,
  ProviderDescriptionContainer
} from "./styles";

import { push } from "../../services/navigate";
import {
  generateBuyToken,
  setMenuVisible,
} from "../../redux/actions/utils.actions";
import LinesEllipsis from 'react-lines-ellipsis'

export default function App() {
  const user = useSelector((state) => state.userReducer.user);

  const [activeProvider, setActiveProvider] = useState(false);
  const [particularAgreements, setParticularAgreements] = useState(false);

  const providersExt = user.empresasExterno;
  const providersInt = user.empresas;

  const dispatch = useDispatch();

  useEffect(() => {
    if (providersInt) {
      providersInt.map((prov) => {
        if (
          prov.id_convenio === process.env.REACT_APP_ID_AGREEMENT_PARTICULAR
        ) {
          setParticularAgreements(true);
        }
      });
    }
    dispatch(setMenuVisible(false));
  }, [dispatch, providersInt]);

  function setProvider(provider, key) {
    setActiveProvider({ ...provider, key });
  }

  function handleSubmit() {
    if (!activeProvider || !activeProvider.key) return;
    if (activeProvider.key === 301) {
      return dispatch(generateBuyToken());
    }
    localStorage.setItem("@selected_provider", JSON.stringify(activeProvider));
    window.location.href = "/home";
  }

  return (
    <Container>
      <Main>
        <DivLogo onClick={() => push("/")}>
          <Logo src="img/logo-sidebar.png" />
        </DivLogo>

        <ButtonBack
          onClick={() => {
            localStorage.removeItem("@access_token");
            window.location.href = "/login";
          }}
        >
          <IconBack src="img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        {user && user.checkElegibility && (
          <DivBody>
            <Labels>
              <h3>Escolha seu provedor</h3>
              <p>
                Identificamos a sua elegibilidade. Selecione o provedor para
                prosseguir com o atendimento:
              </p>
            </Labels>

            <Providers>
              {(providersInt || []).map((providerInt, key) => (
                <ItemProvider
                  onClick={() => setProvider(providerInt, key + 112)}
                >
                  <ImageProvider active={activeProvider.key === key + 112}>
                    <img
                      src={providerInt.imagem || "img/default_image.png"}
                      onError={(e) => (e.target.src = "img/default_image.png")}
                    />
                  </ImageProvider>
                  <ProviderDescriptionContainer>
                    <LinesEllipsis
                      text={providerInt.descricao}
                      basedOn="letters"
                      maxLine="2"
                      ellipsis="..."
                    ></LinesEllipsis>
                  </ProviderDescriptionContainer>
                </ItemProvider>
              ))}
            </Providers>

            {providersExt && providersExt.length > 0 && (
              <>
                <Labels>
                  <p>
                    Você também pode utilizar ou contratar um dos provedores
                    abaixo:
                  </p>
                </Labels>
                <Providers>
                  {(providersExt || []).map((provider, key) => (
                    <ItemProvider
                      onClick={() => setProvider(provider, key + 1)}
                    >
                      <ImageProvider active={activeProvider.key === key + 1}>
                        <img
                          src={provider.imagem || "img/default_image.png"}
                          onError={(e) =>
                            (e.target.src = "img/default_image.png")
                          }
                        />
                      </ImageProvider>

                      <ProviderDescriptionContainer>
                        <LinesEllipsis
                          text={provider.descricao}
                          basedOn="letters"
                          maxLine="2"
                          ellipsis="..."
                        ></LinesEllipsis>
                      </ProviderDescriptionContainer>
                    </ItemProvider>
                  ))}
                </Providers>
                {!particularAgreements && (
                  <>
                    <Labels>
                      <p>
                        Você ainda pode contratar um plano particular para
                        seguir com o atendimento:
                      </p>
                    </Labels>
                    <Providers>
                      <ItemProvider onClick={() => setProvider({}, 301)}>
                        <ImageProvider active={activeProvider.key === 301}>
                          <img src={"img/einstein.jpeg"} />
                        </ImageProvider>
                        <ProviderDescriptionContainer>
                          <LinesEllipsis
                            text="Atendimento particular"
                            basedOn="letters"
                            maxLine="2"
                            ellipsis="..."
                          ></LinesEllipsis>
                        </ProviderDescriptionContainer>
                      </ItemProvider>
                    </Providers>
                  </>
                )}
              </>
            )}
          </DivBody>
        )}
        {user && !user.checkElegibility && (
          <DivBody>
            <Labels>
              <h3>Selecione sua empresa conveniada</h3>
              <p>
                Para iniciar o seu atendimento clique abaixo em uma das 
                empresas que você é elegível:
              </p>
            </Labels>

            <div>
              <Providers>
                {providersExt.map((provider, key) => (
                  <ItemProvider onClick={() => setProvider(provider, key + 1)}>
                    <ImageProvider active={activeProvider.key === key + 1}>
                      <img
                        src={provider.imagem || "img/default_image.png"}
                        onError={(e) => (e.target.src = "img/default_image.png")}
                      />
                    </ImageProvider>
                    <ProviderDescriptionContainer>
                      <LinesEllipsis
                        text={provider.descricao}
                        basedOn="letters"
                        maxLine="2"
                        ellipsis="..."
                        component="p"
                      />
                    </ProviderDescriptionContainer>
                  </ItemProvider>
                ))}
              </Providers>
              <Labels>
                <p>
                  Você ainda pode contratar um plano particular para seguir com
                  o atendimento:
                </p>
              </Labels>

              <Providers>
                <ItemProvider onClick={() => setProvider({}, 301)}>
                  <ImageProvider active={activeProvider.key === 301}>
                    <img 
                      style={{ width: 80, height: 80 }}
                      src={"img/einstein.jpeg"} 
                    />
                  </ImageProvider>
                  <p>Atendimento particular</p>
                </ItemProvider>
              </Providers>
            </div>
          </DivBody>
        )}
        <DivButton>
          <Button
            onClick={handleSubmit}
            active={activeProvider && activeProvider.key}
          >
            Continuar
          </Button>
        </DivButton>
      </Main>
      <ImageForm src="img/home-kv.png">
        <h1>IDENTIFICAÇÃO PARA ATENDIMENTO</h1>
      </ImageForm>
    </Container>
  );
}
