import styled from "styled-components";

import { motion } from "framer-motion";
import animations from "../../utils/animation";

export const Container = styled(motion.div).attrs(animations)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const ImageForm = styled.div`
    background-image: url('${(props) => props.src}');
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    width: 85%;
    height: 100%;

    @media (max-width: 1024px) {
    display: none;
  }

`;

export const BackIos = styled.div`
  padding: 15px;
  padding-top: 35%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
export const BackIosButton = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #e0832c;
  color: white;
  text-align: center;
  border-radius: 25px;
`;

export const Form = styled.form`
  max-width: 405px;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px 0 0;
  z-index: 300;
  background: #fff;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const DivLogo = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 20px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  width: 220px;
`;

export const Inputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

export const Icon = styled.img``;

export const LabelPassword = styled.div`
  width: 335px;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    color: #005a9c;
    font-size: 15px;
  }

  @media (max-width: 1024px) {
    width: 70%;
    margin: 20px 0 0;
  }
`;

export const Button = styled.button`
  border: 0;
  border-radius: 200px;
  font-weight: bolder;
  color: #005a9c;
  margin: 0 auto;
  width: 305px;
  height: 37px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`;

export const Register = styled.p`
  cursor: pointer;
  color: #707070;
  margin: 80px auto 0;
  b {
    color: #005a9c;
  }
`;
