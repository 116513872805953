import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import "semantic-ui-react";
import { show } from "../../services/alert";
import {
  slotByPatient,
  cancelScheduling,
} from "../../redux/actions/schedule.actions";
import { push } from "../../services/navigate";
import api from "../../services/api";

import {
  Body,
  DivTitle,
  TitleSchedule,
  TitleInfo,
  SubTitleSchedule,
  SubTitleInfo,
  DivScheduled,
  BoxDateTime,
  DivPatient,
  DivButtons,
  Button,
  Div,
  HeaderScheduled,
  Header,
  BodyScheduled,
  TitleScheduled,
  SubTitleScheduled,
  ButtonOk,
  TitleDate,
  TitleTime,
  DivHeader,
  DivBody,
  ButtomSchedule,
} from "./styles";

import "./style.css";

import { useSelector, useDispatch } from "react-redux";
import Container from "../../container";

import Confirm from "./modal-confirm";

function DateTimeFormInline() {
  const dispatch = useDispatch();

  const appointments = useSelector((state) => state.schedule.scheduled);
  const user = useSelector((state) => state.userReducer.user);

  const [modalPatient, setModalPatient] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [flagScheduling, setFlagScheduling] = useState(false);

  function getFlagScheduling() {
    api.get("/config-system").then((result) => {
      const flag = result.data.configSystem[0].scheduling;
      setFlagScheduling(flag);
    });
  }

  useEffect(() => {
    if (user && !user.checkElegibility) {
      return push("/home");
    }
    getFlagScheduling();
    dispatch(slotByPatient(user));
  }, []);

  const onClickAccessConsult = (slot) => {
    if (!slot.roomUrl) {
      show("Sala indisponível");
    } else {
      window.open(slot.roomUrl, "_blank");
    }
  };

  const handleOpen = (slot) => {
    setSelectedSlot(slot);
    setModalPatient(true);
  };

  const handleClose = (confirm) => {
    if (confirm) {
      dispatch(cancelScheduling(selectedSlot));
      setModalPatient(false);
    } else {
      setModalPatient(false);
    }
  };

  function goToSchedule() {
    push("/agendamento");
  }

  return (
    <Container>
      <Div>
        <DivTitle>
          <TitleSchedule>Agendamentos</TitleSchedule>
          <SubTitleSchedule>
            Verifique suas consultas agendadas
          </SubTitleSchedule>
        </DivTitle>

        <Body>
          {appointments.length === 0 && (
            <HeaderScheduled>
              <Header>
                <BodyScheduled>
                  <TitleScheduled>
                    Você não possui uma consulta agendada!
                  </TitleScheduled>
                  {flagScheduling && (
                    <ButtomSchedule>
                      <SubTitleScheduled>
                        Clique abaixo para agendar:
                      </SubTitleScheduled>

                      <ButtonOk
                        onClick={() => {
                          goToSchedule();
                        }}
                      >
                        Agendar
                      </ButtonOk>
                    </ButtomSchedule>
                  )}
                </BodyScheduled>
              </Header>
            </HeaderScheduled>
          )}
          {appointments &&
            appointments.map((slot) => (
              <DivScheduled>
                <DivHeader>
                  <BoxDateTime>
                    <TitleDate>
                      {moment(slot.startAt).format("DD/MM")}
                    </TitleDate>
                    <TitleTime>
                      {moment(slot.startAt).format("HH:mm")}
                    </TitleTime>
                  </BoxDateTime>
                  <DivPatient>
                    <TitleInfo>PACIENTE</TitleInfo>
                    <SubTitleInfo>{`${slot.Patient.name} ${slot.Patient.lastName}`}</SubTitleInfo>
                  </DivPatient>
                </DivHeader>

                <DivBody>
                  <DivPatient>
                    <TitleInfo>ESPECIALIDADE: </TitleInfo>
                    <SubTitleInfo>
                      {slot.Specialty.name
                        ? slot.Specialty.name
                        : "Não informado"}
                    </SubTitleInfo>
                  </DivPatient>
                  <DivPatient>
                    <TitleInfo>PROFISSIONAL: </TitleInfo>
                    <SubTitleInfo>
                      {slot.doctorRefName
                        ? slot.doctorRefName
                        : "Não informado"}
                    </SubTitleInfo>
                  </DivPatient>
                  <DivPatient>
                    <TitleInfo>STATUS: </TitleInfo>
                    <SubTitleInfo>{slot.SlotState.name}</SubTitleInfo>
                  </DivPatient>
                </DivBody>

                <DivButtons>
                  <Button blue onClick={() => onClickAccessConsult(slot)}>
                    Ingressar
                  </Button>
                  <Button gray onClick={() => handleOpen(slot.id)}>
                    Cancelar
                  </Button>
                </DivButtons>
              </DivScheduled>
            ))}
        </Body>

        <Confirm
          isOpen={modalPatient}
          handleClose={handleClose}
          onClose={() => setModalPatient(false)}
        />
      </Div>
    </Container>
  );
}
export default DateTimeFormInline;
