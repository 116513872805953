import { call, put } from "redux-saga/effects";
import { setLoading } from "../actions/utils.actions";

import { setHistoryList } from "../actions/history.actions";
import api from "../../services/api";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function* getHistory({ payload }) {
  yield put(setLoading(true));

  try {
    const request = () => api.post(`/history/list`, payload);
    const { data } = yield call(request);

    if (data) {
      return yield put(setHistoryList(data));
    }
  } catch (error) {
  } finally {
    yield put(setLoading(false));
  }
}

export function* getAssignUrl({ payload }) {
  yield put(setLoading(true));

  try {
    const request = () => api.post(`/history/generateDocumentAssign`, payload);
    const { data } = yield call(request);

    if (data) {
      return window.open(data);
    }
  } catch (error) {
    console.log(error, error.response);
  } finally {
    yield put(setLoading(false));
  }
}
