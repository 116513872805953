import React, { useEffect } from "react";

import { Main, Link, Title, Titles, SubTitle, Close } from "./styles";

import { FiDownload, FiClock } from "react-icons/fi";
import { IoIosCloseCircle } from "react-icons/io";
import { AiFillEye } from "react-icons/ai";

import { generateDocumentAssign } from "../../../redux/actions/utils.actions";

import { parseISO, format } from "date-fns";
import { pt } from "date-fns/locale";

import Modal from "../../../components/modal";
import { useDispatch } from "react-redux";

export default function App(props) {
  function formatDate(date) {
    try {
      const firstDate = parseISO(date);

      const formattedDate = format(firstDate, "dd/MM/yyyy ", {
        locale: pt,
      });

      return formattedDate;
    } catch (err) {
      return date;
    }
  }

  const dispatch = useDispatch();

  function openDocument(document) {
    dispatch(generateDocumentAssign(document));
  }

  return (
    <Modal isOpen={props.isOpen}>
      <Main>
        <Close onClick={props.onClose}>
          <IoIosCloseCircle size={30} />
        </Close>

        <Titles>
          <Title>Pronto Atendimento Virtual</Title>
          <SubTitle>Data: {formatDate(props.isOpen.date)}</SubTitle>
          <SubTitle>Hora: {props.isOpen.hour}</SubTitle>
          <SubTitle>Paciente: {props.isOpen.namePatient}</SubTitle>
          <SubTitle>Médico: {props.isOpen.nameDoctor}</SubTitle>
        </Titles>

        {(props.isOpen.documentsUser || []).map((item) => {
          return (
            <Link onClick={() => openDocument(item)}>
              <p>{item.classification || item.name}</p>
              <AiFillEye size="20px" />
            </Link>
          );
        })}
      </Main>
    </Modal>
  );
}
