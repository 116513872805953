import styled, { css } from "styled-components";

import { motion } from "framer-motion";
import animations from "../../utils/animation";

export const Container = styled(motion.div).attrs(animations)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  overflow: auto;
  @media (max-width: 1024px) {
    height: 100%;
  }
`;

export const DivLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 200px;
`;

export const Main = styled.div`
  min-width: 700px;
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-height: 90%;

  @media (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
    overflow: unset;
    margin: 10px;
  }
`;

export const Titles = styled.div`
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 10px;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  font-size: 18px;
`;

export const SubTitle = styled.p`
  color: #707070;
`;

export const Infos = styled.div`
  box-shadow: 0px 0px 9px 0px rgba(150, 150, 150, 0.4);
  width: 628px;
  height: auto;
  padding: 30px 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1.5;
  max-height: 100%;

  @media (max-width: 1024px) {
    width: 90%;
    margin: 10px 0;
  }
`;
export const ImageAlert = styled.img`
  margin: 0 0 30px;
`;

export const Texts = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
  color: #909090;

  p {
    color: #909090;
    width: 80%;
    text-align: justify;
    line-height: 1.5;
  }

  ul {
    width: 450px;
    margin: 30px 0;
  }

  li {
    color: #707070;
  }

  img {
    margin: 0 10px;
    width: 70px;
  }

  @media (max-width: 1024px) {
    width: 80%;
    flex-direction: column;
    font-size: 16px;

    p {
      width: 80%;
      text-align: justify;
    }

    ul {
      width: 80%;
    }

    img {
      margin: 15px;
      width: 70px;
    }
  }
`;

export const Buttons = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  width: ${(props) => (props.width ? props.width : "160px")};
  height: 40px;
  border-radius: 30px;
  display: flex;
  margin: 0 10px;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    margin: 0 20px;
    min-width: 100px;
    width: 250px;

    :first-child {
      margin-bottom: 20px;
    }
  }

  ${(props) => props.contained && contained}
  ${(props) => props.outline && outline}
`;

const contained = css`
  background: #2196f3;
  color: #fff;
`;

const outline = css`
  border: 1px solid #2196f3;
  color: #2196f3;
`;
export const ButtonBack = styled.button`
  cursor: pointer;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  margin: 30px 0 10px 0;
`;

export const LabelBack = styled.p`
  margin-left: 10px;
  color: #707070;
`;

export const IconBack = styled.img`
  width: 20px;
  height: 20px;
`;
