import {
  SET_LOADING,
  SET_LOADING_MODAL,
  MODAL_DEPENDENT,
  SET_LOADED,
  SET_LOGGED,
  VERIFY_AUTH,
  SET_DISEASE,
  SEND_EMAIL,
  RESEND_EMAIL,
  SET_ACTIVE_STEP,
  SET_LABEL_WHITE,
  BUY_AUTH,
  GENERATE_BUY_TOKEN,
  SET_MENU_VISIBLE,
  GET_DISEASE,
  SET_LIST_DISEASES,
  SET_VISIBLE_OPTIONS,
  OPEN_MODAL_REQUEST,
  SEND_CANCEL_REQUEST,
  MODAL_PLAN_B2B,
  GET_ASSIGN,
  SET_DOCS_UPLOAD_STEP,
  SET_DOCS_UPLOAD_STEPS,
  NEXT_DOCS_UPLOAD_STEP,
  UNDO_DOCS_UPLOAD_STEP,
  SET_SELECTED_DOC_TYPE,
  SKIP_PROFILE_PIC_UPLOAD_STEP,
  SELECT_DOC_STEP_FILE,
  SET_DOCS_UPLOAD_FILES,
  IGNORE_UPLOAD_DOCS,
  RESET_UPLOAD_DOCS,
  SET_MODAL_CONFIRM,
  MODAL_CONFIRM_ACCEPTED,
  MODAL_CONFIRM_DISMISSED,
  CHECK_TERMS,
} from "./action-types";

export const sendEmail = (payload) => ({
  type: SEND_EMAIL,
  payload,
});

export const sendCancelRequest = (payload) => ({
  type: SEND_CANCEL_REQUEST,
  payload,
});

export const setVisibleOptions = (payload) => ({
  type: SET_VISIBLE_OPTIONS,
  payload,
});

export const openRequestModalDependent = (payload) => ({
  type: OPEN_MODAL_REQUEST,
  payload,
});

export const setMenuVisible = (payload) => ({
  type: SET_MENU_VISIBLE,
  payload,
});

export const reSendEmail = (payload) => ({
  type: RESEND_EMAIL,
  payload,
});

export const setLoading = (bool) => ({
  type: SET_LOADING,
  payload: bool,
});

export const openModalDependent = (dependent) => ({
  type: MODAL_DEPENDENT,
  payload: dependent,
});

export const setLoadingModal = (bool) => ({
  type: SET_LOADING_MODAL,
  payload: bool,
});

export const setLoaded = (bool) => ({
  type: SET_LOADED,
  payload: bool,
});

export const setLogged = (bool) => ({
  type: SET_LOGGED,
  payload: bool,
});

export const verifyAuth = (bool) => ({
  type: VERIFY_AUTH,
  payload: bool,
});

export const setDisease = (disease) => ({
  type: SET_DISEASE,
  payload: disease,
});

export const generateDocumentAssign = (document) => ({
  type: GET_ASSIGN,
  payload: document,
});

export const getDiseases = (disease) => ({
  type: GET_DISEASE,
  payload: disease,
});

export const setListDiseases = (diseases) => ({
  type: SET_LIST_DISEASES,
  payload: diseases,
});

export const setActiveStep = (step) => ({
  type: SET_ACTIVE_STEP,
  payload: step,
});

export const setLabelWhite = (payload) => ({
  type: SET_LABEL_WHITE,
  payload,
});

export const buyAuth = (payload) => ({
  type: BUY_AUTH,
  payload,
});

export const checkTerms = (payload) => ({
  type: CHECK_TERMS,
  payload,
});

export const generateBuyToken = (payload) => ({
  type: GENERATE_BUY_TOKEN,
  payload,
});

export const modalPlanB2B = (payload) => ({
  type: MODAL_PLAN_B2B,
  payload,
});

export const setDocsUploadStep = (payload) => ({
  type: SET_DOCS_UPLOAD_STEP,
  payload,
});

export const setDocsUploadSteps = (payload) => ({
  type: SET_DOCS_UPLOAD_STEPS,
  payload,
});

export const nextDocsUploadStep = (payload) => ({
  type: NEXT_DOCS_UPLOAD_STEP,
  payload
});

export const undoDocsUploadStep = (payload) => ({
  type: UNDO_DOCS_UPLOAD_STEP,
  payload,
});

export const setSelectedDocType = (payload) => ({
  type: SET_SELECTED_DOC_TYPE,
  payload,
});

export const skipDocUploadStep = (payload) => ({
  type: SKIP_PROFILE_PIC_UPLOAD_STEP,
  payload,
});

export const setCurrentDocumentStepFile = (payload) => ({
  type: SELECT_DOC_STEP_FILE,
  payload,
})

export const setDocsUploadFiles = (payload) => ({
  type: SET_DOCS_UPLOAD_FILES,
  payload,
})

export const ignoreUploadDocs = (payload) => ({
  type: IGNORE_UPLOAD_DOCS,
  payload,
})

export const resetUploadDocs = (payload) => ({
  type: RESET_UPLOAD_DOCS,
  payload,
})

export const setModalConfirm = (payload) => ({
  type: SET_MODAL_CONFIRM,
  payload,
})

export const modalConfirmAccepted = (payload) => ({
  type: MODAL_CONFIRM_ACCEPTED,
  payload,
})

export const modalConfirmDismissed = (payload) => ({
  type: MODAL_CONFIRM_DISMISSED,
  payload
})