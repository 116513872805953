import React from "react";

import {
  Container,
  ButtonBack,
  DivButtons,
  LabelConfirm,
  ButtonCancel,
  ButtonConfirm,
  LabelBack,
  IconBack,
} from "./styles";

import Modal from "../../../components/modal-confirm";

export default function App(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      handleClose={props.handleClose}
    >
      <Container>
        <ButtonBack type="button" onClick={() => props.onClose()}>
          <IconBack src="img/icons/back-icon.png"></IconBack>
          <LabelBack>Voltar</LabelBack>
        </ButtonBack>

        <LabelConfirm>
          Tem certeza que deseja cancelar esta consulta?
        </LabelConfirm>

        <DivButtons>
          <ButtonCancel onClick={() => props.handleClose(false)}>
            Não
          </ButtonCancel>
          <ButtonConfirm
            onClick={() => {
              props.handleClose(true);
            }}
          >
            Sim
          </ButtonConfirm>
        </DivButtons>
      </Container>
    </Modal>
  );
}
