import React, { useState, useEffect } from "react";
import {
  ContainerMenu,
  HeaderMenu,
  LogoMenu,
  ButtonMenu,
  Menu,
  Logged,
  Options,
  OptionItem,
  User,
  NameUser,
  ImageUser,
  MenuList,
  MenuItem,
  MenuLabel,
  InitialButton,
  InitialButtonContainer,
} from "./styles";

import { FiMenu, FiX } from "react-icons/fi";

import itemsMenu from "../../utils/items-landing";
import { push } from "../../services/navigate";
import { useDispatch, useSelector } from "react-redux";
import { openModalUser } from "../../redux/actions/user.actions";

export default function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [logged, setLogged] = useState(false);
  const [activeRoute, setActiveRoute] = useState("/home");
  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const activeRoute = window.location.href.split("/")[3];

    if (!activeRoute) {
      return setActiveRoute(`/home`);
    }

    setActiveRoute(`/${activeRoute}`);
  }, []);

  useEffect(() => {
    if (user && user.name) {
      setLogged(true);
      setOptions([
        {
          label: "Home",
          action: () => goToHome(),
        },
        {
          label: "Meu perfil",
          action: () => goToPerfil(),
        },
        {
          label: "Sair",
          action: () => logout(),
        },
      ]);
    }
  }, [user]);

  function goToPerfil() {
    push("/home");
    dispatch(openModalUser(true));
  }

  function goToHome() {
    push("/home");
  }

  function logout() {
    localStorage.removeItem("@access_token");
    window.location.href = "/login";
  }
  function goToLogin() {
    push("/login");
  }

  function goToRegister() {
    push("/cadastro");
  }

  function openMenu() {
    setShowMenu(true);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  function getMessage() {
    const dateNow = new Date();
    const hour = dateNow.getHours();

    if (hour < 5) {
      return "Boa noite";
    }

    if (hour < 12) {
      return "Bom dia";
    }

    if (hour < 18) {
      return "Boa tarde";
    }

    return "Bem-vindo";
  }

  return (
    <ContainerMenu>
      <HeaderMenu>
        <FiMenu size="25px" onClick={openMenu} color="#005A9C" />
        <LogoMenu src="img/logo-sidebar.png" />
      </HeaderMenu>

      <Menu showMenu={showMenu}>
        <Logged>
          <ButtonMenu onClick={closeMenu}>
            <FiX size="25px" color="#fff" />
          </ButtonMenu>

          {!logged ? (
            <>
              <ImageUser
                onClick={goToLogin}
                style={{ 
                  width: 80, 
                  height: 80, 
                  borderRadius: 40, 
                  marginTop: 20, 
                }}
                src="img/default.png"
                o
                onError={(e) => (e.target.src = "img/default.png")}
              />
              <InitialButtonContainer>
                <InitialButton onClick={goToLogin} outline>
                  Entrar
                </InitialButton>
                <InitialButton href="#contratos" contained>
                  Contrate agora
                </InitialButton>
              </InitialButtonContainer>
            </>
          ) : (
            <>
              <User>
                <NameUser>
                  Olá, {getMessage()}. {user.name || user.firstName}
                </NameUser>
                <ImageUser src={user.image || "img/default.png"} o />
              </User>
              <Options>
                {options.map((option, key) => (
                  <OptionItem
                    latest={key + 1 === options.length}
                    onClick={() => option.action()}
                  >
                    {option.label}
                  </OptionItem>
                ))}
              </Options>
            </>
          )}

        </Logged>

        <MenuList>
          {itemsMenu.map((itemMenu, key) => (
            <MenuItem
              onClick={() => setShowMenu(false)}
              href={itemMenu.route}
              active={itemMenu.route === activeRoute}
            >
              {itemMenu.icon && itemMenu.icon}
              <MenuLabel active={itemMenu.route === activeRoute}>
                {itemMenu.label}
              </MenuLabel>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </ContainerMenu>
  );
}
