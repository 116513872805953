import React, { useEffect, useState } from "react";
import {
  Footer,
  FooterBody,
  FooterHeader,
  Div
} from "./styles";

export default function App() {

  return (
    <Footer>
      <FooterHeader>
        <Div>
          <p>Responsável técnico: </p>
          <p>
            Dr. Eliezer Silva - CRM 85148 SP
          </p>
        </Div>

        <Div>
          <img src="img/logo-einstein.svg" />

        </Div>

        <Div />
      </FooterHeader>
      <FooterBody>
        <p>Copyright © 2020 Hospital Israelita Albert Einstein. Todos os direitos reservados.</p>
      </FooterBody>

    </Footer>
  );
}
