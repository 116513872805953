import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Container,
  Main,
  Titles,
  Title,
  SubTitle,
  DivLogo,
  Logo,
  Infos,
  Texts,
  Buttons,
  Button,
  Uploaded,
  Files,
  ItemFile,
  FirstFile,
  Form,
  UploadArea,
  ButtonUpload,
  Caption,
  UploadTextContainer
} from "./styles";

import { push } from "../../services/navigate";
import { show } from "../../services/alert";
import { sendUploadFiles } from "../../redux/actions/user.actions";
import { ReactComponent as FileIcon } from "../../assets/icons/file-plus.svg";

import Confirm from "./modal-confirm";

const formData = new FormData();

export default function Covid(props) {
  const [modalConfirm, setModalConfirm] = useState(false);

  const dispatch = useDispatch();

  function goToGuidelines() {
    push("/orientacoes");
  }

  function generateRandomString(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [files, setFiles] = useState([]);

  function getExt(name) {
    const bits = name.split(".")
  
    if (bits.length < 2) {
      return false
    }

    const fileExt = bits[bits.length - 1]

    return fileExt.toLowerCase()
  }

  function validateExt(fileName) {
    const extWhitelist = ["jpeg", "jpg", "pdf", "png"]
    const fileExt = getExt(fileName)
  
    if (!fileExt) {
      return false
    }

    const isSupported = extWhitelist.some(ext =>  fileExt === ext)
  
    if (!isSupported) {
      return false
    }

    return true
  }

  function handleFiles(newFiles) {
    const fileNames = [...newFiles.files].map(file => file.name)
    const hasUnsuportedFiles = fileNames.some(file => !validateExt(file))

    if (hasUnsuportedFiles) {
      const sample = fileNames.filter(name => !validateExt(name))[0]
      const sampleExt = getExt(sample)

      show(`Formato de arquivo (${sampleExt || "desconhecido"}) não suportado. Por favor, selecione imagens e/ou arquivos PDF.`)
      return
    }


    let auxFiles = [...files];
    let lenghtFiles = Array.from(newFiles.files).length + auxFiles.length;

    if (lenghtFiles > 3) {
      return show("Permitido no máximo 3 anexos.");
    }

    Array.from(newFiles.files).forEach((file) => {
      auxFiles.push({
        ...file,
        originalFile: file,
        originalName: file.name,
        name: generateRandomString(file.name),
        size: file.size,
        preview: URL.createObjectURL(file),
        url: null,
      });

    });

    setFiles([...auxFiles]);
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  function removeItem(key) {
    let auxFiles = [...files];

    auxFiles.splice(key, 1);

    setFiles([...auxFiles]);
  }

  function uploadFiles() {
    formData.set("documentType", "EXAM");
    if (files.length > 0) {
      let auxFiles = [...files];
      auxFiles.forEach((file) => formData.append("files", file.originalFile))
      dispatch(sendUploadFiles(formData));
    } else {
      setModalConfirm(true);
    }
  }

  function goToHome() {
    push("/home");
  }

  const handleClose = (confirm) => {
    if (confirm) {
      let auxFiles = [...files];
      auxFiles.forEach((file) => formData.append("files", file.originalFile))
      dispatch(sendUploadFiles(formData));
      setModalConfirm(false);
    } else {
      setModalConfirm(false);
    }
  };

  return (
    <Container>
      <DivLogo onClick={() => goToHome()}>
        <Logo src="img/logo-sidebar.png" />
      </DivLogo>

      <Main>
        <Titles>
          <Title>Anexar arquivos</Title>
          <SubTitle>
            Você pode enviar fotos da lesão, exames e laudos. Os arquivos serão
            visualizados pelo seu médico.
          </SubTitle>
        </Titles>

        <Form onSubmit={handleSubmit} enctype="multipart/form-data">
          <Infos>
            <UploadArea htmlFor="inputFile">
              <input
                multiple={true}
                onChange={(e) => handleFiles(e.target)}
                id="inputFile"
                type="file"
                accept="image/x-png, image/jpeg,.pdf"
              />
              <Texts>
                <img src="img/file-plus.png" />
                <ButtonUpload htmlFor="inputFile" type="buttton">
                  <UploadTextContainer>
                    Clique aqui para fazer upload de exames ou documentos.
                    <br />
                    <Caption>
                      *Você pode enviar até 3 anexos (upload máximo de 15mb).
                    </Caption>
                  </UploadTextContainer>
                </ButtonUpload>
              </Texts>
            </UploadArea>

            <Uploaded>
              <Files>
                {(files || []).map((item, key) => (
                  <ItemFile>
                    <FirstFile>
                      <div style={{ color: "#2096F3" }}>
                        <FileIcon />
                      </div>
                      <label>{item.originalName}</label>
                    </FirstFile>

                    <img onClick={() => removeItem(key)} src="img/close.png" />
                  </ItemFile>
                ))}
              </Files>
            </Uploaded>

            <Buttons>
              <Button outline onClick={() => goToGuidelines()}>
                Voltar
              </Button>

              <Button contained width="250px" onClick={() => uploadFiles()}>
                Avançar
              </Button>
            </Buttons>
          </Infos>
        </Form>
      </Main>
      <Confirm
        isOpen={modalConfirm}
        handleClose={handleClose}
        onClose={() => setModalConfirm(false)}
      />
    </Container>
  );
}
