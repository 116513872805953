import React, { useState } from "react";
import {
  Main,
  Titles,
  Title,
  SubTitle,
  DivImage,
  OwnerImage,
  ManagerDependents,
  TitleDependents,
  LabelAdd,
  ListDependents,
  DependentItem,
  DependentEdit,
  DependentImage,
  AddDependent,
  DependentRemove,
  DependentAdd,
} from "./styles";

import CreateDependent from "../create-dependent";
import Container from "../../../container";

import {
  openModalDependent,
  openRequestModalDependent,
  setModalConfirm,
} from "../../../redux/actions/utils.actions";

import { eraseDependent } from "../../../redux/actions/depedents.actions";
import { useDispatch, useSelector } from "react-redux";
import { BsPencilSquare, BsFillPlusCircleFill } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import { show } from "../../../services/alert";

import ModalConfirm from "./modal-confirm";

export default function App() {
  let provider = localStorage.getItem("@selected_provider");
  provider = JSON.parse(provider);

  const id_careplus = process.env.REACT_APP_ID_AGREEMENT_CAREPLUS;
  const id_avon = process.env.REACT_APP_ID_AGREEMENT_AVON;
  const id_claro = process.env.REACT_APP_ID_AGREEMENT_CLARO;
  const id_particular = process.env.REACT_APP_ID_AGREEMENT_PARTICULAR;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.userReducer.user);
  const currentPrivatePlan = useSelector(
    (state) => state.userReducer.currentPrivatePlan
  );

  const dependentsProps = useSelector(
    (state) => state.dependentsReducer.dependents
  );

  function cutName(firstName, lastName) {
    let fullName = `${firstName} ${lastName || ""}`;

    if (fullName && fullName.length > 25) {
      return firstName;
    }
    return fullName;
  }

  function editDependent(dependent) {
    if(isCarePlus){
      dependent.notEditable = true
    }
    dispatch(openModalDependent({ ...dependent, edit: true }));
  }

  function removeDependent(dependent) {
    dispatch(eraseDependent({ ...dependent, provider }));
  }

  function addDependentPrivatePlan() {
    const maxDependentCount = currentPrivatePlan.dependentsCount;
    const currentDependentCount =
      (dependentsProps && dependentsProps.length) || 0;

    if (currentDependentCount < maxDependentCount) {
      dispatch(openRequestModalDependent(dependentsProps));
      return;
    }

    show("Número de dependentes excedido");
  }

  const isPrivatePlan = provider && provider.id_convenio == id_particular;
  const isInternalNonPrivatePlan =
    provider &&
    provider.id_convenio != id_careplus &&
    provider.id_convenio !== id_particular &&
    provider.id_convenio != id_avon;
  const isCarePlus = provider && provider.id_convenio == id_careplus;
  const isAvon = provider && provider.id_convenio == id_avon;


  return (
    <Container>
      <ModalConfirm />
      <Main>
        <Titles>
          <Title>Dependentes</Title>
          <SubTitle>Gerencie seus dependentes.</SubTitle>
        </Titles>

        <DivImage>
          <OwnerImage src={user.image || "img/default.png"} />
          <Titles>
            <Title>{user.name || "Usuário"}</Title>
            <SubTitle>Titular</SubTitle>
          </Titles>
        </DivImage>

        <ManagerDependents>
          <TitleDependents>Gerenciar dependentes</TitleDependents>
        </ManagerDependents>

        <ListDependents>
          {(dependentsProps || []).map((dependent) => {
            return (
              <DependentItem>
                {isCarePlus && (
                  <DependentRemove onClick={() => removeDependent(dependent)}>
                    <FiTrash2 size={"20px"} color="red" />
                  </DependentRemove>
                )}
                <DependentEdit
                  position={isCarePlus}
                  onClick={() => editDependent(dependent)}
                >
                  <BsPencilSquare size={"20px"} color="#2196f3" />
                </DependentEdit>
                <DependentImage
                  src={
                    (dependent.signUpDocuments &&
                      dependent.signUpDocuments.image) ||
                    "img/default.png"
                  }
                  onError={(e) => (e.target.src = "img/default.png")}
                />
                <Titles>
                  <Title>
                    {cutName(
                      `${dependent.firstName || ""} ${dependent.lastName || ""}`
                    ) || "Dependente"}
                  </Title>
                  <SubTitle>Dependente</SubTitle>
                </Titles>
              </DependentItem>
            );
          })}

          {isPrivatePlan && (
            <AddDependent onClick={addDependentPrivatePlan}>
              <DependentAdd>
                <BsFillPlusCircleFill size={"20px"} color="#2196f3" />
              </DependentAdd>
              <LabelAdd>Adicionar dependente</LabelAdd>
            </AddDependent>
          )}

          {isInternalNonPrivatePlan && (
            <AddDependent
              onClick={() =>
                show(
                  "Seu plano não possui acesso a dependentes, entre em contato com seu provedor"
                )
              }
            >
              <DependentAdd>
                <BsFillPlusCircleFill size={"20px"} color="#2196f3" />
              </DependentAdd>
              <LabelAdd>Adicionar dependente</LabelAdd>
            </AddDependent>
          )}

          {isCarePlus && (!dependentsProps || dependentsProps.length < 9) && (
            <AddDependent onClick={() => dispatch(openModalDependent(true))}>
              <DependentAdd>
                <BsFillPlusCircleFill size={"20px"} color="#2196f3" />
              </DependentAdd>
              <LabelAdd>Adicionar dependente</LabelAdd>
            </AddDependent>
          )}

          {isAvon && (!dependentsProps || dependentsProps.length < 4) && (
            <AddDependent onClick={() => dispatch(openModalDependent(true))}>
              <DependentAdd>
                <BsFillPlusCircleFill size={"20px"} color="#2196f3" />
              </DependentAdd>
              <LabelAdd>Adicionar dependente</LabelAdd>
            </AddDependent>
          )}
        </ListDependents>
      </Main>

      <CreateDependent onClose={openModalDependent} />
    </Container>
  );
}
