import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Inputs,
  DivInput,
  ErrorMessage,
  ErrorImage,
  Select,
  Button,
  ButtonBack,
  IconBack,
  LabelBack,
  UploadImage,
  DivImage,
  ImageDependent,
  InputNone,
  DivIcon,
  Icon,
  Titles,
  Title,
  SubTitle,
  ButtonsContainer,
} from "./styles";

import Modal from "../../../components/modal-right";

import { useDispatch, useSelector } from "react-redux";
import useYup from "@usereact/use-yup";
import {brasileiroSchema, estrangeiroSchema} from "./validate";
import { cpf as cpfTest } from "cpf-cnpj-validator";
import {
  addDependent,
  editDependent,
} from "../../../redux/actions/depedents.actions";

import Input from "../../../components/input-custom";

import { convertDate } from "../../../utils/date";
import { push } from "../../../services/navigate";
import { patientObjHasDocuments } from "../../../utils/documentHelpers";

export default function CreateDependent(props) {
  const dispatch = useDispatch();
  const [touched, setTouched] = useState(false);
  const [labels, setLabels] = useState({});
  const [dependent, setDependent] = useState({});
  const userProps = useSelector((state) => state.userReducer.user);

  let provider = localStorage.getItem("@selected_provider");
  provider = JSON.parse(provider);

  const modalDependent = useSelector(
    (state) => state.utilsReducer.modalDependent
  );

  const isCareplus =
    provider.id_convenio == process.env.REACT_APP_ID_AGREEMENT_CAREPLUS;
  const isParticularPlan =
    provider.id_convenio == process.env.REACT_APP_ID_AGREEMENT_PARTICULAR;

  useEffect(() => {
    setTouched({
      cpf: true,
    }); return () => backPage(null);
  }, []);

  useEffect(() => {
    if (modalDependent) {
      setDependent({
        dependentId: modalDependent._id,
        edit: modalDependent.edit ? true : false,
        firstName: modalDependent.firstName,
        lastName: modalDependent.lastName,
        document: modalDependent.documentNumber,
        documentType: userProps.documentType,
        rg: modalDependent.rg,
        birthDate: convertDate(modalDependent.birthDate),
        gender: modalDependent.gender,
        nationality: userProps.documentType === "CPF" ? "B" : "E",
        image:
          modalDependent.signUpDocuments &&
          modalDependent.signUpDocuments.image,
        preview:
          modalDependent.signUpDocuments &&
          modalDependent.signUpDocuments.image,
        notEditable: modalDependent.notEditable,
      });
      if (
        (modalDependent._id || modalDependent.idVida) &&
        isParticularPlan
      ) {
        setLabels({
          title: "Editar Dependente",
          subtitle: "Edite as informações de seus dependentes",
        });
      } else if (
        (modalDependent._id || modalDependent.idVida) &&
        !isParticularPlan
      ) {
        setLabels({
          title: "Dependente",
          subtitle: "Consulte as informações dos seus dependentes",
        });
      } else {
        setLabels({
          title: "Adicionar Dependente",
          subtitle: "Insira as informações para adicionar o dependente",
        });
      }
    }
  }, [modalDependent]);

  let { errors } = useYup(dependent, dependent.nationality === 'B' ? brasileiroSchema : estrangeiroSchema, {
    validateOnChange: true,
  });

  function isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }


  function handleSubmit(e) {
    e.preventDefault();

    setTouched({
      firstName: true,
      lastName: true,
      document: true,
      rg: true,
      birthDate: true,
      gender: true,
      nationality: true,
      image: true,
      preview: true,
    });

    if (isEmpty(errors)) {
      if (dependent.edit) {
        return dispatch(editDependent(dependent));
      }

      return dispatch(addDependent(dependent));
    }
  }

  function handleImage(e) {
    const image = e.target.files[0];
    const preview = URL.createObjectURL(image);
    setDependent({
      ...dependent,
      preview,
      image,
    });
  }

  function dontBlockEmoji(value) {
    return (
      value === 'birthDate' || (value === 'document' && dependent.nationality === 'B')
      ||(dependent.edit && cpfTest.isValid(dependent.document) && value === 'document')
    )
  }

  function handleChange(e) {
    const { name, value } = e.currentTarget;
    setTouched({
      ...touched,
      [name]: true,
    });

    const reg = /^([a-zA-ZÀ-ú\s\d]+)$/gi
    if(!reg.test(value) && value.length > 0 && !dontBlockEmoji(name)){
      return
    }
    setDependent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if(name === 'nationality'){
      setDependent((prevState) => ({
        ...prevState,
        document: ''
      }));
    }
  }

  function backPage() {
    setDependent({ notEditable: true });
    setLabels({ title: null });
    setTouched([]);

    errors = {};

    dispatch(props.onClose(false));
  }

  function uploadDocuments() {
    push("/documentos", {
      dependent: JSON.stringify({
        firstName: modalDependent.firstName,
        lastName: modalDependent.lastName,
        birthDate: modalDependent.birthDate,
        documentNumber: modalDependent.documentNumber,
      }),
    });
  }

  function shouldShowSignupDocsButton() {
    if (patientObjHasDocuments(modalDependent)) return false;

    // Modal dependent has initial state obj when
    // user is editing and existing dependent
    if (modalDependent === true) return false;

    return true;
  }

  return (
    <Modal isOpen={modalDependent} onClose={() => backPage()}>
      <Container>
        <Form onSubmit={handleSubmit}>
          <ButtonBack type="button" onClick={() => backPage()}>
            <IconBack src="img/icons/back-icon.png"></IconBack>
            <LabelBack>Voltar</LabelBack>
          </ButtonBack>

          <UploadImage>
            <DivImage
              htmlFor="imageDependent"
              editable={!dependent.notEditable}
            >
              <InputNone
                accept="image/x-png,image/jpeg"
                onChange={handleImage}
                id="imageDependent"
                type={
                  dependent.notEditable
                    ? "text"
                    : "file"
                }
              />
              <ImageDependent
                src={dependent.preview || "img/default.png"}
                onError={(e) => (e.target.src = "img/default.png")}
              />
              {!dependent.notEditable && (
                <DivIcon>
                  <Icon src="img/icons/upload.png" />
                </DivIcon>
              )}
              <ErrorImage>
                {touched["preview"] && errors && errors.preview}
              </ErrorImage>
            </DivImage>
            <Titles>
              <Title>{labels.title}</Title>
              <SubTitle>{labels.subtitle}</SubTitle>
            </Titles>
          </UploadImage>

          <Inputs>
            <Input
              width="325px"
              onChange={handleChange}
              value={dependent.firstName}
              name="firstName"
              placeholder="Nome"
              type="text"
              readOnly={
                (modalDependent._id || modalDependent.idVida) &&
                provider.id_convenio !=
                  process.env.REACT_APP_ID_AGREEMENT_PARTICULAR
              }
              errorMessage={touched["firstName"] && errors && errors.firstName}
            />

            <Input
              width="325px"
              onChange={handleChange}
              value={dependent.lastName}
              name="lastName"
              placeholder="Sobrenome"
              type="text"
              readOnly={
                (modalDependent._id || modalDependent.idVida) &&
                provider.id_convenio !=
                  process.env.REACT_APP_ID_AGREEMENT_PARTICULAR
              }
              errorMessage={touched["lastName"] && errors && errors.lastName}
            />
            
            {!dependent.document && dependent.edit ?
              null
              :
              <>
                {/*<DivInput>
                 <label>{dependent.nationality && "Nacionalidade"}</label>
                  <Select
                    value={dependent.edit ? !cpfTest.isValid(dependent.document) ? 'E' : 'B' : dependent.nationality}
                    name="nationality"
                    onChange={handleChange}
                    disabled={modalDependent._id || modalDependent.idVida}
                  >
                    <option disabled selected value>
                      Nacionalidade
                    </option>
                    <option value="B">Brasileira</option>
                    <option value="E">Estrangeira</option>
                  </Select>
                  <ErrorMessage>
                    {touched["nationality"] && errors && errors.nationality}
                  </ErrorMessage>
                </DivInput> */}

                {dependent.nationality === 'E' || (dependent.edit && !cpfTest.isValid(dependent.document)) ?
                  <Input
                    width="680px"
                    onChange={handleChange}
                    value={dependent.document}
                    name="document"
                    placeholder="Passaporte"
                    type="text"
                    errorMessage={touched["document"] && errors && errors.document}
                    readOnly={modalDependent._id || modalDependent.idVida}
                    readOnlyColor={modalDependent._id || modalDependent.idVida}
                    labelMessage={
                      (modalDependent._id || modalDependent.idVida) &&
                      "Esse campo não pode ser editado"
                    }
                  />
                :
                  <>
                    <Input
                      width="325px"
                      onChange={handleChange}
                      value={dependent.document}
                      name="document"
                      placeholder="CPF"
                      type="text"
                      mask={"999.999.999-99"}
                      errorMessage={touched["document"] && errors && errors.document}
                      readOnly={modalDependent._id || modalDependent.idVida}
                      readOnlyColor={modalDependent._id || modalDependent.idVida}
                      labelMessage={
                        (modalDependent._id || modalDependent.idVida) &&
                        "Esse campo não pode ser editado"
                      }
                    />

                    <Input
                      width="325px"
                      onChange={handleChange}
                      value={dependent.rg}
                      name="rg"
                      placeholder="RG"
                      type="text"
                      readOnly={
                        (modalDependent._id || modalDependent.idVida) &&
                        provider.id_convenio !=
                          process.env.REACT_APP_ID_AGREEMENT_PARTICULAR
                      }
                      errorMessage={touched["rg"] && errors && errors.rg}
                    />
                  </>
                }
              </>
            }

            <Input
              width="325px"
              onChange={handleChange}
              value={dependent.birthDate}
              name="birthDate"
              mask="99/99/9999"
              placeholder="Data de Nascimento"
              readOnly={
                (modalDependent._id || modalDependent.idVida) &&
                provider.id_convenio !=
                  process.env.REACT_APP_ID_AGREEMENT_PARTICULAR
              }
              errorMessage={touched["birthDate"] && errors && errors.birthDate}
            />

            <DivInput>
              <label>{dependent.gender && "Sexo"}</label>

              <Select
                value={dependent.gender}
                name="gender"
                onChange={handleChange}
                disabled={
                  (modalDependent._id || modalDependent.idVida) &&
                  provider.id_convenio !=
                    process.env.REACT_APP_ID_AGREEMENT_PARTICULAR
                }
              >
                <option disabled selected value>
                  Sexo
                </option>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </Select>
              <ErrorMessage>
                {touched["gender"] && errors && errors.gender}
              </ErrorMessage>
            </DivInput>

            <ButtonsContainer>
              {shouldShowSignupDocsButton() && (
                <Button onClick={uploadDocuments} marginRight={16}>
                  Upload documentos
                </Button>
              )}
              {!dependent.notEditable && <Button type="submit">Salvar</Button>}
            </ButtonsContainer>
          </Inputs>
        </Form>
      </Container>
    </Modal>
  );
}
